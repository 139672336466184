import styled from "styled-components";
import { Button, IButtonProps, Checkbox } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "../../../../constants";

export const CustomButton = styled(Button)<IButtonProps>`
  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    justify-content: center;
  }
`;

export const PolicyContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 24px;
`;

export const StyledCheckbox = styled(Checkbox)`
  line-height: 24px;
  padding-top: 5px;
`;
