export const convertDateTimeToDateString = (dateTime?: Date | null): string => {
  if (dateTime) {
    const date: Date = new Date(dateTime);
    const year: number = date.getFullYear();

    const month: string =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;

    const day: string =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

    return `${year}-${month}-${day}`;
  }

  return "";
};
