import { CardStatuses } from "components/StatusCard/types";
import { IButtonProps } from "@security-watchdog/sw-ui-kit";

export interface IPropsStyles extends IButtonProps {
  isButtonModal?: boolean;
}

export interface ISection {
  id: string;
  sortNumber: number;
  title: string;
  description: string;
  icon: IconName;
  status: CardStatuses;
  path: string;
}

export enum IconName {
  user = "user",
  chat = "chat",
  target = "target",
  case = "case",
  medal = "medal",
  shield = "shield",
  folder = "folder",
  checkShieldIcon = "checkShieldIcon"
}
