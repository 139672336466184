import React from "react";
import { ArrowIcon, BUTTON_TYPE } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";

export const ButtonBack: React.FC<IProps> = ({
  children,
  handlerClick
}: IProps) => (
  <s.ButtonBack
    buttonType={BUTTON_TYPE.Link}
    onClick={(): Function => (handlerClick ? handlerClick() : null)}
    icon={<ArrowIcon />}
  >
    {children}
  </s.ButtonBack>
);
