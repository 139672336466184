import React, { useCallback, useMemo } from "react";

import { IProps } from "./types";
import { CaseTroubleshoootingHistoryType } from "src/graphQLTypes";
import { getFieldAttachments, isMultiUploadField } from "src/utils";
import { isAttachmentValue } from "src/utils/typeGuards";
import { UploadFileViewerWithPendingFiles } from "components/UploadFileViewerWithPendingFiles";
import * as s from "./styles";

const UploadFieldCardComponent: React.FC<IProps> = ({
  field,
  questionId,
  answerId,
  isAnswered,
  updatedFieldIds,
  onUploadFile,
  onDeleteFile,
  onDownloadFile,
  isDisabled,
  isDeleteFileDisabled
}: IProps) => {
  const isMultiUpload = isMultiUploadField(field.type);
  const files = useMemo(() => getFieldAttachments(field.value), [field.value]);

  const handleUploadFile = useCallback(
    (uploadedFiles: File[]) => {
      const historyFieldFrom = isMultiUpload
        ? files.length
          ? files.map((i) => i.fileName)
          : undefined
        : files?.[0]?.fileName || undefined;

      const historyFieldTo = isMultiUpload
        ? [...(files || []), ...uploadedFiles].map((i) =>
            isAttachmentValue(i) ? i.fileName : i?.name
          )
        : uploadedFiles[0].name;

      onUploadFile({
        files: uploadedFiles,
        questionId,
        answerId,
        fieldId: field.fieldId,
        fieldType: field.type,
        attachments: files,
        isAnswered,
        history: [
          {
            type: CaseTroubleshoootingHistoryType.FILE_UPLOAD,
            fieldId: field.fieldId,
            answerId,
            isDeleted: false,
            index: field.answerIndex,
            fieldType: field.type,
            isMulti: field.isMulti,
            from: historyFieldFrom,
            to: historyFieldTo
          }
        ]
      });
    },
    [
      onUploadFile,
      questionId,
      answerId,
      field.fieldId,
      field.answerIndex,
      field.type,
      field.isMulti,
      files,
      isMultiUpload,
      isAnswered
    ]
  );

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      const file = files.find((i) => i.id === fileId);

      if (file && !isDeleteFileDisabled) {
        const historyFieldFrom = isMultiUpload
          ? files.length
            ? files.map((i) => i.fileName)
            : undefined
          : file.fileName || undefined;
        const historyFieldTo = isMultiUpload
          ? files.filter((i) => i.id !== fileId).map((i) => i.fileName)
          : undefined;

        onDeleteFile({
          questionId,
          answerId,
          fieldId: field.fieldId,
          fieldType: field.type,
          fieldValue: files.filter((i) => i.id !== fileId),
          history: [
            {
              fieldId: field.fieldId,
              type: CaseTroubleshoootingHistoryType.FILE_UPLOAD,
              fieldType: field.type,
              isMulti: field.isMulti,
              index: field.answerIndex,
              isDeleted: true,
              from: historyFieldFrom,
              to: historyFieldTo,
              answerId
            }
          ]
        });
      }
    },
    [
      onDeleteFile,
      questionId,
      answerId,
      field.fieldId,
      field.type,
      field.isMulti,
      field.answerIndex,
      isDeleteFileDisabled,
      isMultiUpload,
      files
    ]
  );

  const handleDownloadFile = useCallback(
    (fileId: string) => {
      const file = files.find((i) => i.id === fileId);

      if (file) {
        onDownloadFile({
          downloadUrl: file.downloadUrl,
          fileName: file.fileName
        });
      }
    },
    [files, onDownloadFile]
  );

  const isUpdating: boolean = useMemo(
    () =>
      updatedFieldIds.some((i: string) => i === `${field.fieldId}${answerId}`),
    [updatedFieldIds, field.fieldId, answerId]
  );

  return (
    <s.FieldContainer key={field.fieldId}>
      {field.title && <s.FieldTitle>{field.title}</s.FieldTitle>}
      <UploadFileViewerWithPendingFiles
        files={files}
        isLoading={isUpdating}
        disabled={isDisabled}
        multiple={isMultiUpload}
        onChange={handleUploadFile}
        onFileDelete={handleDeleteFile}
        onFileClick={handleDownloadFile}
      />
    </s.FieldContainer>
  );
};

export const UploadFieldCard = React.memo<IProps>(UploadFieldCardComponent);
