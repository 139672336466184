import { IDraftStatuses } from "modules/case/types";
import { IconName } from "containers/SectionList/types";
import {
  CandidateCaseProductStatus,
  CandidateCaseStatus,
  Section,
  TroubleshootingRequestStatus
} from "./graphQLTypes";
import {
  ISectionDataType,
  ISectionDescriptionType,
  ISectionRouteType,
  ISectionIconType,
  CustomSection
} from "./types";
import { getUploadSignatureFileSizeString } from "src/utils/getUploadSizeString";

export const MAX_SIGNATURE_FILE_SIZE_IN_BYTES = 1024 * 1024;

export enum CandidateState {
  Incomplete = "Incomplete",
  Completed = "Completed",
  Missing = "Missing",
  Attached = "Attached"
}

export enum ROUTES {
  ROOT = "/",
  LOGIN = "/login",
  PROOF_OF_IDENTITY = "/proof-of-identity",
  MY_DOCUMENTS = "/my-documents",
  TRUST_ID = "/trust-id",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  PERSONAL_DETAILS = "/proof-of-identity/personal-details",
  ADDRESS_TIMELINE = "/address-timeline",
  EMPLOYMENT_AND_EDUCATION = "/activity-timeline",
  DECLARATIONS = "/disclosure-barring",
  HELP = "/contact",
  COOKIES_PAGE = "/cookies-policy",
  SUCCESS_SUBMIT = "/success-submit",
  PROFESSIONAL_MEMBERSHIP = "/professional-membership",
  CUSTOM_CASES_LINK = "/:caseId",
  AUTHENTICATION_METHOD_AZURE_AD = "/authentication-method/azure_ad",
  ADDITIONAL_DOCUMENTS = "/additional-documents",
  AUTHENTICATE_SCREENER = "/authenticate-screener",
  PERSONAL_REFEREES = "/personal-referees",
  KONFIR = "/konfir-page",
  KONFIR_REDIRECT = "/konfir"
}

export const PUBLIC_ROUTES = [ROUTES.COOKIES_PAGE];

// size of devices
export enum DEVICE {
  MOBILE_S = "320px",
  MOBILE_M = "375px",
  MOBILE_L = "425px",
  TABLET = "768px",
  LAPTOP = "1024px",
  LAPTOP_L = "1440px",
  DESKTOP = "2560px"
}

export const TIME_OF_IDLE: number = 5 * 60 * 1000; // in milliseconds
export const TIME_FOR_CONFIRM: number = 15 * 60 * 1000; // in milliseconds

export const PRIVACY_POLICY_LINKS = {
  FOR_SCOTLAND: "https://www.mygov.scot/privacy/",
  FOR_NOT_SCOTLAND:
    "https://www.gov.uk/government/publications/dbs-privacy-policies"
};

export const CONSENT_FOR_RECEIPT = {
  FOR_SCOTLAND: {
    title: "Consent for receipt of Disclosure Scotland results",
    text: "I consent to the Disclosure Scotland (DS) providing an electronic result directly to the body that is responsible for the submission of my application (Security Watchdog). I understand that the electronic result contains a message that indicates that my certificate does not contain criminal record information, or to await the certificate in the case that it does contain criminal record information."
  },
  FOR_NOT_SCOTLAND: {
    title: "Consent for receipt of DBS result",
    text: "I consent to the Disclosure and Barring Service (DBS) providing an electronic result directly to the body that is responsible for the submission of my application (Security Watchdog). I understand that the electronic result contains a message that indicates that my certificate does not contains criminal record information, or to await the certificate in the case that it does contain criminal record information."
  }
};

export const ACCEPT_SIGNATURE_FILE_TYPES: string = ".png";

export const PNG_FILE_MIME_TYPE = "image/png";

export const CONDITION_PRODUCT_MESSAGE: string =
  "Additional details have been inquired based on your answers. Please provide the required information.";

export const INTERVAL_OF_SAVING_DRAFT_DATA = 30 * 1000;

export const INTERVAL_OF_POLLING_SCREENER_ACCESS_DATA = 30 * 1000;

export const INTERVAL_OF_POLLING_SCREENER_DRAFT_DATA = 30 * 1000;

export const SECTION_SORT_INDEX = {
  [Section.PERSONAL_INFORMATION]: 1,
  [Section.TRUST_ID]: 2,
  [Section.ADDRESS_HISTORY]: 3,
  [Section.EMPLOYMENT_EDUCATION]: 4,
  [Section.KONFIR]: 5,
  [Section.PERSONAL_REFEREES]: 6,
  [Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]: 7,
  [Section.DECLARATION]: 8,
  DOCUMENTS: 9,
  [Section.TERMS_AND_CONDITIONS]: 10,
  ADDITIONAL_DOCUMENTS: 11
};

export const DRAFT_STATUS_TEXT: { [key in IDraftStatuses]: string } = {
  [IDraftStatuses.NO_CHANGES]: "No changes",
  [IDraftStatuses.UNSAVED_CHANGES]: "Unsaved Changes",
  [IDraftStatuses.SAVING]: "Saving",
  [IDraftStatuses.SAVED]: "Saved"
};

export const TROUBLESHOOTING_REQUEST_STATUS_TEXT: {
  [key in TroubleshootingRequestStatus]: string;
} = {
  [TroubleshootingRequestStatus.SCREENER_REQUESTED]:
    "Screener requesting access to edit information",
  [TroubleshootingRequestStatus.CANDIDATE_ACCEPTED]:
    "Screener is able to edit info in your application now",
  [TroubleshootingRequestStatus.TROUBLESHOOTING_FINISHED]: "",
  [TroubleshootingRequestStatus.CANDIDATE_REJECTED]: "",
  [TroubleshootingRequestStatus.CLOSED_BY_SCREENER]: "",
  [TroubleshootingRequestStatus.CLOSED_BY_TIMEOUT]: ""
};

export const SECTION_TITLE: ISectionDataType = {
  [Section.PERSONAL_INFORMATION]: "Personal details",
  [Section.TRUST_ID]: "Verify your identity",
  [Section.KONFIR]: "Verify your employment history (Konfir)",
  [Section.ADDRESS_HISTORY]: "Address timeline",
  [Section.EMPLOYMENT_EDUCATION]: "Employment and education",
  [Section.PERSONAL_REFEREES]: "Personal referees",
  [Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]:
    "Qualifications and Memberships",
  [Section.DECLARATION]: "Declarations",
  [CustomSection.DOCUMENTS]: "My documents",
  [Section.TERMS_AND_CONDITIONS]: "Terms & Conditions",
  [CustomSection.ADDITIONAL_DOCUMENTS]: "Additional documents"
};

export const SECTION_DESCRIPTION: ISectionDescriptionType = {
  [Section.PERSONAL_INFORMATION]: "Verify your details",
  [Section.TRUST_ID]: "Verify your identity via our digital identity provider",
  [Section.KONFIR]:
    "Verify your employment history via our digital referencing partner",
  [Section.PERSONAL_REFEREES]:
    "Nominate one or more referees who can give details of your character and work...",
  [Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]:
    "Please let us know details of your qualifications and/or if you are a member of any professional institutions relevant to your role",
  [Section.DECLARATION]:
    "Convictions, civil proceedings, and/or declarations of bankruptcy",
  [CustomSection.DOCUMENTS]:
    "See a summary of the documents required and uploaded already",
  [Section.TERMS_AND_CONDITIONS]:
    "View the Terms & Conditions and give SW authority to carry out your checks",
  [CustomSection.ADDITIONAL_DOCUMENTS]:
    "Add additional documents to support your screening"
};

export const SECTION_ICON: ISectionIconType = {
  [Section.PERSONAL_INFORMATION]: IconName.user,
  [Section.TRUST_ID]: IconName.chat,
  [Section.KONFIR]: IconName.chat,
  [Section.ADDRESS_HISTORY]: IconName.target,
  [Section.EMPLOYMENT_EDUCATION]: IconName.case,
  [Section.PERSONAL_REFEREES]: IconName.case,
  [Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]: IconName.medal,
  [Section.DECLARATION]: IconName.shield,
  [CustomSection.DOCUMENTS]: IconName.folder,
  [Section.TERMS_AND_CONDITIONS]: IconName.checkShieldIcon,
  [CustomSection.ADDITIONAL_DOCUMENTS]: IconName.folder
};

export const SECTION_ROUTE: ISectionRouteType = {
  [Section.ADDRESS_HISTORY]: ROUTES.ADDRESS_TIMELINE,
  [Section.TRUST_ID]: ROUTES.TRUST_ID,
  [Section.KONFIR]: ROUTES.KONFIR,
  [Section.EMPLOYMENT_EDUCATION]: ROUTES.EMPLOYMENT_AND_EDUCATION,
  [Section.PERSONAL_REFEREES]: ROUTES.PERSONAL_REFEREES,
  [Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]:
    ROUTES.PROFESSIONAL_MEMBERSHIP,
  [Section.DECLARATION]: ROUTES.DECLARATIONS,
  [CustomSection.DOCUMENTS]: ROUTES.MY_DOCUMENTS,
  [Section.TERMS_AND_CONDITIONS]: ROUTES.TERMS_AND_CONDITIONS,
  [CustomSection.ADDITIONAL_DOCUMENTS]: ROUTES.ADDITIONAL_DOCUMENTS
};

export const NO_VALIDATION_DATA = {
  errorMessage: "",
  index: null
};

export const ERROR_TEXT_OF_ADDRESS_HISTORY_OVERLAP_IN_SCOTLAND_LOCATION =
  "Address history section should not contain overlapping addresses";

export const PNG_FILE_ERROR_MESSAGE = "Required file format is PNG";

export const FILE_TRANSPARENCY_ERROR_MESSAGE =
  "Your PNG file must be transparent. Please check your file and try again";

export const SIGNATURE_FILE_SIZE_ERROR_MESSAGE = `Your file size is too large. Maximum file size is ${getUploadSignatureFileSizeString()}`;

export const NAME_ALLOWED_SPECIAL_CHARACTER = "-";

export const CANDIDATE_CASE_PRODUCT_STATUS_TEXT: {
  [key in CandidateCaseProductStatus]: string;
} = {
  [CandidateCaseProductStatus.AWAITING_CANDIDATE]: "Awaiting Candidate",
  [CandidateCaseProductStatus.AWAITING_REFERENCE]: "Awaiting Reference",
  [CandidateCaseProductStatus.AWAITING_3RD_PARTY]: "Awaiting 3rd Party",
  [CandidateCaseProductStatus.COMPLETED]: "Complete",
  [CandidateCaseProductStatus.IN_PROGRESS]: "In Progress",
  [CandidateCaseProductStatus.READY_TO_START]: "Ready To Start"
};
export const CANDIDATE_CASE_PRODUCT_STATUS_TOOLTIP_TEXT: {
  [key in CandidateCaseProductStatus]: string;
} = {
  [CandidateCaseProductStatus.AWAITING_CANDIDATE]:
    "We require some further information from you. Please check your emails for further details.",
  [CandidateCaseProductStatus.AWAITING_REFERENCE]:
    "Your check is awaiting a response from one of your referees.",
  [CandidateCaseProductStatus.AWAITING_3RD_PARTY]:
    "Your check is awaiting a response from a third-party provider.",
  [CandidateCaseProductStatus.COMPLETED]:
    "Your check has been completed and the results shared with our client.",
  [CandidateCaseProductStatus.IN_PROGRESS]:
    "Your check has started and is in progress. This can take anywhere from 1 to 15 business days.",
  [CandidateCaseProductStatus.READY_TO_START]:
    "Your check is with us for review. We will contact you if we need any further information."
};

export const CANDIDATE_CASE_STATUS_TOOLTIP_TEXT: {
  [key in CandidateCaseStatus]: string;
} = {
  [CandidateCaseStatus.CANDIDATE_ACTION_REQUIRED]:
    "We require some further information from you. Click “View requested information” for more details",
  [CandidateCaseStatus.ACTIVE]: "",
  [CandidateCaseStatus.INACTIVE]:
    "Your checks has been completed and the results shared with our client.",
  [CandidateCaseStatus.IN_PROGRESS]:
    "Your checks have started and are in progress. This can take anywhere from 1 to 15 business days.",
  [CandidateCaseStatus.SUBMITTED]:
    "We are reviewing your information and will contact you via e-mail or phone if anything further is needed for us to start our checks."
};

export const CANDIDATE_CASE_STATUS_TEXT: {
  [key in CandidateCaseStatus]: string;
} = {
  [CandidateCaseStatus.CANDIDATE_ACTION_REQUIRED]: "Awaiting Candidate",
  [CandidateCaseStatus.ACTIVE]: "",
  [CandidateCaseStatus.INACTIVE]: "Your screening has Finished’ Message/Page",
  [CandidateCaseStatus.IN_PROGRESS]: "In Progress",
  [CandidateCaseStatus.SUBMITTED]: "Awaiting application Review"
};
