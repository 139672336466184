import styled from "styled-components";
import { Field, IPropsField } from "@security-watchdog/sw-ui-kit";

export const StyledField = styled(Field)<IPropsField>`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledFieldHalf = styled(Field)<IPropsField>`
  display: inline-flex;
  width: 50%;
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;
