import React, { FC, PropsWithChildren } from "react";

import * as s from "./styles";
import { Button, Icon } from "@security-watchdog/ui-components";

type ModalHeaderProps = PropsWithChildren<{
  title: string;
  subTitle?: string;
  onClose?: () => void;
}>;

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  subTitle,
  onClose,
  children
}) => (
  <s.Header $hasChildren={Boolean(children)}>
    <s.HeaderTitleWrapper>
      <div>
        <s.HeaderTitle>{title}</s.HeaderTitle>
        {subTitle && <s.HeaderSubTitle>{subTitle}</s.HeaderSubTitle>}
      </div>
      {onClose && (
        <Button
          dataTestId="modal-close-button"
          variant="ghost"
          onClick={onClose}
        >
          <Icon name="close" className="color-icon-default" />
        </Button>
      )}
    </s.HeaderTitleWrapper>
    {children}
  </s.Header>
);

ModalHeader.displayName = "DesignSystemModalHeader";
