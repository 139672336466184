import styled from "styled-components";
import { NavItems } from "components/Header/NavItem";
import { BurgerMenu } from "components/BurgerMenu";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";
import { IProps } from "./types";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.div<IProps>`
  display: flex;
  justify-content: ${(props: IProps): string =>
    props.$isSticky ? "center" : "space-between"};
  align-items: center;
  box-shadow: 0 4px 2px -2px rgb(221 222 237 / 50%);
  flex-shrink: 0;
  background-color: ${({ theme }): string => theme.colors.white};
  height: 72px;
  padding: ${(props: IProps): string =>
    props.$isSticky ? "0 0 0 40px" : "0 20px"};
  box-sizing: border-box;
  position: ${(props: IProps): string =>
    props.$isSticky ? "sticky" : "relative"};
  top: 0;
  width: 100%;
  left: 0;
  z-index: ${({ theme, $isSticky }): number | undefined =>
    $isSticky ? theme.layers.sticky : undefined};

  &.menu-opened {
    z-index: ${({ theme }): number => theme.layers.overlay};
  }

  @media (max-width: ${DEVICE.TABLET}) {
    justify-content: ${(props: IProps): string =>
      props.$isSticky ? "flex-end" : "space-between"};
    padding: 0 0 0 16px;
    width: 100%;
    z-index: ${({ theme }): number => theme.layers.sticky};
  }
`;

export const TitleHeader = styled(Link)<IProps>`
  ${getTypographyStyles({ color: "tangaroa", variant: "20MediumL30" })};
  left: ${(props: IProps): string => (props.$isSticky ? "20px" : "0")};
  text-decoration: none;
  position: ${(props: IProps): string =>
    props.$isSticky ? "absolute" : "initial"};

  &:hover {
    color: ${({ theme }): string => theme.colors.tangaroa};
  }

  @media (max-width: ${DEVICE.TABLET}) {
    left: 16px;
  }

  &.hidden {
    opacity: 0;
    z-index: -1;
  }
`;

export const NavButtons = styled(NavItems)<IProps>`
  position: ${(props: IProps): string =>
    props.$isSticky ? "absolute" : "initial"};
  right: 20px;

  @media (max-width: ${DEVICE.TABLET}) {
    display: none;
  }
`;

export const BurgerMenuCustom = styled(BurgerMenu)<IProps>`
  @media (min-width: ${DEVICE.TABLET}) {
    display: none;
  }
`;

export const PageTitle = styled.h1`
  ${getTypographyStyles({ color: "tangaroa", variant: "32BoldL40" })};
  margin: 0;
`;

export const PageTitleStatusContainer = styled.div`
  box-sizing: border-box;
  width: 795px;

  .section-title {
    font-size: 16px;
    line-height: 36px;
  }

  .section-status {
    margin-top: 0;
  }
`;
