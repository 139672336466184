export const convertDateStringToDateTime = (
  dateString?: string | null
): number | null | undefined => {
  if (dateString) {
    const dateUnits: string[] = dateString.split("-");
    return new Date(
      parseInt(dateUnits[0], 10),
      parseInt(dateUnits[1], 10) - 1,
      parseInt(dateUnits[2], 10)
    ).valueOf();
  }

  return null;
};
