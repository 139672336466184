import { IRootReducer } from "src/reducers";
import { IReducer } from "modules/screenerAccess/reducers";
import { createSelector } from "reselect";
import { TroubleshootingRequestStatus } from "src/graphQLTypes";
import { takeIsAdminFlow } from "modules/main/selectors";

export const rootSelector = (state: IRootReducer): IReducer =>
  state.screenerAccess;

export const takeScreenerAccessStatus = createSelector(
  rootSelector,
  (state: IReducer): TroubleshootingRequestStatus | null => state.status
);

export const takeIsAcceptingRequestInProgress = createSelector(
  rootSelector,
  (state: IReducer): boolean => state.isAcceptingRequestInProgress
);

export const takeIsCancelRequestInProgress = createSelector(
  rootSelector,
  (state: IReducer): boolean => state.isCancelRequestInProgress
);

export const takeIsTroubleShootingFinishing = createSelector(
  rootSelector,
  (state: IReducer): boolean => state.isTroubleShootingFinishing
);

export const takeScreenerAccessId = createSelector(
  rootSelector,
  (state: IReducer): string | null => state.id
);

export const takeIsAllFormsDisabled = createSelector(
  rootSelector,
  takeIsAdminFlow,
  (state: IReducer, isAdminFlow: boolean) =>
    isAdminFlow
      ? false
      : state.status === TroubleshootingRequestStatus.CANDIDATE_ACCEPTED
);
