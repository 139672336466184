import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";
import { Tile as TileComponent } from "components/Tile";

export const Tile = styled(TileComponent)`
  justify-content: space-between;
  cursor: default;
  height: auto;
  min-height: 80px;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const InfoSectionBlock = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
  }
`;

export const ActivityType = styled.span`
  ${getTypographyStyles({
    variant: "11MediumUppercaseL16",
    color: "stormGray"
  })};
  margin-bottom: 3px;
`;

export const CompanyName = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;

export const Position = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24", color: "stormGray" })};
`;

export const DateRange = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24", color: "stormGray" })};
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  margin-left: 20px;
`;

export const ToolsPanel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: ${DEVICE.TABLET}) {
    margin-top: 10px;
  }
`;

export const EditButton = styled(Button)<IButtonProps>`
  margin-right: 30px;
  color: ${({ theme, isDisabled }): string | undefined =>
    isDisabled ? undefined : theme.colors.royalBlue};
`;

export const DeleteButton = styled(Button)<IButtonProps>`
  color: ${({ theme, isDisabled }): string | undefined =>
    isDisabled ? undefined : theme.colors.mojo};
`;
