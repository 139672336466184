import React, { NamedExoticComponent } from "react";
import { ModalWindow } from "components/ModalWindow";
import { IProps } from "./types";

const ModalSubmitAppComponent: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSubmit,
  text
}: IProps) =>
  isOpen ? (
    <ModalWindow
      primaryBtnText="Submit application"
      title="Confirm action"
      onModalClose={onClose}
      confirmAction={onSubmit}
    >
      {text}
    </ModalWindow>
  ) : null;

export const ModalSubmitApp: NamedExoticComponent<IProps> = React.memo<IProps>(
  ModalSubmitAppComponent
);
