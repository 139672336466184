import React, { FC, memo } from "react";
import * as s from "./styles";
import { CandidateCaseStatus } from "src/graphQLTypes";
import { CANDIDATE_CASE_STATUS_TEXT } from "src/constants";

type Props = {
  status: CandidateCaseStatus;
};

const Component: FC<Props> = ({ status }) => (
  <s.Wrapper>
    <div className="badge margin-r-2">{CANDIDATE_CASE_STATUS_TEXT[status]}</div>
    {status !== CandidateCaseStatus.INACTIVE && (
      <s.BarContainer status={status}>
        <span></span>
        <span></span>
        <span></span>
      </s.BarContainer>
    )}
  </s.Wrapper>
);

Component.displayName = "CaseStatus";

export const CaseStatus = memo(Component);
