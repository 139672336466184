export type StorageMSFileUploadPayload = {
  downloadUrl: string;
  expiresAt: Date;
  fileName: string;
  id: string;
  uploadedBy: string;
  uploadedWhen: number;
};

export type StorageMSFileUploadInput = {
  file?: File;
  fileName?: string;
  container?: string;
  uploadedBy: string;
  candidateEmail: string;
  shared: boolean;
  activationRequired: boolean;
  contractId: string;
};

export enum StorageMSFileUploadStorage {
  UPLOADS = "UPLOADS",
  CONSENT = "CONSENT"
}
