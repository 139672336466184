import { IProps as IModalProps } from "components/Modal/types";

export interface IESignatureProps
  extends Omit<IModalProps, "actionButtons" | "children"> {
  onSave?: (eSignatureFile: File) => void;
}

export enum MODAL_TAB {
  Draw,
  Type,
  Upload
}
