import React, { FC, PropsWithChildren } from "react";
import { Button } from "@security-watchdog/ui-components";

import { DesignSystemModal } from "components/DesignSystemModal";
import { FooterActions } from "./styles";

type TooltipModalProps = PropsWithChildren<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
}>;

export const TooltipModal: FC<TooltipModalProps> = ({
  title,
  isOpen,
  onClose,
  children
}) => {
  return (
    <DesignSystemModal showModal={isOpen} fullHeightMode={false}>
      <DesignSystemModal.Header title={title} onClose={onClose} />
      <DesignSystemModal.Body>{children}</DesignSystemModal.Body>
      <DesignSystemModal.Footer>
        <FooterActions>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </FooterActions>
      </DesignSystemModal.Footer>
    </DesignSystemModal>
  );
};
