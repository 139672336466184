import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  hexToRgb,
  theme,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${`rgba(${hexToRgb(theme.colors.tangaroa)}, 0.3)`};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.white};
  padding: 26px 24px 32px 24px;
  border-radius: 4px;
  max-width: 368px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  ${getTypographyStyles({ variant: "20MediumL30", color: "tangaroa" })};
  margin-bottom: 15px;
`;

export const MainText = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24", color: "mineShaft" })};
  text-align: center;
  margin-bottom: 25px;
`;

export const CloseButton = styled(Button)<IButtonProps>`
  width: 100%;

  & > span {
    width: 100%;
    text-align: center;
  }
`;
