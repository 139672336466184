/* eslint-disable no-console */
import { isAfter, startOfDay } from "date-fns";
import {
  IAnswer,
  IAnswersToValidateMap,
  IFieldAnswer,
  IQuestion,
  ITimeLineRestriction,
  ITimeLineValidationReturnValue,
  IValidateAnswersMap,
  IValidationCondition,
  TimelineEntityName
} from "../types";
import { FieldType, HandleableFieldType } from "../graphQLTypes";
import { getDateUnitText } from "./getDateUnitText";
import { isUndefinedOrNull } from "./typeGuards";
import { getAnswersForConditionalValidaton } from "./getAnswersForConditionalValidaton";
import { NO_VALIDATION_DATA } from "../constants";
import findIndexOfGap, { IDateRange } from "./findIndexOfGap";
import { isGeneralUploadField } from "src/utils/answers";

interface IValidation {
  errorMessage: string;
  previousEndDate: Date;
  hasCurrent?: boolean;
  index: number | null;
}

const validateGap = (
  hasGapAtIndex: number,
  index: number,
  timeLineRestriction: ITimeLineRestriction
): string => {
  const { gap, gapUnit }: ITimeLineRestriction = timeLineRestriction;

  if (hasGapAtIndex === index) {
    return `Allowable gap between answers is ${gap} ${getDateUnitText(
      gapUnit,
      gap
    )}.`;
  }
  return "";
};

const takeAnswerIdsThatHaveUnfilledFields = (
  answers: IAnswer[]
): IValidateAnswersMap =>
  answers.reduce(
    (acc: IValidateAnswersMap, answer: IAnswer): IValidateAnswersMap => {
      const hasUnfilledFields = answer.fields.some(
        (field: IFieldAnswer) =>
          !isGeneralUploadField(field.type) &&
          field.validation.isRequired &&
          field.isVisible &&
          isUndefinedOrNull(field.value)
      );
      if (hasUnfilledFields) {
        return {
          ...acc,
          [answer.answerId]: answer.answerId
        };
      }
      return acc;
    },
    {}
  );

export const noAmountOfAnswersValidation = (
  validation: IValidationCondition,
  answersForValidations: Map<string, IAnswersToValidateMap>
): ITimeLineValidationReturnValue => {
  if (!validation.id) {
    return NO_VALIDATION_DATA;
  }

  if (answersForValidations.has(validation.id)) {
    const answer = answersForValidations.get(validation.id);

    for (let i = 0; i < validation.availableAnswers.length; i++) {
      if (answer && answer[validation.availableAnswers[i]]) {
        return {
          errorMessage: validation.validationMessage,
          index: null
        };
      }
    }
  }

  return NO_VALIDATION_DATA;
};

const validateConditionalValidations = (
  validation: IValidationCondition,
  answersForValidations: Map<string, IAnswersToValidateMap>
): ITimeLineValidationReturnValue => {
  if (!validation.id) {
    return NO_VALIDATION_DATA;
  }

  const isFromValueEmpty = isUndefinedOrNull(
    validation.answersCondition?.minNumberOfAnswers
  );
  const isToValueEmpty = isUndefinedOrNull(
    validation.answersCondition?.maxNumberOfAnswers
  );

  /**
   * Validation for cases, when user is not
   * set any values into from and to fields
   */
  if (isFromValueEmpty && isToValueEmpty) {
    return noAmountOfAnswersValidation(validation, answersForValidations);
  }

  const answerForValidate = answersForValidations.get(validation.id);
  const fromValue = isUndefinedOrNull(
    validation.answersCondition?.minNumberOfAnswers
  )
    ? 0
    : validation.answersCondition?.minNumberOfAnswers;
  const toValue = isUndefinedOrNull(
    validation.answersCondition?.maxNumberOfAnswers
  )
    ? Infinity
    : validation.answersCondition?.maxNumberOfAnswers;
  const validationData = {
    errorMessage: validation.validationMessage,
    index: null
  };

  if (!answerForValidate && fromValue === 0) {
    return validationData;
  }

  if (answerForValidate) {
    let matchedAnswersTotal = 0;
    validation.availableAnswers.forEach((element) => {
      if (answerForValidate[element]) {
        matchedAnswersTotal += answerForValidate[element];
      }
    });

    if (matchedAnswersTotal <= toValue && matchedAnswersTotal >= fromValue) {
      return validationData;
    }
  }

  return NO_VALIDATION_DATA;
};

export const getErrorMessageForTimeLineValidation = (
  answers: IAnswer[],
  timeLineRestriction: ITimeLineRestriction | undefined,
  entityName: TimelineEntityName,
  question: IQuestion | undefined,
  conditionalValidations: IValidationCondition[],
  isScotlandLocation: boolean
): ITimeLineValidationReturnValue => {
  if (
    conditionalValidations.length &&
    entityName === TimelineEntityName.ACTIVITY
  ) {
    const answersForValidations = getAnswersForConditionalValidaton(
      answers,
      conditionalValidations
    );

    for (let i = 0; i < conditionalValidations.length; i++) {
      const validationData = validateConditionalValidations(
        conditionalValidations[i],
        answersForValidations
      );

      if (validationData.errorMessage) {
        return validationData;
      }
    }
  }

  const answerIdsThatHaveUnfilledFieldsMap: IValidateAnswersMap =
    takeAnswerIdsThatHaveUnfilledFields(answers);

  if (Object.keys(answerIdsThatHaveUnfilledFieldsMap).length) {
    return {
      errorMessage: "Some answer has missing required fields.",
      answerIdsMap: answerIdsThatHaveUnfilledFieldsMap,
      index: null
    };
  }

  if (
    entityName === TimelineEntityName.ADDRESS &&
    !question?.isMultipleAnswers
  ) {
    // current address have just one answer ( all time )
    const answer: IAnswer | undefined = question?.answers[0];

    const dateField = answer?.fields.find(
      (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
    );

    if (
      dateField &&
      dateField.value?.from &&
      isAfter(startOfDay(dateField.value.from), startOfDay(new Date()))
    ) {
      return {
        errorMessage: "Current address cannot be in the future",
        index: null
      };
    }
  }

  const dateRangeField = question?.fields.find(
    (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
  );

  if (
    !dateRangeField?.validation.gapRestriction &&
    entityName === TimelineEntityName.ACTIVITY
  ) {
    return {
      index: null,
      errorMessage: ""
    };
  }

  const hasCurrentEntity: boolean = answers.some((answer: IAnswer) =>
    answer.fields.some(
      (field: IFieldAnswer) =>
        field.type === FieldType.BOOLEAN &&
        (field.fieldType === HandleableFieldType.CURRENT_ADDRESS ||
          field.fieldType === HandleableFieldType.CURRENT_EMPLOYER) &&
        field.value
    )
  );

  if (!hasCurrentEntity) {
    return {
      errorMessage: `At least one current ${entityName} should be provided`,
      index: null
    };
  }

  if (!timeLineRestriction) {
    return {
      index: null,
      errorMessage: ""
    };
  }

  const lastIndex: number = answers.length - 1;

  const dateRanges: IDateRange[] = answers.map(
    (answer) =>
      answer.fields.find((field) => field.type === FieldType.DATE_RANGE)
        ?.value as IDateRange
  );

  const hasGapAtIndex = findIndexOfGap(dateRanges, timeLineRestriction);

  const validation: IValidation = answers.reduce(
    (acc: IValidation, answer: IAnswer, index: number): IValidation => {
      const dateRangeField: IFieldAnswer | undefined = answer.fields.find(
        (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
      );
      const currentField: IFieldAnswer | undefined = answer.fields.find(
        (field: IFieldAnswer) =>
          field.type === FieldType.BOOLEAN &&
          (field.fieldType === HandleableFieldType.CURRENT_ADDRESS ||
            field.fieldType === HandleableFieldType.CURRENT_EMPLOYER)
      );

      if (
        !dateRangeField ||
        !currentField ||
        acc.errorMessage ||
        acc.hasCurrent
      ) {
        return acc;
      }
      if (
        index !== lastIndex &&
        currentField.value &&
        entityName === TimelineEntityName.ADDRESS
      ) {
        return {
          errorMessage: `Only the last ${entityName} can be current.`,
          previousEndDate: dateRangeField.value?.to,
          index
        };
      }

      if (
        isScotlandLocation &&
        index > 0 &&
        entityName === TimelineEntityName.ADDRESS
      ) {
        const date = answers[index - 1].fields.find(
          (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
        )?.value.to;

        if (
          date &&
          new Date(dateRangeField.value.from).getTime() <
            new Date(date).getTime()
        ) {
          return {
            errorMessage: `This entry starts before the previous entry ends. Please check and amend the dates`,
            previousEndDate: dateRangeField.value?.to,
            index
          };
        }
      }

      const gapValidation: IValidation = {
        index,
        errorMessage: validateGap(hasGapAtIndex, index, timeLineRestriction),
        previousEndDate:
          acc.previousEndDate > dateRangeField.value.to
            ? acc.previousEndDate
            : dateRangeField.value.to,
        hasCurrent: Boolean(
          entityName === TimelineEntityName.ACTIVITY &&
            !dateRangeField.value?.to
        )
      };

      if (gapValidation.errorMessage && answers.length < 2) {
        return {
          index: null,
          errorMessage:
            entityName === TimelineEntityName.ADDRESS
              ? "Previous address required"
              : "Employment and Education information is required",
          previousEndDate:
            acc.previousEndDate > dateRangeField.value.to
              ? acc.previousEndDate
              : dateRangeField.value.to,
          hasCurrent: Boolean(
            entityName === TimelineEntityName.ACTIVITY &&
              !dateRangeField.value?.to
          )
        };
      }

      return gapValidation;
    },
    {
      errorMessage: "",
      previousEndDate: timeLineRestriction.startDate,
      index: null
    }
  );
  return {
    errorMessage: validation.errorMessage,
    index: validation.index
  };
};
