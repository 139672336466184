import { useLayoutEffect, useRef } from "react";

export const useScrollToTop = (): void => {
  const documentRootRef = useRef<HTMLDivElement>(
    document.querySelector("#root")
  );

  useLayoutEffect(() => {
    documentRootRef?.current?.scrollTo(0, 0);
  }, []);
};
