import { UploadedFile } from "@security-watchdog/ui-components";

import { fieldAnyValueFragment_Attachment } from "src/graphQLTypes";
import {
  isAttachmentValue,
  isUploadFile,
  nonNullable
} from "src/utils/typeGuards";
import { DropzoneAttachmentToValidate } from "src/types/documents";

const generateUploadFileId = (file: File, prefix = ""): string =>
  `${prefix}${file.name}-${file.size}`;

export const getDropzoneUploadedFiles = (
  files: Array<fieldAnyValueFragment_Attachment | File>
): UploadedFile[] =>
  files
    .map((file) => {
      if (isAttachmentValue(file)) {
        return {
          id: file.id,
          downloadUrl: file.downloadUrl,
          fileName: file.fileName,
          lastModified: file.uploadDate
        };
      }
      if (isUploadFile(file)) {
        return {
          id: generateUploadFileId(file),
          downloadUrl: file.webkitRelativePath,
          fileName: file.name,
          lastModified: file.lastModified
        };
      }
      return null;
    })
    .filter(nonNullable);

export const getDropzoneFilesToValidate = (
  files: Array<fieldAnyValueFragment_Attachment | File>
): Array<DropzoneAttachmentToValidate | File> =>
  (files || []).map((file) => {
    if (isAttachmentValue(file)) {
      return {
        ...file,
        name: file.fileName,
        size: 0
      };
    }
    return file;
  });
