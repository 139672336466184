import { getCase_screeningCase_sections_answers } from "src/graphQLTypes";
import { KonfirButtonState } from "containers/KonfirPage/types";
import { getKonfirFirstDifficultiesBooleanAnswerValue } from "containers/KonfirPage/utils";

type UseGoToKonfirBtnState = {
  buttonState: KonfirButtonState;
};

type UseGoToKonfirBtnStateArgs = {
  sectionAnswers: getCase_screeningCase_sections_answers[];
  isCompleted: boolean;
};

export const useGoToKonfirBtnState = ({
  sectionAnswers,
  isCompleted
}: UseGoToKonfirBtnStateArgs): UseGoToKonfirBtnState => {
  const difficultiesQuestionAnswerValue =
    getKonfirFirstDifficultiesBooleanAnswerValue(sectionAnswers);

  const getButtonState = () => {
    if (difficultiesQuestionAnswerValue || isCompleted) {
      return {
        isVisible: true,
        isDisabled: true
      };
    }

    return {
      isVisible: true,
      isDisabled: false
    };
  };

  return {
    buttonState: getButtonState()
  };
};
