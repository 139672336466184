import { Configuration } from "@azure/msal-browser/dist/config/Configuration";
import { BrowserConfiguration } from "@azure/msal-browser";
import { ROUTES } from "src/constants";
import { isIE } from "src/utils/isIE";
import { getScreenerAuthority } from "./getScreenerAuthority";

type ScreenerAzureVariables = {
  AZURE_SCREENER_TENANT_ID: string;
  AZURE_SCREENER_CLIENT_ID: string;
};

export const getScreenerAzureConfig = ({
  AZURE_SCREENER_TENANT_ID,
  AZURE_SCREENER_CLIENT_ID
}: ScreenerAzureVariables): Configuration | BrowserConfiguration => {
  return {
    auth: {
      clientId: AZURE_SCREENER_CLIENT_ID,
      authority: getScreenerAuthority({ AZURE_SCREENER_TENANT_ID }),
      postLogoutRedirectUri: window.location.origin,
      redirectUri: `${window.location.origin}${ROUTES.AUTHENTICATE_SCREENER}`,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: isIE() // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
};
