class AsyncValue<T> {
  private value: T | undefined;
  private isResolved = false;
  private isFetching = false;
  private onResolve: Array<() => void> = [];
  constructor(private readonly getValue: () => Promise<T>) {}
  async get(): Promise<T> {
    if (this.isResolved) {
      return this.value as T;
    } else if (this.isFetching) {
      await this.whenResolved();
      return this.value as T;
    } else {
      this.isFetching = true;
      const value = await this.getValue();
      this.isResolved = true;
      this.value = value;
      this.onResolve.forEach((cb) => cb());
      return value;
    }
  }
  async whenResolved(): Promise<void> {
    if (this.isResolved) {
      return;
    }
    return new Promise((resolve) => {
      this.onResolve.push(resolve);
    });
  }
}

export default AsyncValue;
