import { FieldType } from "../../../../graphQLTypes";

export const isInputField = (fieldType: FieldType): boolean =>
  [
    FieldType.STRING,
    FieldType.EMAIL,
    FieldType.PHONE_NUMBER,
    FieldType.INTEGER,
    FieldType.HIRING_MANAGER_EMAIL,
    FieldType.PASSPORT,
    FieldType.NATIONAL_INSURANCE_NUMBER,
    FieldType.FIRST_NAME,
    FieldType.SURNAME
  ].includes(fieldType);

export const isDateField = (fieldType: FieldType): boolean =>
  FieldType.DATE === fieldType;

export const isDateRangeField = (fieldType: FieldType): boolean =>
  FieldType.DATE_RANGE === fieldType;

export const isSelectField = (fieldType: FieldType): boolean =>
  FieldType.SELECT === fieldType;

export const isBooleanField = (fieldType: FieldType): boolean =>
  [FieldType.BOOLEAN, FieldType.CONTACT_EMPLOYER].includes(fieldType);

export const isCountryField = (fieldType: FieldType): boolean =>
  FieldType.COUNTRY === fieldType;

export const isAddressField = (fieldType: FieldType): boolean =>
  FieldType.ADDRESS === fieldType;

export const isCompanyField = (fieldType: FieldType): boolean =>
  FieldType.COMPANY === fieldType;

export const isMultipleStingField = (fieldType: FieldType): boolean =>
  fieldType === FieldType.MULTIPLE_STRING;

export const isQualificationAndGradesField = (fieldType: FieldType): boolean =>
  fieldType === FieldType.QUALIFICATIONS_AND_GRADES;

export const isNumericInputFormField = (fieldType: FieldType): boolean =>
  fieldType === FieldType.INTEGER;

export const isPhoneNumberInputFormField = (fieldType: FieldType): boolean =>
  fieldType === FieldType.PHONE_NUMBER;

export const isEmailInputFormField = (fieldType: FieldType): boolean =>
  [FieldType.EMAIL, FieldType.HIRING_MANAGER_EMAIL].includes(fieldType);

const textTypes = [
  "email",
  "password",
  "search",
  "tel",
  "text",
  "number"
] as const;

export type InputType = (typeof textTypes)[number];

export const getInputFieldType = (fieldType: FieldType): InputType => {
  if (isEmailInputFormField(fieldType)) {
    return "email";
  }

  if (isPhoneNumberInputFormField(fieldType)) {
    return "tel";
  }

  return "text";
};
