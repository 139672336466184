import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const ContainerDisclaimer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.snuff};
  padding: 18px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContainerTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  @media (max-width: ${DEVICE.TABLET}) {
    margin-right: 0;
  }
`;
export const ContainerButton = styled.div`
  display: flex;

  @media (max-width: ${DEVICE.TABLET}) {
    margin-top: 15px;
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const CustomButton = styled(Button)<IButtonProps>`
  margin-right: 15px;
  height: 40px;

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    margin-right: 0;
    justify-content: center;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const PrimaryText = styled.div`
  ${getTypographyStyles({ variant: "20MediumL30" })};
`;

export const SecondaryText = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;
