import { gql } from "@apollo/client";

export const GET_CASE_INFORMATION = gql`
  query GetCaseInformation($id: ID!) {
    screeningCase(id: $id) {
      id
      whenCreated
      whenCompleted
      status
      client {
        name
      }
      candidate {
        firstName
        lastName
      }
      assignee {
        id
        name
      }
      products {
        id
        status
        name
        candidateDataRequest {
          id
          attachments {
            downloadUrl
            fileName
            id
            uploadDate
            uploadedBy
          }
          content
          attachmentsAllowed
          replyData {
            content
            attachments {
              downloadUrl
              fileName
              id
            }
          }
        }
      }
    }
  }
`;
