import { IAnswer, IQuestion } from "../types";
import {
  CandidateCaseFragment_consentForm,
  ConsentType,
  FieldType,
  getCase_screeningCase_sections,
  getCase_screeningCase_sections_answers,
  getCase_screeningCase_sections_answers_question_fields,
  HandleableFieldType,
  QuestionType
} from "../graphQLTypes";
import { SECTION_SORT_INDEX } from "../constants";
import { isUndefinedOrNull } from "./typeGuards";
import { isGeneralUploadField } from "src/utils";

export const getAnswersWithFieldTypeUpload = (
  sections: getCase_screeningCase_sections[]
): getCase_screeningCase_sections_answers[] => {
  const sortedSections: getCase_screeningCase_sections[] = [...sections].sort(
    (a: getCase_screeningCase_sections, b: getCase_screeningCase_sections) =>
      SECTION_SORT_INDEX[a.section] - SECTION_SORT_INDEX[b.section]
  );
  return sortedSections.reduce(
    (
      acc: getCase_screeningCase_sections_answers[],
      section: getCase_screeningCase_sections
    ) => {
      const answersWidthUploadField: getCase_screeningCase_sections_answers[] =
        section.answers.filter(
          (answer: getCase_screeningCase_sections_answers) =>
            answer.question.fields.some(
              (field: getCase_screeningCase_sections_answers_question_fields) =>
                isGeneralUploadField(field.type)
            )
        );
      return [...acc, ...answersWidthUploadField];
    },
    []
  );
};

const generateConsentFormQuestion = (
  consentForm: CandidateCaseFragment_consentForm | null | undefined
): IQuestion => ({
  isVisible: true,
  conditional: false,
  conditionFulfilled: false,
  questionConditionsMap: {},
  questionId: "consentFormQuestionId",
  title: "Declaration of Authority form",
  description:
    "Please sign the Declaration of Authority form included in your welcome email and attach a scanned copy or clear photograph.",
  questionType: QuestionType.OTHER,
  isUploadQuestion: true,
  isMultipleAnswers: false,
  isConsentFormQuestion: true,
  completed: Boolean(consentForm),
  fields: [],
  answers: [
    {
      index: 0,
      answerId: "consentFormAnswerId",
      isAnswered: false,
      fields: [
        {
          isHighlightedWhenOcrUploaded: false,
          answerIndex: 0,
          fieldId: "consentFormFieldId",
          value: consentForm,
          title: "",
          type: FieldType.UPLOAD,
          fieldType: HandleableFieldType.OTHER,
          isVisible: true,
          isConditional: false,
          hasDependentFields: false,
          conditions: [],
          isMulti: false,
          description: "",
          isRoleLocationScotland: false,
          validation: {
            isRequired: true,
            isMulti: false,
            dependentField: undefined
          }
        }
      ]
    }
  ]
});

export const getQuestionsOfMyDocumentSection = (
  questions: IQuestion[],
  consentType: ConsentType,
  consentForm: CandidateCaseFragment_consentForm | null | undefined
): IQuestion[] =>
  questions.reduce(
    (acc: IQuestion[], question: IQuestion) => {
      const answersWidthUpload: IAnswer[] = question.answers.filter((answer) =>
        answer.fields.some(
          (field) => isGeneralUploadField(field.type) && field.isVisible
        )
      );

      const isUploadQuestion = question.fields.every((field) =>
        isGeneralUploadField(field.type)
      );

      const visibleAnswers: IAnswer[] = isUploadQuestion
        ? answersWidthUpload
        : answersWidthUpload.filter((answer: IAnswer) => answer.isAnswered);

      if (visibleAnswers.length) {
        const completed = visibleAnswers.every((answer) =>
          answer.fields.every((field) =>
            field.validation.isRequired && field.isVisible
              ? !isUndefinedOrNull(field.value)
              : true
          )
        );

        return [
          ...acc,
          {
            ...question,
            answers: answersWidthUpload,
            isUploadQuestion,
            completed
          }
        ];
      }
      return acc;
    },
    [
      ...(consentType === ConsentType.WET_SIGNATURE
        ? [generateConsentFormQuestion(consentForm)]
        : [])
    ]
  );
