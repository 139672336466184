import {
  CandidateCaseProductStatus,
  CandidateCaseStatus,
  GetCaseInformation_screeningCase_products
} from "src/graphQLTypes";

export const getCaseStatus = (
  caseStatus: CandidateCaseStatus | undefined,
  caseProducts: GetCaseInformation_screeningCase_products[] | undefined
) => {
  const hasProductWithAwaitingCandidateStatus = caseProducts?.some(
    (product) =>
      product.status === CandidateCaseProductStatus.AWAITING_CANDIDATE
  );

  if (hasProductWithAwaitingCandidateStatus) {
    return CandidateCaseStatus.CANDIDATE_ACTION_REQUIRED;
  }

  return caseStatus;
};
