import {
  attachmentFragment,
  fieldAnyValueFragment_Attachment,
  FieldType,
  FieldValueInput
} from "src/graphQLTypes";
import { isArrayOfAttachments, isAttachmentValue } from "src/utils/typeGuards";
import { IQuestion } from "src/types";

export const isUploadField = (fieldType: FieldType): boolean =>
  fieldType === FieldType.UPLOAD;

export const isMultiUploadField = (fieldType: FieldType): boolean =>
  fieldType === FieldType.MULTIPLE_UPLOAD;

export const isGeneralUploadField = (fieldType: FieldType): boolean =>
  isUploadField(fieldType) || isMultiUploadField(fieldType);

export const getFieldAttachments = (
  value: unknown
): fieldAnyValueFragment_Attachment[] => {
  if (isAttachmentValue(value)) {
    return [value];
  }

  if (isArrayOfAttachments(value)) {
    return value;
  }

  return [];
};

export const getMultiUploadFieldValue = (
  fieldId: string,
  files: File[],
  attachments: fieldAnyValueFragment_Attachment[] = []
): FieldValueInput => ({
  fieldId,
  uploadIndexes: files.map((_, idx) => idx),
  attachmentValues: attachments.map(
    ({ fileName, id, uploadedBy, uploadDate, downloadUrl }) => ({
      fileName,
      id,
      uploadedBy,
      uploadDate,
      downloadUrl
    })
  )
});

export const getUploadFieldValue = (
  fieldId: string,
  attachment?: attachmentFragment
): FieldValueInput => {
  if (!attachment) {
    return { fieldId };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename: _, ...attachmentValue } = attachment;

  return {
    fieldId,
    attachmentValue
  };
};

const findDifferenceBetweenTwoArrays = (
  arr1: string[],
  arr2: string[]
): string[] =>
  arr1
    .filter((i) => !arr2.includes(i))
    .concat(arr2.filter((i) => !arr1.includes(i)));

type CheckConditionalProductQuestionsTriggeredResult = {
  isQuestionAdded: boolean;
  isQuestionDeleted: boolean;
  conditionalQuestionId: string;
};

export const checkConditionalProductQuestionsTriggered = (
  newQuestions: IQuestion[],
  prevQuestions: IQuestion[]
): CheckConditionalProductQuestionsTriggeredResult => {
  const prevQuestionIds = prevQuestions.map((i) => i.questionId);
  const newQuestionIds = newQuestions.map((i) => i.questionId);

  return {
    isQuestionAdded: newQuestionIds.length > prevQuestionIds.length,
    isQuestionDeleted: newQuestionIds.length < prevQuestionIds.length,
    conditionalQuestionId: findDifferenceBetweenTwoArrays(
      newQuestionIds,
      prevQuestionIds
    )[0]
  };
};
