import React, { FC, useCallback, useState } from "react";

import { useDeviceType } from "src/hooks/useDeviceType";
import { ProductActionAnswerDrawer } from "containers/SuccessSubmit/components/ProductActionAnswerDrawer";
import { OpenedProduct } from "containers/SuccessSubmit/hooks";
import { ProductActionAnswerModal } from "containers/SuccessSubmit/components/ProductActionAnswerModal";
import { useProductActionAnswerForm } from "containers/SuccessSubmit/hooks/useProductActionAnswerForm";
import { DesignSystemConfirmModal } from "components/common/DesignSystemConfirmModal";
import { ProductActionAnswerForm } from "containers/SuccessSubmit/components/ProductActionAnswerForm";

type ProductActionAnswerDetailsProps = {
  product?: OpenedProduct | null;
  onClose: () => void;
  isOpen: boolean;
  refetchCase: () => void;
};

export const ProductActionAnswerDetails: FC<
  ProductActionAnswerDetailsProps
> = ({ isOpen, onClose, product, refetchCase }) => {
  const { isMobile } = useDeviceType();
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);
  const {
    formValues,
    formErrors,
    formControl,
    formReset,
    formTrigger,
    submitForm
  } = useProductActionAnswerForm(product?.candidateDataRequest?.id || "");

  const onProductDetailsClose = useCallback(() => {
    onClose();
    formReset();
  }, [formReset, onClose]);

  const onProductDetailsCloseHandler = useCallback(() => {
    if (formValues.description || formValues.attachments?.length) {
      return setIsConfirmModalVisible(true);
    }
    onProductDetailsClose();
  }, [formValues.description, formValues.attachments, onProductDetailsClose]);

  const onConfirmModalOk = useCallback(() => {
    setIsConfirmModalVisible(false);
    onProductDetailsClose();
  }, [onProductDetailsClose]);

  const onConfirmModalClose = () => setIsConfirmModalVisible(false);

  const handleSubmitForm = useCallback(async () => {
    const isSubmitSuccessful = await submitForm();

    if (isSubmitSuccessful) {
      onProductDetailsClose();
      refetchCase();
    }
  }, [submitForm, onProductDetailsClose, refetchCase]);

  const renderForm = () => {
    return (
      <ProductActionAnswerForm
        formControl={formControl}
        formErrors={formErrors}
        formTrigger={formTrigger}
        formValues={formValues}
        messageFromScreener={product?.candidateDataRequest?.content || ""}
        attachmentsFromScreener={
          product?.candidateDataRequest?.attachments || []
        }
        candidateCanAttachFiles={Boolean(
          product?.candidateDataRequest?.attachmentsAllowed
        )}
      />
    );
  };

  return (
    <>
      <DesignSystemConfirmModal
        showModal={isConfirmModalVisible}
        onConfirm={onConfirmModalOk}
        onModalClose={onConfirmModalClose}
      >
        <span className="body-default">
          Are you sure you want to cancel sending the message? All entered
          information will be lost
        </span>
      </DesignSystemConfirmModal>

      {isMobile ? (
        <ProductActionAnswerModal
          title={product?.name || ""}
          isOpen={isOpen}
          onClose={onProductDetailsCloseHandler}
          onSubmit={handleSubmitForm}
        >
          {renderForm()}
        </ProductActionAnswerModal>
      ) : (
        <ProductActionAnswerDrawer
          title={product?.name || ""}
          isOpen={isOpen}
          onClose={onProductDetailsCloseHandler}
          onSubmit={handleSubmitForm}
        >
          {renderForm()}
        </ProductActionAnswerDrawer>
      )}
    </>
  );
};
