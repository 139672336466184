import React, { Context } from "react";

export type IAuthContextValueProps = {
  isLoading: boolean;
  isAuthenticated: boolean;
  logout: () => void;
};

export const DEFAULT_AUTH_CONTEXT_VALUE: IAuthContextValueProps = {
  isLoading: false,
  isAuthenticated: false,
  logout: () => null
};

export const AuthContext: Context<IAuthContextValueProps> =
  React.createContext<IAuthContextValueProps>(DEFAULT_AUTH_CONTEXT_VALUE);

export const AuthContextProvider = AuthContext.Provider;
