import React, { useMemo } from "react";
import { ITimeLineRestriction, TimelineEntityName } from "src/types";
import {
  takeAddressTimeLineQuestion,
  takeAddressTimeLineRestriction,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import { useSelector } from "react-redux";
import { getDateUnitText } from "src/utils";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageTimelineData } from "modules/case/types";
import { TimelineSectionDetails } from "../common/TimelineSectionDetails";

const AddressTimeLineComponent: React.FC = () => {
  const data: IPageTimelineData = useSelector(takeAddressTimeLineQuestion);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const timeLineRestriction: ITimeLineRestriction | undefined = useSelector(
    takeAddressTimeLineRestriction
  );

  const description: string = useMemo(() => {
    if (!data.question?.isMultipleAnswers) {
      return "Please give us details of your current address";
    }

    return timeLineRestriction?.duration
      ? `Please share your address history for the past ${
          timeLineRestriction.duration
        } ${getDateUnitText(
          timeLineRestriction.durationUnit,
          timeLineRestriction.duration
        )}`
      : "Please share your address history";
  }, [data.question, timeLineRestriction]);

  return (
    <TimelineSectionDetails
      sectionStatus={data.status}
      saveDraftStatusState={saveDraftStatusState}
      section={Section.ADDRESS_HISTORY}
      title="Address timeline"
      description={description}
      entityName={TimelineEntityName.ADDRESS}
      question={data.question}
      timeLineRestriction={timeLineRestriction}
    />
  );
};

export const AddressTimeLine = React.memo(AddressTimeLineComponent);

export default AddressTimeLine;
