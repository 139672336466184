import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ROUTES } from "src/constants";
import { takeLogoVisibilityStatus } from "modules/case/selectors";
import { SectionStatus } from "components/common/SectionStatus";
import * as s from "./styles";
import { IHeaderProps } from "./types";
import { LogoColorFulIcon } from "@security-watchdog/sw-ui-kit";

export const Header = React.forwardRef<HTMLDivElement, IHeaderProps>(
  (
    { title, sectionStatus, saveDraftStatus, isSticky }: IHeaderProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const isLogoVisible: boolean = useSelector(takeLogoVisibilityStatus);

    const [isShowMenu, setShowMenu]: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>
    ] = useState<boolean>(false);

    return (
      <s.HeaderContainer
        className={`${isShowMenu ? "menu-opened" : ""}`}
        role="banner"
        $isSticky={isSticky}
        ref={ref}
      >
        <s.TitleHeader
          to={`${ROUTES.ROOT}${window.location.search}`}
          className={`logo-header ${isLogoVisible ? "" : "hidden"}`}
          $isSticky={isSticky}
        >
          <LogoColorFulIcon />
        </s.TitleHeader>
        {!isLogoVisible && (
          <s.PageTitleStatusContainer>
            <s.PageTitle className="section-title">{title}</s.PageTitle>
            {sectionStatus && (
              <SectionStatus
                sectionStatus={sectionStatus}
                saveDraftStatus={saveDraftStatus}
              />
            )}
          </s.PageTitleStatusContainer>
        )}
        <s.NavButtons $isSticky={isSticky} />
        <s.BurgerMenuCustom
          isOpened={isShowMenu}
          handleClick={setShowMenu}
          $isSticky={isSticky}
        />
      </s.HeaderContainer>
    );
  }
);

Header.displayName = "Header";
