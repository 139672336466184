import axios from "axios";

import { getToken } from "src/msal";
import { IPublicClientApplication } from "@azure/msal-browser";

const logoutFromBackEnd = async () => {
  await axios.post("/api/logout", null, {
    headers: {
      authorization: await getToken()
    },
    responseType: "json",
    baseURL: window.location.origin
  });
};

export const logout = async (instance: IPublicClientApplication) => {
  await logoutFromBackEnd();
  await instance.logout();
};
