import React, { FC, useCallback, useState } from "react";
import {
  AlertCircleIcon,
  theme,
  LifebuoyIcon
} from "@security-watchdog/sw-ui-kit";
import { TermsAndConditionsModal } from "components/TermsAndConditionsModal";
import * as s from "./styles";
import { useDeviceType } from "src/hooks/useDeviceType";
import { DesignSystemConfirmModal } from "components/common/DesignSystemConfirmModal";
import {
  useDifficultiesBtnState,
  useGoToKonfirBtnState,
  useKonfirVerificationLinkCreate
} from "containers/KonfirPage/hooks";
import { Alert, Button, Icon } from "@security-watchdog/ui-components";
import { getCase_screeningCase_sections } from "src/graphQLTypes";

type KonfirPageContentProps = {
  sectionData: getCase_screeningCase_sections | undefined;
  caseId: string;
  onDifficultiesConfirmed: () => void;
};

export const KonfirPageContent: FC<KonfirPageContentProps> = ({
  sectionData,
  onDifficultiesConfirmed,
  caseId
}) => {
  const { isMobile } = useDeviceType();

  const [isTermsModalOpened, setIsTermsModalOpened] = useState<boolean>(false);

  const [isDifficultiesModalOpened, setIsDifficultiesModalOpened] =
    useState<boolean>(false);

  const {
    buttonState: difficultiesBtnState,
    setDifficultiesButtonVisibilityToStorage
  } = useDifficultiesBtnState({
    sectionAnswers: sectionData?.answers || [],
    isCompleted: !!sectionData?.completed
  });

  const { buttonState: goToKonfirBtnState } = useGoToKonfirBtnState({
    sectionAnswers: sectionData?.answers || [],
    isCompleted: !!sectionData?.completed
  });

  const { createKonfirGuestLink, submitKonfirSection, isLoading } =
    useKonfirVerificationLinkCreate({
      sectionAnswers: sectionData?.answers || [],
      caseId
    });

  const onTermsModalClose = useCallback(() => {
    setIsTermsModalOpened(false);
    setDifficultiesButtonVisibilityToStorage();
  }, [setDifficultiesButtonVisibilityToStorage]);

  const onTermsModalConfirm = useCallback(async () => {
    setIsTermsModalOpened(false);

    const url = await createKonfirGuestLink();

    if (url) {
      window.location.href = url;
    }
    setDifficultiesButtonVisibilityToStorage();
  }, [createKonfirGuestLink, setDifficultiesButtonVisibilityToStorage]);

  const onDifficultiesModalClose = useCallback(() => {
    setIsDifficultiesModalOpened(false);
  }, []);

  const onDifficultiesModalConfirm = useCallback(() => {
    setIsDifficultiesModalOpened(false);

    submitKonfirSection(true);

    onDifficultiesConfirmed();
  }, [onDifficultiesConfirmed, submitKonfirSection]);

  const goToKonfirHandler = useCallback(() => {
    setIsTermsModalOpened(true);
  }, []);

  const onDifficultiesBtnClick = useCallback(() => {
    setIsDifficultiesModalOpened(true);
  }, []);

  return (
    <s.Container>
      <TermsAndConditionsModal
        headerSubTitle="Agree to data processing by Konfir"
        isOpen={isTermsModalOpened}
        onClose={onTermsModalClose}
        onConfirm={onTermsModalConfirm}
      >
        <div className="body-wide-line color-text-default">
          <p className="margin-b-4">
            I authorise Matrix Security Watchdog to share my personal data with
            Konfir (UK) Ltd (Konfir) for the purpose of employment verification.
          </p>
          <p className="margin-b-4">
            I understand that Konfir will process my data in accordance with UK
            Data Protection legislation and that the results will be shared with
            Matrix Security Watchdog and my prospective employer (or prospective
            client where I will not directly be employed).
          </p>
          <p className="margin-b-4">
            I confirm that I have read and understood{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.securitywatchdog.org.uk/privacy-policy"
              className="notes-default color-text-on-surface"
            >
              Matrix Security Watchdog’s Privacy Notice.
            </a>
          </p>
          <p className="margin-b-4">
            I confirm that I have read my prospective employer’s Privacy Notice,
            as the Data Controller, and understand my subject rights under UK
            GDPR and understand the legal basis for processing my personal
            information.
          </p>
          <p>
            I confirm that I have read and understood{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.konfir.com/legal/privacy-policy"
              className="notes-default color-text-on-surface"
            >
              Konfir’s Privacy Notice
            </a>{" "}
            and understand how my personal data will be processed during the
            employment verification process.
          </p>
        </div>
      </TermsAndConditionsModal>
      <DesignSystemConfirmModal
        showModal={isDifficultiesModalOpened}
        onConfirm={onDifficultiesModalConfirm}
        onModalClose={onDifficultiesModalClose}
      >
        <>
          <s.ConfirmModalSubHeader>
            Are you sure you want to proceed?
          </s.ConfirmModalSubHeader>
          <Alert variant="warning">
            <s.ConfirmModalText>
              Verifying your identity is a mandatory part of screening process.
              Our team will contact you to discuss alternative methods of
              verifying your identity if you are unable to complete this part of
              the form. This will delay your screening.
            </s.ConfirmModalText>
          </Alert>
        </>
      </DesignSystemConfirmModal>
      <s.Section>
        <s.Title>About Konfir</s.Title>
        <s.Text>
          Konfir is a platform that improves the employment verification process
          by providing instant access to secure employment and payroll records.
          Integrations are utilised to ensure that employment verifications are
          quick, reliable and fully compliant with GDPR standards. This service
          helps streamline the hiring process for employers and candidates while
          maintaining the highest levels of security and data privacy. Konfir is
          GDPR compliant, ICO registered, ISO 27001 certified, and certified as
          an Attribute Service Provider under the UK Government Digital Identity
          and Attributes Trust Framework.
        </s.Text>
      </s.Section>
      <s.Section>
        <s.Title>How it works</s.Title>
        <s.Text>
          Konfir automatically verifies employment history by connecting to
          various data sources, including payroll systems, HMRC and open
          banking. This information is then securely retrieved and verified,
          speeding up the referencing process.
        </s.Text>
      </s.Section>
      <s.PageInfoBanner>
        <s.InfoBannerIcon>
          <AlertCircleIcon size={20} color={theme.colors.iconWarning} />
        </s.InfoBannerIcon>
        <div>
          Upon accepting the terms and conditions you will be redirected to the
          Konfir form. After completing the form in Konfir you will be
          redirected back to our Candidate Portal to complete your screening.
        </div>
      </s.PageInfoBanner>
      <s.ActionsWrapper $isMobile={isMobile}>
        {goToKonfirBtnState.isVisible && (
          <Button
            dataTestId="go-to-konfir-button"
            onClick={goToKonfirHandler}
            disabled={goToKonfirBtnState.isDisabled || isLoading}
          >
            {isLoading && <Icon size={16} name="loading" />}
            <span>Go to Konfir form</span>
          </Button>
        )}
        {difficultiesBtnState.isVisible && (
          <Button
            dataTestId="unable-to-complete-button"
            variant="ghost"
            onClick={onDifficultiesBtnClick}
            disabled={difficultiesBtnState.isDisabled || isLoading}
          >
            <LifebuoyIcon
              className="margin-r-1"
              size={16}
              color={
                difficultiesBtnState.isDisabled
                  ? theme.colors.lightGray
                  : theme.colors.blueActive
              }
            />
            <span>Unable to complete Konfir</span>
          </Button>
        )}
      </s.ActionsWrapper>
    </s.Container>
  );
};
