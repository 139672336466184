import React from "react";
import {
  FolderIcon,
  ChevronIcon,
  DIRECTION
} from "@security-watchdog/sw-ui-kit";
import { ISection } from "containers/SectionList/types";
import { StatusCard } from "components/StatusCard";
import { Tile } from "components/TimelineCard/styles";
import { IProps } from "./types";
import * as s from "./styles";

export const SectionsList: React.FC<IProps> = ({
  sectionList,
  onSectionItemClick
}: IProps) => (
  <s.ContainerListSectionItems role="navigation">
    {sectionList.map((item: ISection) => (
      <Tile key={item.id} onClick={onSectionItemClick(item.path)}>
        <s.InfoSectionBlock>
          <FolderIcon />
          <s.NameSectionBlock>
            <s.TitleSection>
              <s.TitleCard>{item.title}</s.TitleCard>
              <StatusCard title={item.status} status={item.status} />
            </s.TitleSection>
            <s.Description>{item.description}</s.Description>
          </s.NameSectionBlock>
          <s.ArrowIconForMobile>
            <ChevronIcon direction={DIRECTION.RIGHT} size={14} />
          </s.ArrowIconForMobile>
        </s.InfoSectionBlock>
      </Tile>
    ))}
  </s.ContainerListSectionItems>
);
