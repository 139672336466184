import { Portal } from "components/Portal";
import { TimerCountDown } from "components/TimerCountDown";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { BUTTON_TYPE } from "@security-watchdog/sw-ui-kit";
import { TIME_OF_IDLE } from "src/constants";
import * as s from "./styles";
import { AuthContext, IAuthContextValueProps } from "src/context/auth";

export const IdleTimerComponent: React.FC<PropsWithChildren> = ({
  children
}: PropsWithChildren) => {
  const { logout }: IAuthContextValueProps =
    useContext<IAuthContextValueProps>(AuthContext);

  const [isIdle, setIsIdle]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const onIdle = useCallback((): void => {
    setIsIdle(true);
  }, []);

  const { reset } = useIdleTimer({
    timeout: TIME_OF_IDLE,
    onIdle
  });

  const handleLogoutButtonClick = (): void => logout?.();

  const stayActive = useCallback(() => {
    reset();
    setIsIdle(false);
  }, [reset]);

  return (
    <>
      {isIdle && (
        <Portal>
          <s.ExtraContainer>
            <s.ModalContainer>
              <s.Header>Are you still here?</s.Header>
              <s.SecondaryText>
                To keep your details secure, you will automatically be signed
                out in:
              </s.SecondaryText>
              <TimerCountDown onComplete={handleLogoutButtonClick} />
              <s.ButtonsContainer>
                <s.CustomButton
                  buttonType={BUTTON_TYPE.Primary}
                  onClick={stayActive}
                >
                  Continue
                </s.CustomButton>
                <s.CustomButton
                  buttonType={BUTTON_TYPE.Link}
                  onClick={handleLogoutButtonClick}
                >
                  Log out now
                </s.CustomButton>
              </s.ButtonsContainer>
            </s.ModalContainer>
          </s.ExtraContainer>
        </Portal>
      )}
      {children}
    </>
  );
};
