import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Header as CommonHeader } from "components/Header";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { ContentLayout } from "components/ContentLayout";
import { ButtonBack } from "components/ButtonBack";
import { PageHeader } from "components/PageHeader";
import { ROUTES, SECTION_DESCRIPTION, SECTION_TITLE } from "src/constants";
import { Section } from "src/graphQLTypes";
import { KonfirPageContent } from "containers/KonfirPage/components";
import { useSelector } from "react-redux";
import { takeCaseId, takeKonfirSection } from "modules/case/selectors";

export const KonfirPage: FC = () => {
  const navigateTo = useNavigate();

  const pageData = useSelector(takeKonfirSection);

  const caseId = useSelector(takeCaseId);

  const onGoBack = useCallback(() => {
    navigateTo({
      pathname: ROUTES.ROOT,
      search: window.location.search
    });
  }, [navigateTo]);

  return (
    <>
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <ButtonBack handlerClick={onGoBack}>Back</ButtonBack>
        <PageHeader
          title={SECTION_TITLE[Section.KONFIR]}
          description={SECTION_DESCRIPTION[Section.KONFIR]}
        />
        <KonfirPageContent
          sectionData={pageData?.section}
          caseId={caseId}
          onDifficultiesConfirmed={onGoBack}
        />
      </ContentLayout>
    </>
  );
};
