import styled, { css } from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const FieldContainer = styled.div`
  max-width: 368px;

  &:not(:last-child) {
    margin-bottom: 23px;
  }
`;

export const FieldTitle = styled.div`
  ${getTypographyStyles({ color: "stormGray", variant: "16RegularL24" })};
  margin-bottom: 17px;
`;

export const FilesList = styled.div<{ $isMultiUploadList: boolean }>`
  ${({ $isMultiUploadList }) =>
    $isMultiUploadList &&
    css`
      margin-top: 16px;
    `}
`;
