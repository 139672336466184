export const CASE_ID_SEARCH_PARAMS_KEY = "caseId";

export const IS_ADMIN_FLOW_SEARCH_PARAMS_KEY = "isAdminFlow";

export const CASE_DATA_LC_KEY = "case-data";

export const COOKIES_CONSENT_LC_KEY = "cookiesConsent";

export const IS_TRUST_ID_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY =
  "isTrustIdDifficultiesButtonVisible";

export const IS_KONFIR_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY =
  "isKonfirDifficultiesButtonVisible";

export const CASE_ASSIGNMENT_MANAGER_DEFAULT_VALUE = "Unassigned";

export const KONFIR_REDIRECT_JOURNEY_KEY = "journeyStatus";

export const KONFIR_REDIRECT_CASE_ID = "state";

export const KONFIR_REDIrECT_JOURNEY_SUBMITTED_KEY = "submitted";
