import { useCallback, useEffect } from "react";

export const useExitPrompt = (bool: boolean): void => {
  const fn = useCallback(
    (event: BeforeUnloadEvent): string | undefined => {
      if (bool) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
      return undefined;
    },
    [bool]
  );

  useEffect(() => {
    if (bool) {
      window.addEventListener("beforeunload", fn);
    }

    if (!bool) {
      window.removeEventListener("beforeunload", fn);
    }

    return (): void => {
      window.removeEventListener("beforeunload", fn);
    };
  }, [bool, fn]);
};
