import React, { FC, useCallback, useMemo, useState } from "react";
import { FilesError } from "@security-watchdog/ui-components";

import {
  UploadFileViewer,
  UploadFileViewerProps
} from "components/UploadFileViewer";
import { getYupErrorForField } from "src/utils/validationResolver";
import {
  getDropzoneFilesToValidate,
  getDropzoneUploadedFiles
} from "src/utils/attachments";
import { MULTI_FILES_VALIDATION_RULES } from "src/constants/form.validation";
import { getMultiUploadFilesError } from "src/utils/validateUploadedFiles";
import { fieldAnyValueFragment_Attachment } from "src/graphQLTypes";

export type UploadFileViewerValidatorProps = {
  files: Array<fieldAnyValueFragment_Attachment | File>;
  onChange: (files: File[], errors: FilesError[] | undefined) => void;
  onFileDelete: (
    fileId: string,
    fileIdx: number,
    errors: FilesError[] | undefined
  ) => void;
} & Omit<UploadFileViewerProps, "onChange" | "onFileDelete" | "uploadedFiles">;

export const UploadFileViewerValidator: FC<UploadFileViewerValidatorProps> = ({
  files,
  filesError,
  onChange,
  onFileDelete,
  ...rest
}) => {
  const [internalFilesError, setFilesError] = useState<FilesError[]>();
  const uploadedFiles = useMemo(() => getDropzoneUploadedFiles(files), [files]);

  const onChangeHandler = useCallback(
    (droppedFiles: File[]) => {
      const uploadedFilesToValidate = getDropzoneFilesToValidate(files || []);
      const error = getYupErrorForField(
        [...uploadedFilesToValidate, ...droppedFiles],
        MULTI_FILES_VALIDATION_RULES
      );
      const filesError = getMultiUploadFilesError(error || undefined);

      setFilesError(filesError);

      onChange(droppedFiles, filesError);
    },
    [files, onChange]
  );

  const onFileDeleteHandler = useCallback(
    (fileId: string, fileIdx: number) => {
      const filesToValidate = (files || []).filter((_, idx) => idx !== fileIdx);
      const uploadedFilesToValidate =
        getDropzoneFilesToValidate(filesToValidate);
      const error = getYupErrorForField(
        uploadedFilesToValidate,
        MULTI_FILES_VALIDATION_RULES
      );
      const filesError = getMultiUploadFilesError(error || undefined);

      setFilesError(filesError);

      onFileDelete(fileId, fileIdx, filesError);
    },
    [files, onFileDelete]
  );

  return (
    <UploadFileViewer
      uploadedFiles={uploadedFiles}
      onChange={onChangeHandler}
      onFileDelete={onFileDeleteHandler}
      filesError={filesError || internalFilesError}
      {...rest}
    />
  );
};
