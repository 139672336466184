import { MutableRefObject, useEffect, useRef } from "react";

export const useInterval = (callback: Function, delay: number): void => {
  const savedCallback: MutableRefObject<Function | undefined> = useRef();

  useEffect((): void => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick(): void {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
};
