import { IPartialTroubleShootHistoryRecord } from "src/types";
import { fieldAnyValueFragment_Attachment, FieldType } from "src/graphQLTypes";

export enum EntityNameEnum {
  ANSWER = "answer",
  FILE = "file"
}

export interface IFileToDelete {
  questionId: string;
  answerId: string;
  fieldId: string;
  fieldType: FieldType;
  fieldValue: fieldAnyValueFragment_Attachment[];
  history: IPartialTroubleShootHistoryRecord[];
}

export interface IAnswerToDelete {
  questionId: string;
  answerId: string;
  isAnswered?: boolean;
  history: IPartialTroubleShootHistoryRecord[];
}

export type EntityToDelete = {
  entityName: EntityNameEnum;
  isConsentForm?: boolean;
  entity: IFileToDelete | IAnswerToDelete;
};

export const isFileToDeleteEntity = (
  entity: EntityToDelete["entity"]
): entity is IFileToDelete =>
  !!entity && (entity as IFileToDelete)?.fieldId !== undefined;
