import React from "react";
import { BUTTON_TYPE } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";

export const Policy: React.FC<IProps> = ({
  onCaseSubmit,
  onPolicyCheckboxValueChange,
  isAdditionalDocumentsLoading,
  isAdditionalDocumentsSectionComplete,
  isAgreedToPrivacy
}: IProps) => (
  <>
    <s.PolicyContainer>
      <s.StyledCheckbox
        value={isAgreedToPrivacy}
        onChange={onPolicyCheckboxValueChange}
        label="I confirm that I have read consent and agree to Security Watchdog’s Privacy Policy (including the processing and disclosing of my personal data)"
      />
    </s.PolicyContainer>
    <s.CustomButton
      isDisabled={!(isAdditionalDocumentsSectionComplete && isAgreedToPrivacy)}
      onClick={onCaseSubmit}
      buttonType={BUTTON_TYPE.Primary}
      isLoading={isAdditionalDocumentsLoading}
    >
      Submit
    </s.CustomButton>
  </>
);
