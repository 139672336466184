import React, { FC, useCallback, useMemo } from "react";
import { FormControl, Icon, TextArea } from "@security-watchdog/ui-components";
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormTrigger
} from "react-hook-form";

import { attachmentFragment } from "src/graphQLTypes";
import { downloadFile } from "src/utils";
import {
  IFormFields,
  FORM_FIELDS
} from "containers/SuccessSubmit/hooks/useProductActionAnswerForm";
import * as s from "./styles";
import { UploadFileViewer } from "components/UploadFileViewer/UploadFileViewer";
import { getFormFilesError } from "src/utils/validateUploadedFiles";
import { getDropzoneUploadedFiles } from "src/utils/attachments";

type ProductActionAnswerFormProps = {
  messageFromScreener: string;
  attachmentsFromScreener: attachmentFragment[];
  candidateCanAttachFiles: boolean;
  formValues: IFormFields;
  formErrors: FieldErrors<IFormFields>;
  formTrigger: UseFormTrigger<IFormFields>;
  formControl: Control<IFormFields>;
};

export const ProductActionAnswerForm: FC<ProductActionAnswerFormProps> = ({
  messageFromScreener,
  attachmentsFromScreener,
  candidateCanAttachFiles,
  formTrigger,
  formErrors,
  formValues,
  formControl
}) => {
  const filesError = useMemo(
    () => getFormFilesError(formErrors, FORM_FIELDS.ATTACHMENTS),
    [formErrors]
  );

  const uploadedFiles = useMemo(
    () => getDropzoneUploadedFiles(formValues.attachments || []),
    [formValues.attachments]
  );

  const attachmentsControls = useFieldArray({
    control: formControl,
    name: FORM_FIELDS.ATTACHMENTS as never
  });

  const handleDownloadFile = useCallback(
    (attachment: attachmentFragment): void => {
      void downloadFile(attachment);
    },
    []
  );

  const handleAddFiles = useCallback(
    (files: File[]): void => {
      attachmentsControls.append(files);
      void formTrigger(FORM_FIELDS.ATTACHMENTS);
    },
    [attachmentsControls, formTrigger]
  );

  const handleDeleteFile = useCallback(
    (_, itemIndex: number) => {
      attachmentsControls.remove(itemIndex);
      void formTrigger(FORM_FIELDS.ATTACHMENTS);
    },
    [attachmentsControls, formTrigger]
  );

  return (
    <>
      <s.ScreenerInfo>
        <span className="body-highlight">Note from the screener</span>
        <s.NoteContainer>
          <span className="body-default">{messageFromScreener}</span>
        </s.NoteContainer>
        {attachmentsFromScreener.map((file) => (
          <s.FileNameContainer
            key={file.id}
            onClick={() => handleDownloadFile(file)}
          >
            <Icon name="document" size={24} className="color-text-active" />
            <s.FileName role="button" tabIndex={0}>
              <span className="color-text-active">{file.fileName}</span>
            </s.FileName>
          </s.FileNameContainer>
        ))}
      </s.ScreenerInfo>
      <s.Container>
        <FormControl
          control={formControl}
          name={FORM_FIELDS.DESCRIPTION}
          showError
        >
          <FormControl.Label>Message</FormControl.Label>
          <TextArea />
        </FormControl>
      </s.Container>
      {candidateCanAttachFiles && (
        <s.Container>
          <FormControl.Label>Attachments</FormControl.Label>
          <UploadFileViewer
            uploadedFiles={uploadedFiles}
            multiple
            filesError={filesError ?? undefined}
            onChange={handleAddFiles}
            onFileDelete={handleDeleteFile}
          />
        </s.Container>
      )}
    </>
  );
};
