import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }): string => theme.colors.white};
  padding: 24px 24px;
  border-radius: 4px;
  width: 368px;
  box-sizing: border-box;
`;
export const Header = styled.div`
  ${getTypographyStyles({ variant: "20MediumL30", color: "tangaroa" })};
  margin-bottom: 15px;
`;
export const BodyModal = styled.div`
  text-align: center;
  ${getTypographyStyles({ variant: "16RegularL24" })};
  margin-bottom: 25px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const SubmitButton = styled(Button)<IButtonProps>`
  width: 184px;
  justify-content: center;
`;
export const CancelButton = styled(Button)<IButtonProps>`
  width: 120px;
  justify-content: center;
`;
