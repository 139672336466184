import React, { memo, PropsWithChildren, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const PortalComponent: React.FC<PropsWithChildren<{}>> = ({
  children
}: PropsWithChildren<{}>) => {
  const divTag: React.MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(
    document.createElement("div")
  );

  useEffect(() => {
    const currentNode: HTMLDivElement = divTag.current;
    currentNode.setAttribute("id", "modal-root");
    document.body.appendChild(currentNode);

    return (): void => {
      document.body.removeChild(currentNode);
    };
  }, []);

  return ReactDOM.createPortal(children, divTag.current);
};

export const Portal = memo(PortalComponent);
