import { Failure, Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  caseSubmitVariables,
  caseUpdate_caseUpdate_case,
  Countries_countries,
  getCase_screeningCase,
  getCaseVariables,
  saveDraft_saveDraft_case,
  CandidateCaseStatus,
  saveUploadHistory_saveDraft_case
} from "src/graphQLTypes";
import { ICaseUpdateInput, IErrorType } from "src/types";
import {
  IAutoSaveVariables,
  IClearSectionDraftValuesVariables,
  IDraftStatuses,
  IDraftStatusState,
  ISaveUploadHistoryVariables,
  IAdditionalDocumentsState
} from "modules/case/types";
import { CardStatuses } from "components/StatusCard/types";
import * as actions from "./actions";

export interface IReducer {
  isCaseLoading: boolean;
  isCaseLoaded: boolean;
  case: getCase_screeningCase | null;
  caseError: IErrorType;
  isCaseUpdating: boolean;
  isCaseSubmitting: boolean;
  countries: Countries_countries[];
  areCountriesLoading: boolean;
  updatedFieldIds: string[];
  saveDraftStatusState: IDraftStatusState;
  isLogoVisible: boolean;
  additionalDocumentsState: IAdditionalDocumentsState;
  isAgreedToPrivacyPolicy: boolean;
  isAdditionalDocumentsLoading: boolean;
  isAdditionalDocumetsSubmitted: boolean;
  showAdditionalDocuments: boolean;
}

export const initialState: IReducer = {
  isCaseLoading: false,
  isCaseLoaded: false,
  isCaseUpdating: false,
  isCaseSubmitting: false,
  saveDraftStatusState: {
    status: IDraftStatuses.NO_CHANGES,
    error: null,
    inProgress: false,
    clearDataInProgress: false
  },
  case: null,
  caseError: null,
  countries: [],
  areCountriesLoading: false,
  updatedFieldIds: [],
  isLogoVisible: true,
  additionalDocumentsState: {
    status: CardStatuses.INCOMPLETE,
    data: {
      files: [],
      noteForScreener: ""
    }
  },
  isAgreedToPrivacyPolicy: false,
  isAdditionalDocumentsLoading: false,
  isAdditionalDocumetsSubmitted: false,
  showAdditionalDocuments: false
};

export const reducers = reducerWithInitialState(initialState)
  .case(
    actions.getCase.started,
    (state: IReducer): IReducer => ({
      ...state,
      isCaseLoading: true,
      isCaseLoaded: initialState.isCaseLoaded,
      case: state.case || initialState.case,
      showAdditionalDocuments: initialState.showAdditionalDocuments
    })
  )
  .case(
    actions.getCase.done,
    (
      state: IReducer,
      payload: Success<getCaseVariables, getCase_screeningCase>
    ): IReducer => ({
      ...state,
      isCaseLoading: false,
      isCaseLoaded: true,
      case: payload.result,
      showAdditionalDocuments:
        payload.result.status === CandidateCaseStatus.SUBMITTED
    })
  )
  .case(
    actions.getCase.failed,
    (
      state: IReducer,
      payload: Failure<getCaseVariables, IErrorType>
    ): IReducer => ({
      ...state,
      isCaseLoading: false,
      caseError: payload.error
    })
  )
  .case(
    actions.caseUpdate.started,
    (state: IReducer, action: ICaseUpdateInput): IReducer => ({
      ...state,
      isCaseUpdating: true,
      updatedFieldIds: action.updatedFieldId
        ? [...state.updatedFieldIds, action.updatedFieldId]
        : state.updatedFieldIds
    })
  )
  .case(
    actions.caseUpdate.done,
    (
      state: IReducer,
      payload: Success<ICaseUpdateInput, caseUpdate_caseUpdate_case>
    ): IReducer => ({
      ...state,
      isCaseUpdating: false,
      case: payload.result,
      updatedFieldIds: payload.params.updatedFieldId
        ? state.updatedFieldIds.filter(
            (i: string) => i !== payload.params.updatedFieldId
          )
        : state.updatedFieldIds
    })
  )
  .case(
    actions.caseUpdate.failed,
    (
      state: IReducer,
      payload: Failure<ICaseUpdateInput, IErrorType>
    ): IReducer => ({
      ...state,
      isCaseUpdating: false,
      updatedFieldIds: payload.params.updatedFieldId
        ? state.updatedFieldIds.filter(
            (i: string) => i !== payload.params.updatedFieldId
          )
        : state.updatedFieldIds
    })
  )
  .case(
    actions.caseSubmitRequest.started,
    (state: IReducer): IReducer => ({
      ...state,
      isCaseSubmitting: true
    })
  )
  .case(
    actions.caseSubmitRequest.done,
    (
      state: IReducer,
      payload: Success<caseSubmitVariables, caseUpdate_caseUpdate_case>
    ): IReducer => ({
      ...state,
      isCaseSubmitting: false,
      case: payload.result
    })
  )
  .case(
    actions.caseSubmitRequest.failed,
    (state: IReducer): IReducer => ({
      ...state,
      isCaseSubmitting: false
    })
  )
  .case(
    actions.getCountries.started,
    (state: IReducer): IReducer => ({
      ...state,
      areCountriesLoading: true
    })
  )
  .case(
    actions.getCountries.done,
    (
      state: IReducer,
      payload: Success<void, Countries_countries[]>
    ): IReducer => ({
      ...state,
      areCountriesLoading: false,
      countries: payload.result
    })
  )
  .case(
    actions.getCountries.failed,
    (state: IReducer): IReducer => ({
      ...state,
      areCountriesLoading: false
    })
  )
  .case(
    actions.doAutoSave.started,
    (state: IReducer): IReducer => ({
      ...state,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        status: IDraftStatuses.SAVING,
        inProgress: true
      }
    })
  )
  .case(
    actions.doAutoSave.done,
    (
      state: IReducer,
      { result }: Success<IAutoSaveVariables, saveDraft_saveDraft_case>
    ): IReducer => ({
      ...state,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        status: IDraftStatuses.SAVED,
        inProgress: false
      },
      case: result
    })
  )
  .case(
    actions.doAutoSave.failed,
    (
      state: IReducer,
      { error }: Failure<IAutoSaveVariables, IErrorType>
    ): IReducer => ({
      ...state,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        status: IDraftStatuses.NO_CHANGES,
        inProgress: false,
        error
      }
    })
  )
  .case(
    actions.clearSectionDraftValues.started,
    (state: IReducer): IReducer => ({
      ...state,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        clearDataInProgress: true
      }
    })
  )
  .case(
    actions.clearSectionDraftValues.done,
    (
      state: IReducer,
      {
        result
      }: Success<IClearSectionDraftValuesVariables, saveDraft_saveDraft_case>
    ): IReducer => ({
      ...state,
      case: result,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        clearDataInProgress: false,
        status: IDraftStatuses.NO_CHANGES
      }
    })
  )
  .case(
    actions.clearSectionDraftValues.failed,
    (state: IReducer): IReducer => ({
      ...state,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        clearDataInProgress: false,
        status: IDraftStatuses.NO_CHANGES
      }
    })
  )
  .case(
    actions.updateSaveDraftStatus,
    (state: IReducer, payload: IDraftStatuses): IReducer => ({
      ...state,
      saveDraftStatusState: {
        ...state.saveDraftStatusState,
        status: payload
      }
    })
  )
  .case(
    actions.changeLogoVisibility,
    (state: IReducer, payload: boolean): IReducer => ({
      ...state,
      isLogoVisible: payload
    })
  )
  .case(
    actions.setAddittionalDocumentsToStore,
    (state: IReducer, payload: IAdditionalDocumentsState): IReducer => ({
      ...state,
      additionalDocumentsState: {
        data: payload.data,
        status: payload.data.files.length
          ? CardStatuses.COMPLETE
          : CardStatuses.INCOMPLETE
      }
    })
  )
  .case(
    actions.setIsAdditionalDocumetsSubmitted,
    (state: IReducer, payload: boolean): IReducer => ({
      ...state,
      isAdditionalDocumetsSubmitted: payload
    })
  )
  .case(
    actions.setAgreedToPrivacyPolicy,
    (state: IReducer, payload: boolean): IReducer => ({
      ...state,
      isAgreedToPrivacyPolicy: payload
    })
  )
  .case(
    actions.additionalDocumentsUpload.started,
    (state: IReducer): IReducer => ({
      ...state,
      isAdditionalDocumentsLoading: true
    })
  )
  .case(
    actions.additionalDocumentsUpload.done,
    (state: IReducer): IReducer => ({
      ...state,
      isAdditionalDocumentsLoading: false,
      isAdditionalDocumetsSubmitted: true,
      isAgreedToPrivacyPolicy: false,
      additionalDocumentsState: {
        data: {
          files: [],
          noteForScreener: ""
        },
        status: CardStatuses.INCOMPLETE
      }
    })
  )
  .case(
    actions.additionalDocumentsUpload.failed,
    (state: IReducer): IReducer => ({
      ...state,
      isAdditionalDocumentsLoading: false
    })
  )
  .case(
    actions.pullDraftData.done,
    (
      state: IReducer,
      { result }: Success<getCaseVariables, getCase_screeningCase>
    ): IReducer => ({
      ...state,
      case: result,
      showAdditionalDocuments: result.status === CandidateCaseStatus.SUBMITTED
    })
  )
  .case(
    actions.saveUploadFilesHistory.done,
    (
      state: IReducer,
      {
        result
      }: Success<ISaveUploadHistoryVariables, saveUploadHistory_saveDraft_case>
    ): IReducer => ({
      ...state,
      case: result
    })
  );
