import { PHONE_NUMBER_VALIDATION_PATTERN } from "src/constants/form.validation";

export const getValidatedPhoneNumber = (value: string): string =>
  (value?.split("") || []).reduce((acc, character, index) => {
    if (
      (character === "+" && index === 0) ||
      character === " " ||
      PHONE_NUMBER_VALIDATION_PATTERN.test(character)
    ) {
      return acc + character;
    }
    return acc;
  }, "");
