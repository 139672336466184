import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  flex-direction: column;
  background: ${({ theme }): string => theme.colors.whiteLilac};
  border-radius: 4px;
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const ContentPrimaryText = styled.span`
  ${getTypographyStyles({ variant: "16MediumL24" })}
`;

export const ExpandedContainer = styled.div`
  &:not(:empty) {
    display: flex;
    flex-grow: 1;
    margin-top: 11px;
    flex-direction: column;
  }
`;

export const ExpandedText = styled.span`
  ${getTypographyStyles({ variant: "16RegularL20" })}
`;
