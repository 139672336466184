import { azureAppConfigVar } from "src/cache";
import {
  initSurvicate,
  getSurvicateInstance
} from "@survicate/survicate-web-surveys-wrapper/widget_wrapper";
import { useMount } from "src/hooks/useMount";

export const useSurvicate = () => {
  useMount(() => {
    initSurvicate({
      disableTargeting: true,
      workspaceKey: azureAppConfigVar().SURVICATE_WORKSPACE_KEY
    })
      .then(() => {
        const survicate = getSurvicateInstance();
        survicate?.showSurvey(azureAppConfigVar().SURVICATE_SURVEY_ID);
      })
      .catch((error) => console.log(`[Survicate error]: ${error}`));
  });
};
