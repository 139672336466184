import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const NameSectionBlock = styled.div`
  font-size: 16px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: calc(100% - 44px); /* 24px - size of Icon + 20px padding left*/
`;

export const TitleCard = styled.span`
  ${getTypographyStyles({ color: "mineShaft", variant: "16MediumL24" })};

  @media (max-width: ${DEVICE.TABLET}) {
    order: 1;
  }
`;

export const TitleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoSectionBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ContainerListSectionItems = styled.div`
  margin: 25px 0 16px;
  font-size: 20px;
`;

export const Description = styled.span`
  color: ${({ theme }): string => theme.colors.stormGray};

  @media (max-width: ${DEVICE.TABLET}) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ArrowIconForMobile = styled.div`
  display: none;

  @media (max-width: ${DEVICE.TABLET}) {
    display: block;
  }
`;
