import React, { PropsWithChildren } from "react";
import * as s from "./styles";
import { IProps } from "./types";

const StatusCardComponent: React.FC<PropsWithChildren<IProps>> = ({
  status,
  title
}: PropsWithChildren<IProps>) => (
  <s.Container>
    <s.StatusCircle status={status} />
    <s.Title>{title}</s.Title>
  </s.Container>
);

export const StatusCard =
  React.memo<PropsWithChildren<IProps>>(StatusCardComponent);
