import {
  FILE_TRANSPARENCY_ERROR_MESSAGE,
  MAX_SIGNATURE_FILE_SIZE_IN_BYTES,
  PNG_FILE_ERROR_MESSAGE,
  PNG_FILE_MIME_TYPE,
  SIGNATURE_FILE_SIZE_ERROR_MESSAGE
} from "../constants";
import {
  FILE_ACCEPT_TYPES,
  FILE_MAX_SIZE_IN_BYTES,
  UPLOAD_DOCUMENTS_MAX_COUNT
} from "src/constants/form.validation";
import { FileForValidation } from "src/types/index";
import { FilesError } from "@security-watchdog/ui-components";
import { FieldErrors, get } from "react-hook-form";
import { nonNullable } from "src/utils/typeGuards";
import * as yup from "yup";

type FileValidationState = {
  isValid: boolean;
  message?: string;
};

export const validateFilesType = (files: FileForValidation[]): boolean => {
  const acceptFileTypes = FILE_ACCEPT_TYPES.split(", ");

  return files.every((file) => {
    return acceptFileTypes.some((type) =>
      file?.name?.toLowerCase().includes(type)
    );
  });
};

export const validateFilesSize = (files: FileForValidation[]): boolean =>
  files.every((file) => (file?.size || 0) <= FILE_MAX_SIZE_IN_BYTES);

export const validateFilesMaxCount = (files: FileForValidation[]): boolean =>
  files?.length <= UPLOAD_DOCUMENTS_MAX_COUNT;

const validateUploadedSignatureFilesSize = (files: File[]): boolean =>
  files.reduce((acc: number, file: File) => acc + file.size, 0) <=
  MAX_SIGNATURE_FILE_SIZE_IN_BYTES;

const validateFileImageHasTransparency = (file: File): Promise<boolean> => {
  const canvas = document.createElement("canvas");
  const image = new Image();

  return new Promise((resolve) => {
    image.onload = () => {
      const canvasContext = canvas.getContext("2d");
      let hasTransparency = false;

      if (canvasContext) {
        canvas.width = image.width;
        canvas.height = image.height;

        canvasContext.drawImage(image, 0, 0);

        const { data: imgData } = canvasContext.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        for (let i = 0; i < imgData.length; i += 4) {
          if (imgData[i + 3] < 255) {
            hasTransparency = true;
            break;
          }
        }

        resolve(hasTransparency);
      }
    };
    image.src = URL.createObjectURL(file);
  });
};

const validateFileImageIsPngFormat = (file: File): boolean => {
  return file.type === PNG_FILE_MIME_TYPE || file.name.includes(".png");
};

export const validateUploadedSignatureFile = async (
  file: File
): Promise<FileValidationState> => {
  const isFileSizeValid = validateUploadedSignatureFilesSize([file]);
  const isPngFile = validateFileImageIsPngFormat(file);
  const isFileHasTransparency = await validateFileImageHasTransparency(file);

  if (!isFileSizeValid) {
    return {
      isValid: false,
      message: SIGNATURE_FILE_SIZE_ERROR_MESSAGE
    };
  }

  if (!isPngFile) {
    return {
      isValid: false,
      message: PNG_FILE_ERROR_MESSAGE
    };
  }

  if (!isFileHasTransparency) {
    return {
      isValid: false,
      message: FILE_TRANSPARENCY_ERROR_MESSAGE
    };
  }

  return { isValid: true };
};

export const getFormFilesError = (
  errors: FieldErrors | undefined,
  fieldName: string
): FilesError[] | undefined => {
  const fieldErrors = get(errors, fieldName);

  if (Array.isArray(fieldErrors)) {
    return fieldErrors
      .map((i, idx) =>
        i
          ? {
              message: i.message,
              invalidFileIndexes: [idx]
            }
          : null
      )
      .filter(nonNullable);
  }

  return [{ message: fieldErrors?.message ?? "", invalidFileIndexes: [] }];
};

const getMultiUploadFileErrorPath = (path: string | undefined): number[] => {
  try {
    return path ? (JSON.parse(path) as number[]) : [];
  } catch (e) {
    return [];
  }
};

export const getMultiUploadFilesError = (
  fieldError: yup.ValidationError | undefined
): FilesError[] | undefined => {
  return (
    fieldError?.inner
      .map((error) => ({
        message: error.message,
        invalidFileIndexes: getMultiUploadFileErrorPath(error.path)
      }))
      .filter(nonNullable) || undefined
  );
};
