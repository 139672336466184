import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  theme,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.stormGray};
  padding: 18px 20px 18px 20px;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const InfoTextContainer = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24", color: "white" })};
  display: flex;
  align-items: center;

  & > span {
    margin-left: 16px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
`;

export const ActionButton = styled(Button)<IButtonProps>`
  color: ${theme.colors.white};
  margin-left: 28px;
`;

export const OnlineContainer = styled.div`
  ${getTypographyStyles({ variant: "11RegularUppercaseL16", color: "white" })};
  display: flex;
  align-items: center;
`;

export const OnlineIcon = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.funGreen};
  border: 1px solid ${theme.colors.white};
  margin-right: 8px;
`;
