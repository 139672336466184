import React, { useCallback, useState } from "react";
import {
  Button,
  EyeIcon,
  EyeSlashIcon,
  BUTTON_TYPE,
  theme,
  Link
} from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";

const AcceptableDocumentsComponent: React.FC = () => {
  const [showGuidance, setShowGuidance] = useState(false);

  const setShowGuidanceVisibility = useCallback(
    () => setShowGuidance(!showGuidance),
    [showGuidance]
  );

  return (
    <>
      <s.TextWrapper>
        <s.Title>Acceptable Proof of Address</s.Title>
        <s.SecondaryText>
          This document needs to include your full name, full address and date
          issued.
        </s.SecondaryText>
      </s.TextWrapper>
      <s.Divider />
      <s.TextBlock>
        <s.MainText>Electronic copies are not acceptable</s.MainText>
        unless you are subject to a Basic Criminality check,{" "}
        <s.MainText>and</s.MainText>your role is based in Scotland
      </s.TextBlock>
      <s.List>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Mortgage Statement</s.MainText>– (UK) &#42;&#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Bank / Building Society Statement</s.MainText>(UK,
            Channel Islands) &#42; (Online Statements are not acceptable)
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>
              Bank/Building Society Account Opening Confirmation Letter
            </s.MainText>
            (UK) &#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Credit Card Statement</s.MainText>
            (UK) &#42; (Online Statements are not acceptable)
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Financial Statement</s.MainText>
            e.g. pension, endowment, ISA (UK) &#42;&#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>P45 / P60 Statement</s.MainText>
            (UK / Channel Islands) &#42;&#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Council Tax Statement</s.MainText>
            (UK / Channel Islands) &#42;&#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Utility Bill</s.MainText>
            (UK) - Not Mobile Phone &#42; (Online Statements are not acceptable)
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Benefit Statement</s.MainText>
            (UK) e.g. Child Allowance, Pension &#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>
              Document from Central / Local Government / Government Agency /
              Local Authority
            </s.MainText>
            (UK / Channel Islands) e.g. DWP, Employment Service, HMRC, Job
            Centre, Social Security&#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>EEA National ID Card</s.MainText>– must be valid
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Cards carrying the PASS accreditation logo</s.MainText>
            (UK / Isle of Man/Channel Islands) – must be valid
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Irish Passport Card</s.MainText>– must be valid (Cannot
            be used with an Irish Passport)
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>
              Letter from Head Teacher or College Principal
            </s.MainText>
            (16 – 19 year olds in full time education in the UK. Only used in
            exceptional circumstances if other documents cannot be provided)
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Non-UK Bank / Building Society Statement</s.MainText>
            (Valid only for roles where applicant is living and working outside
            of UK – Branch must be located in the country in which the applicant
            lives and works)&#42;
          </s.TextContainer>
        </s.ListItem>
        <s.ListItem>
          <s.Dot />
          <s.TextContainer>
            <s.MainText>Letter of Sponsorship from future employer</s.MainText>
            (Non-UK nationals only. Valid only for applicants residing outside
            UK at time of application)
          </s.TextContainer>
        </s.ListItem>
      </s.List>
      <s.StyledReference>&#42;issued in the last 3 months</s.StyledReference>
      <s.StyledReference>
        &#42;&#42;issued in the last 12 months
      </s.StyledReference>
      <s.Divider />
      <s.TextWrapper>
        <s.Title>Acceptable Proof of Identity</s.Title>
        <s.MainText>UK Nationals</s.MainText>
        <s.List>
          <s.ListItem>
            <s.Dot />
            <s.TextContainer>
              Current passport. Attach a scanned copy or clear photograph of one
              of the documents listed below
            </s.TextContainer>
          </s.ListItem>
          <s.ListItem>
            <s.Dot />
            <s.TextContainer>
              Birth or adoption certificate. Show the full documents, including
              your full name and date of birth
            </s.TextContainer>
          </s.ListItem>
        </s.List>

        {showGuidance && (
          <>
            <s.MainText>Non UK Nationals</s.MainText>
            <s.List>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A current document issued by the Home Office to a family
                  member of an EEA or Swiss citizen and which indicated that the
                  holder is permitted to say in the United Kingdom indefinitely
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  Online confirmation of right to work using a share code
                  generated through the Home Office service at
                  <Link
                    to="https://www.gov.uk/prove-right-to-work"
                    isExternal
                    target="_blank"
                  >
                    &nbsp;Prove your right to work to an employer&nbsp;
                  </Link>
                  and provided to Security Watchdog or your employer/prospective
                  employer.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A current passport endorsed to show that the holder is exempt
                  from immigration control, is allowed to stay indefinitely in
                  the UK, has the right of abode in the UK, or has no time limit
                  on their stay in the UK.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A current Immigration Status Document issued by the Home
                  Office to the holder with an endorsement indicating that the
                  named person is allowed to stay indefinitely in the UK or has
                  no time limit on their stay in the UK, together with an
                  official document giving the person’s permanent National
                  Insurance number and their name issued by a Government agency
                  or a previous employer.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A current passport endorsed to show that the holder is allowed
                  to stay in the UK and is currently allowed to do the type of
                  work in question.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A current document issued by the Home Office to a family
                  member of an EEA or Swiss citizen, and which indicates that
                  the holder is permitted to stay in the United Kingdom for a
                  time limited period and to do the type of work in question.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A current Immigration Status Document containing a photograph
                  issued by the Home Office to the holder with a valid
                  endorsement indicating that the named person may stay in the
                  UK, and is allowed to do the type of work in question,
                  together with an official document giving the person’s
                  permanent National Insurance number and their name issued by a
                  Government agency or a previous employer.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A document issued by the Home Office showing that the holder
                  has made an application for leave to enter or remain under
                  Appendix EU to the immigration rules on or before 30 June 2021
                  together with a Positive Verification Notice from the Home
                  Office Employer Checking Service.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  An Application Registration Card issued by the Home Office
                  stating that the holder is permitted to take the employment in
                  question, together with a Positive Verification Notice from
                  the Home Office Employer Checking Service.
                </s.TextContainer>
              </s.ListItem>
              <s.ListItem>
                <s.Dot />
                <s.TextContainer>
                  A Positive Verification Notice issued by the Home Office
                  Employer Checking Service to the employer or prospective
                  employer, which indicates that the named person may stay in
                  the UK and is permitted to do the work in question.
                </s.TextContainer>
              </s.ListItem>
            </s.List>
          </>
        )}

        <Button
          buttonType={BUTTON_TYPE.Link}
          onClick={setShowGuidanceVisibility}
          icon={
            showGuidance ? (
              <EyeSlashIcon color={theme.colors.royalBlue} size={16} />
            ) : (
              <EyeIcon color={theme.colors.royalBlue} size={16} />
            )
          }
        >
          {`${showGuidance ? "Hide" : "Show"} guidance for Non-UK Nationals`}
        </Button>
      </s.TextWrapper>

      <s.BoxFooter>
        Still unsure? More guidance can be found here:
        <s.TextBlock>
          <Link
            to="https://www.securitywatchdog.org.uk/candidate-zone-acceptable-documentation"
            isExternal
            target="_blank"
          >
            https://www.securitywatchdog.org.uk/candidate-zone-acceptable-documentation
          </Link>
        </s.TextBlock>
      </s.BoxFooter>
    </>
  );
};

export const AcceptableDocuments = React.memo(AcceptableDocumentsComponent);
