import React, {
  FC,
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from "@azure/msal-react";
import {
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType
} from "@azure/msal-browser";
import { AuthContextProvider, IAuthContextValueProps } from "src/context/auth";
import { logout } from "src/utils";
import { AzureErrors } from "components/AzureErrors";
import { Spinner } from "components/common/Spinner";

const Component: FC<PropsWithChildren> = ({ children }) => {
  const [isAppReady, setIsAppReady] = useState(false);

  const { inProgress, instance } = useMsal();

  const msalAuthentication = useMsalAuthentication(InteractionType.Redirect);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    instance.addEventCallback((event: EventMessage) => {
      switch (event.eventType) {
        case EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.LOGIN_SUCCESS: {
          setIsAppReady(true);
          break;
        }
      }
    });
  }, [instance]);

  const value = useMemo<IAuthContextValueProps>(
    () => ({
      isAuthenticated,
      logout: () => logout(instance),
      isLoading: inProgress !== InteractionStatus.None
    }),
    [inProgress, instance, isAuthenticated]
  );

  return (
    <>
      <AzureErrors {...msalAuthentication} />

      {isAppReady ? (
        <AuthenticatedTemplate>
          <AuthContextProvider value={value}>{children}</AuthContextProvider>
        </AuthenticatedTemplate>
      ) : (
        <Spinner />
      )}
    </>
  );
};

Component.displayName = "AzureProviderManager";

export const AzureProviderManager = memo(Component);
