import {
  IAnswer,
  IFieldAnswer,
  IPartialTroubleShootHistoryRecord,
  IQuestion
} from "../../../types";
import {
  CandidateCaseFragment_sectionsByAffectingField,
  CaseTroubleshoootingHistoryType,
  Section
} from "../../../graphQLTypes";
import { checkEntityIds } from "src/utils/checkEntityIds";

export const convertQuestionsToPartialFieldHistoryRecords = (
  questions: IQuestion[]
): IPartialTroubleShootHistoryRecord[] =>
  questions.reduce(
    (
      acc: IPartialTroubleShootHistoryRecord[],
      question: IQuestion
    ): IPartialTroubleShootHistoryRecord[] =>
      acc.concat(
        question.answers.reduce(
          (
            acc: IPartialTroubleShootHistoryRecord[],
            answer: IAnswer
          ): IPartialTroubleShootHistoryRecord[] =>
            acc.concat(
              answer.fields.map(
                (field: IFieldAnswer): IPartialTroubleShootHistoryRecord => ({
                  type: CaseTroubleshoootingHistoryType.FIELD_VALUE_UPDATED,
                  index: answer.index,
                  isDeleted: Boolean(answer.isDeleted),
                  answerId: answer.answerId,
                  fieldId: field.fieldId,
                  from: field.value,
                  to: undefined,
                  fieldType: field.type,
                  isMulti: field.isMulti
                })
              )
            ),
          []
        )
      ),
    []
  );

type CheckIfFieldShouldTriggerCaseUpdateArgs = {
  fieldId: string;
  section: Section;
  sectionsByAffectingField: CandidateCaseFragment_sectionsByAffectingField[];
};

export const checkIfFieldShouldTriggerCaseUpdate = ({
  fieldId,
  section,
  sectionsByAffectingField
}: CheckIfFieldShouldTriggerCaseUpdateArgs): boolean => {
  const sectionByAffectingField = sectionsByAffectingField.find((i) =>
    checkEntityIds(i.fieldId, fieldId)
  );

  if (sectionByAffectingField) {
    return sectionByAffectingField.conditionalProductsAffectedSections.includes(
      section
    );
  }

  return false;
};
