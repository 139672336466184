import React, { FC, PropsWithChildren } from "react";
import { Button } from "@security-watchdog/ui-components";

import { DesignSystemModal } from "components/DesignSystemModal";
import { FooterActionsContainer } from "containers/SuccessSubmit/components/ProductActionAnswerModal/styles";

type ProductActionAnswerModalProps = PropsWithChildren<{
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  isOpen: boolean;
}>;

export const ProductActionAnswerModal: FC<ProductActionAnswerModalProps> = ({
  title,
  isOpen,
  children,
  onClose,
  onSubmit
}) => (
  <DesignSystemModal showModal={isOpen}>
    <DesignSystemModal.Header title={title} onClose={onClose} />
    <DesignSystemModal.Body>{children}</DesignSystemModal.Body>
    <DesignSystemModal.Footer>
      <FooterActionsContainer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Send
        </Button>
      </FooterActionsContainer>
    </DesignSystemModal.Footer>
  </DesignSystemModal>
);
