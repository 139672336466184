import { OptionProps, TYPE_NOTIFICATION } from "@security-watchdog/sw-ui-kit";
import { IconName } from "containers/SectionList/types";
import {
  CaseTroubleshootingHistoryInput,
  caseUpdateVariables,
  DateUnit,
  FieldType,
  getCase_screeningCase_sections_answers_answers_fieldValues,
  getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction,
  getCase_screeningCase_sections_answers_question_fields_restrictions_DurationRestriction,
  getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction,
  getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction,
  getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction,
  HandleableFieldType,
  QuestionType,
  getCase_screeningCase_products_product_conditionalValidations_answersCondition,
  Section,
  fieldAnyValueFragment_Attachment,
  CandidatePortalAuthFlow
} from "./graphQLTypes";

export interface IExtendedWindow extends Window {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  HTMLInputElement: any;
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  _sva?: any;
  ga?: any;
  __lc?: any; // LiveChat
  LiveChatWidget?: any;
  __be?: any; // ChatBot
  BE_API?: any; // ChatBot
}

export interface IFormFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IFieldValueExtended
  extends getCase_screeningCase_sections_answers_answers_fieldValues {
  answerId: string;
}

export interface IUploadFileProps {
  files: File[];
  questionId: string;
  answerId: string;
  fieldId: string;
  fieldType: FieldType;
  attachments?: fieldAnyValueFragment_Attachment[];
  isAnswered: boolean;
  history: IPartialTroubleShootHistoryRecord[];
}

export interface IDownloadProps {
  downloadUrl: string;
  fileName: string;
}

export interface ICondition {
  fieldId: string;
  value: IFieldValue;
  type: FieldType;
  fieldType: HandleableFieldType;
  isMulti: boolean;
}

export interface IDependentField {
  isMulti: boolean;
  fieldId: string;
  fieldType: HandleableFieldType;
  type: FieldType;
}

export interface IValidation {
  isMulti: boolean;
  isRequired: boolean;
  dependentField: IDependentField | undefined;
  dateRestrictions?: getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction[];
  durationRestriction?: getCase_screeningCase_sections_answers_question_fields_restrictions_DurationRestriction;
  gapRestriction?: getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction;
  lengthRestriction?: getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction;
  numericRestrictions?: getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction[];
  timeLineRestriction?: ITimeLineRestriction;
}

export interface IFieldAnswer {
  fieldId: string;
  title: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  description: string;
  options?: Array<OptionProps<string>>;
  value: IFieldValue;
  isAnswered?: boolean;
  isConditional: boolean;
  isVisible: boolean;
  hasDependentFields: boolean;
  conditions: ICondition[][];
  validation: IValidation;
  isMulti: boolean;
  isRoleLocationScotland: boolean;
  answerIndex: number;
  isHighlightedWhenOcrUploaded: boolean;
}

export interface IAnswer {
  answerId: string;
  isAnswered: boolean;
  isDeleted?: boolean;
  fields: IFieldAnswer[];
  isTemplateAnswer?: boolean;
  isCandidateAnswer?: boolean;
  index: ScalarLong;
}

export interface IQuestion {
  questionId: string;
  title: string;
  description?: string;
  questionType: QuestionType;
  isMultipleAnswers: boolean;
  fields: IFieldAnswer[];
  answers: IAnswer[];
  isUploadQuestion?: boolean;
  completed?: boolean;
  isConsentFormQuestion?: boolean;
  conditionFulfilled: boolean;
  conditional: boolean;
  isVisible: boolean;
  questionConditionsMap: IFieldConditionsMap;
}

export interface IAddressValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  country?: any;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  county?: string;
  town?: string;
  postcode?: string;
}

export interface ICompanyValue extends IAddressValue {
  name?: string;
}

export enum TimelineEntityName {
  ADDRESS = "address",
  ACTIVITY = "activity"
}

export interface IMessagesNtc {
  id: string;
  type: TYPE_NOTIFICATION;
  message: string;
}

export interface IFieldValuesMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IFieldConditionsMap {
  [key: string]: ICondition[][];
}

export interface IFieldsThatHaveDependentFieldsMapMap {
  [key: string]: string;
}

export interface IFieldsValidationMap {
  [key: string]: IValidation;
}

export interface IBuildFieldKeyProps {
  questionId: string;
  answerId: string;
  fieldId: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  isMulti: boolean;
}

export interface ITimeLineRestriction {
  startDate: Date;
  endDate: Date;
  duration: number;
  gap: number;
  durationUnit: DateUnit;
  gapUnit: DateUnit;
}

export enum CaseErrorTypes {
  INVALID_LINK,
  FAILED_FETCH
}

export enum CookiesValue {
  Accept = "accept",
  Decline = "decline"
}

export interface ICaseUpdateInput {
  caseInput: caseUpdateVariables;
  updatedFieldId?: string;
}

export interface IValidateAnswersMap {
  [key: string]: string;
}

export interface ITimeLineValidationReturnValue {
  errorMessage: string;
  answerIdsMap?: IValidateAnswersMap;
  index: number | null;
}

export interface ICheckIsFieldVisibleProps {
  conditions: ICondition[][];
  questionId: string;
  answerId: string;
  fieldKey: string;
  value: IFieldValue;
  formValues: IFormFields;
  unregisteredFields?: string[];
}

interface IFormFieldError {
  type: string;
  message: string;
}

export interface IFormFieldErrorMap {
  [key: string]: IFormFieldError;
}

export interface IProofOfIdentityField {
  questionId: string;
  fieldId: string;
  isAnswered: boolean;
}

export enum ErrorValidationType {
  ERROR_TYPE = "validation"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IErrorType = any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IFieldValue = any;

export type IPartialTroubleShootHistoryRecord = Pick<
  CaseTroubleshootingHistoryInput,
  "fieldId" | "index" | "type"
> & {
  answerId: string;
  isDeleted: boolean;
  fieldType: FieldType;
  isMulti: boolean;
  from: IFieldValue;
  to: IFieldValue;
};

export interface IAnswersToValidateMap {
  [key: string]: number;
}

export interface IValidationId {
  id: string;
}

export interface IValidationCondition {
  id: string | null;
  availableAnswers: string[];
  answersCondition: getCase_screeningCase_products_product_conditionalValidations_answersCondition;
  validationMessage: string;
}

export enum CustomSection {
  DOCUMENTS = "DOCUMENTS",
  ADDITIONAL_DOCUMENTS = "ADDITIONAL_DOCUMENTS"
}

export type ISectionDataType = {
  [key in Exclude<Section, Section.CLIENT_INFORMATION>]: string;
} & {
  [key in CustomSection]: string;
};

export type ISectionDescriptionType = {
  [key in Exclude<
    Section,
    | Section.CLIENT_INFORMATION
    | Section.ADDRESS_HISTORY
    | Section.EMPLOYMENT_EDUCATION
  >]: string;
} & {
  [key in CustomSection]: string;
};

export type ISectionIconType = {
  [key in Exclude<Section, Section.CLIENT_INFORMATION>]: IconName;
} & {
  [key in CustomSection]: IconName;
};

export type ISectionRouteType = {
  [key in Exclude<
    Section,
    Section.CLIENT_INFORMATION | Section.PERSONAL_INFORMATION
  >]: string;
} & {
  [key in CustomSection]: string;
};

export interface IListProps {
  marginLeft?: number;
}

export interface IDotProps {
  isRed?: boolean;
}

export type TempKonfirFlowData = {
  authFlow?: CandidatePortalAuthFlow | null;
  caseId: string | null;
};
