import React, { FC, memo } from "react";
import * as s from "./styles";
import { BUTTON_TYPE, LogoColorFulIcon } from "@security-watchdog/sw-ui-kit";

export type IProps = {
  onRetryClick?: () => void;
};

const Component: FC<IProps> = ({ onRetryClick }) => (
  <s.Container>
    <LogoColorFulIcon />
    <s.Title>Log in to your account</s.Title>
    <s.Description>
      You have reached the maximum number of attempts to enter the SMS
      verification code. Please try to login again
    </s.Description>
    <s.Button buttonType={BUTTON_TYPE.Primary} onClick={onRetryClick}>
      Retry Login
    </s.Button>
  </s.Container>
);

Component.displayName = "MultiFactorErrorScreen";

export const MultiFactorErrorScreen = memo(Component);
