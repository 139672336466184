import React, { useEffect, useContext } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/auth";
import { ROUTES } from "src/constants";
import { createQueryParams } from "src/utils/createQueryParams";
import { useLazyQuery } from "@apollo/client";
import { getScreeningCases } from "src/graphQLTypes";
import GET_SCREENING_CASES_QUERY from "./getScreeningCases.gql";

export const CheckCaseIdWrapper = ({ children }) => {
  const navigateTo = useNavigate();

  const queryParams = queryString.parse(window.location.search);

  const { isAuthenticated } = useContext(AuthContext);

  const [getCases, { data }] = useLazyQuery<getScreeningCases>(
    GET_SCREENING_CASES_QUERY
  );

  const sortedCases = data?.screeningCases?.sort(
    (a, b) => b.whenCreated - a.whenCreated
  );
  const lastCaseId: string = sortedCases?.[0]?.id || "";

  useEffect((): void => {
    if (isAuthenticated && !queryParams.caseId && !lastCaseId) {
      void getCases();
    }

    if (
      isAuthenticated &&
      !queryParams.caseId &&
      lastCaseId &&
      !window.location.href.includes("/konfir")
    ) {
      navigateTo({
        pathname: ROUTES.ROOT,
        search: createQueryParams(encodeURIComponent(lastCaseId))
      });
    }
  }, [isAuthenticated, queryParams.caseId, lastCaseId, getCases, navigateTo]);

  return <>{children}</>;
};
