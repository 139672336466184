export type ScreenerB2CPoliciesProps = {
  AUTHORITY_DOMAIN: string;
  SIGN_IN_NAME: string;
};

export type ScreenerB2CPolicies = {
  names: {
    signIn: string;
  };
  authorities: {
    signIn: {
      authority: string;
    };
  };
  authorityDomain: string;
};

export const getScreenerAzureB2CPolicies = (() => {
  let instance: ScreenerB2CPolicies | null = null;

  const createInstance = ({
    SIGN_IN_NAME,
    AUTHORITY_DOMAIN
  }: ScreenerB2CPoliciesProps) => {
    const AZURE_AUTHORITY_DOMAIN_NAME = AUTHORITY_DOMAIN.split(".")[0];

    return {
      names: {
        signIn: SIGN_IN_NAME
      },
      authorities: {
        signIn: {
          authority: `https://${AUTHORITY_DOMAIN}/${AZURE_AUTHORITY_DOMAIN_NAME}.onmicrosoft.com/${SIGN_IN_NAME}`
        }
      },
      authorityDomain: AUTHORITY_DOMAIN
    };
  };

  return (variables?: Partial<ScreenerB2CPoliciesProps>) => {
    if (!instance) {
      instance = createInstance(variables as ScreenerB2CPoliciesProps);
    }
    return instance;
  };
})();
