import React, {
  MutableRefObject,
  PropsWithChildren,
  useRef,
  useEffect
} from "react";
import { BUTTON_TYPE, FocusTrap } from "@security-watchdog/sw-ui-kit";

import { Portal } from "components/Portal";
import * as s from "./styles";

type DesignSystemConfirmModalProps = {
  showModal: boolean;
  onModalClose: () => void;
  onConfirm: () => void;
};

const DesignSystemConfirmModalComponent: React.FC<
  PropsWithChildren<DesignSystemConfirmModalProps>
> = ({ showModal, children, onModalClose, onConfirm }) => {
  const cancelBtnRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  useEffect(() => {
    if (cancelBtnRef.current) {
      cancelBtnRef.current.focus();
    }
  });

  return showModal ? (
    <Portal>
      <s.Container>
        <FocusTrap isActive>
          <s.ModalContainer>
            <s.Header>Confirm action</s.Header>
            <s.BodyModal>{children}</s.BodyModal>
            <s.ButtonsContainer>
              <s.CancelButton
                buttonRef={cancelBtnRef}
                buttonType={BUTTON_TYPE.Secondary}
                onClick={onModalClose}
              >
                Cancel
              </s.CancelButton>
              <s.SubmitButton
                buttonType={BUTTON_TYPE.Primary}
                onClick={onConfirm}
              >
                Confirm
              </s.SubmitButton>
            </s.ButtonsContainer>
          </s.ModalContainer>
        </FocusTrap>
      </s.Container>
    </Portal>
  ) : null;
};

export const DesignSystemConfirmModal = React.memo<
  PropsWithChildren<DesignSystemConfirmModalProps>
>(DesignSystemConfirmModalComponent);
