import React, { FC, memo } from "react";
import {
  FilesError,
  Upload,
  UploadedFilesList,
  UploadedFile
} from "@security-watchdog/ui-components";

import {
  UPLOAD_CONTROL_DEFAULT_PRIMARY_TEXT,
  UPLOAD_CONTROL_DEFAULT_SECONDARY_TEXT
} from "src/constants/attachments";
import { FILE_ACCEPT_TYPES } from "src/constants/form.validation";

export type UploadFileViewerProps = {
  uploadedFiles: UploadedFile[] | null;
  uploadPrimaryText?: string;
  uploadSecondaryText?: string;
  multiple?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  showFileWithDate?: boolean;
  filesError?: FilesError[];
  onChange: (files: File[]) => void;
  onFileDelete: (fileId: string, fileIdx: number) => void;
  onFileClick?: (fileId: string) => void;
};

const UploadFileViewerComponent: FC<UploadFileViewerProps> = ({
  uploadedFiles = null,
  uploadPrimaryText = UPLOAD_CONTROL_DEFAULT_PRIMARY_TEXT,
  uploadSecondaryText = UPLOAD_CONTROL_DEFAULT_SECONDARY_TEXT,
  multiple = false,
  showFileWithDate = false,
  isLoading = false,
  disabled = false,
  filesError,
  onChange,
  onFileDelete,
  onFileClick
}) => {
  const isUploadVisible = multiple || !uploadedFiles?.length;

  return (
    <>
      {isUploadVisible && (
        <Upload
          accept={FILE_ACCEPT_TYPES}
          primaryText={uploadPrimaryText}
          secondaryText={uploadSecondaryText}
          isLoading={isLoading}
          multiple={multiple}
          disabled={disabled}
          onChange={onChange}
        />
      )}
      {uploadedFiles && Boolean(uploadedFiles?.length) && (
        <div className={multiple ? "margin-top-4" : ""}>
          <UploadedFilesList
            files={uploadedFiles}
            errors={filesError}
            withDate={showFileWithDate}
            onFileDelete={onFileDelete}
            onFileClick={onFileClick}
          />
        </div>
      )}
    </>
  );
};

export const UploadFileViewer = memo(UploadFileViewerComponent);
