import {
  REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE,
  EQUAL_TO_FIELD_VALIDATION_ERROR_MESSAGE,
  LESS_THAN_FIELD_VALIDATION_ERROR_MESSAGE,
  LESS_OR_EQUAL_TO_FIELD_VALIDATION_ERROR_MESSAGE,
  MORE_OR_EQUAL_TO_FIELD_VALIDATION_ERROR_MESSAGE,
  MORE_THAN_FIELD_VALIDATION_ERROR_MESSAGE,
  MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE,
  MIN_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE
} from "@security-watchdog/ui-form-validation";

export const getFormErrorMessage = (
  type:
    | "required"
    | "validEndDate"
    | "validTimelineStart"
    | "validTimelineEnd"
    | "minLength"
    | "maxLength"
    | "EQ"
    | "LT"
    | "LTE"
    | "GT"
    | "GTE",
  value: string | number = ""
): string => {
  switch (type) {
    case "required":
      return REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE;

    case "validEndDate":
      return `This field must be a later than start date`;

    case "validTimelineStart":
      return `This field should not be earlier than your timeline starts`;

    case "validTimelineEnd":
      return `This field should not be later than your timeline ends`;

    case "minLength":
      return MIN_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    case "maxLength":
      return MAX_LENGTH_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    case "EQ":
      return EQUAL_TO_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    case "LT":
      return LESS_THAN_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    case "LTE":
      return LESS_OR_EQUAL_TO_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    case "GT":
      return MORE_THAN_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    case "GTE":
      return MORE_OR_EQUAL_TO_FIELD_VALIDATION_ERROR_MESSAGE(Number(value));

    default:
      return "";
  }
};
