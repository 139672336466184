/* eslint-disable */
import { IExtendedWindow } from "src/types";
import { isChatBotLiveChatEnabled } from "src/utils";
import { azureAppConfigVar } from "src/cache";

export const initLiveChat = () => {
  ((w: IExtendedWindow, d, c): void => {
    if (isChatBotLiveChatEnabled()) {
      w.__lc = w.__lc || {};
      w.__lc.license = azureAppConfigVar().LIVE_CHAT_LICENSE_NUMBER;

      const init = (args) => {
        return initialInstance._h
          ? (initialInstance._h as Function)(...args)
          : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initialInstance._q.push(args);
      };

      const initialInstance = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          // eslint-disable-next-line prefer-rest-params
          init(["on", c.call(arguments)]);
        },
        once: function () {
          // eslint-disable-next-line prefer-rest-params
          init(["once", c.call(arguments)]);
        },
        off: function () {
          // eslint-disable-next-line prefer-rest-params
          init(["off", c.call(arguments)]);
        },
        get: function () {
          if (!initialInstance._h)
            throw new Error(
              "[LiveChatWidget] You can't use getters before load."
            );
          // eslint-disable-next-line prefer-rest-params
          return init(["get", c.call(arguments)]);
        },
        call: function () {
          // eslint-disable-next-line prefer-rest-params
          init(["call", c.call(arguments)]);
        },
        init: function () {
          const script = d.createElement("script");

          script.async = true;
          script.type = "text/javascript";
          script.src = "https://cdn.livechatinc.com/tracking.js";

          d.head.appendChild(script);
        }
      };

      if (!w.__lc.asyncInit) {
        initialInstance.init();
      }
      w.LiveChatWidget = w.LiveChatWidget || initialInstance;
    }
  })(window, document, [].slice);
};
