import { useMutation } from "@apollo/client";
import { KONFIR_VERIFICATION_SUBMITTED_MUTATION } from "containers/KonfirPage/api";
import { useCallback } from "react";
import {
  KonfirVerificationSubmittedMutation,
  KonfirVerificationSubmittedMutationVariables
} from "src/graphQLTypes";

export const useKonfirVerificationSubmitted = () => {
  const [mutate, { loading: isLoading, data }] = useMutation<
    KonfirVerificationSubmittedMutation,
    KonfirVerificationSubmittedMutationVariables
  >(KONFIR_VERIFICATION_SUBMITTED_MUTATION);

  const onKonfirVerificationSubmitted = useCallback(
    async (caseId: string) => await mutate({ variables: { caseId } }),
    [mutate]
  );

  return {
    onKonfirVerificationSubmitted,
    isLoading,
    data: data?.konfirVerificationSubmitted
  };
};
