import React from "react";
import * as s from "./styles";

type IProps = {
  message: string;
};

const ErrorPageComponent: React.FC<IProps> = ({ message }: IProps) => (
  <s.Container>{message || "Oops! Something went wrong."}</s.Container>
);

export const ErrorPage = React.memo(ErrorPageComponent);
