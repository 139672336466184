import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest,
  doCaseTroubleshootingRequestUpdateVariables,
  getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest,
  getActiveTroubleShootingRequestVariables,
  TroubleshootingRequestStatus
} from "src/graphQLTypes";
import { Success } from "typescript-fsa";
import * as actions from "./actions";

export interface IReducer {
  status: TroubleshootingRequestStatus | null;
  isAcceptingRequestInProgress: boolean;
  isCancelRequestInProgress: boolean;
  isTroubleShootingFinishing: boolean;
  id: string | null;
}

export const initialState: IReducer = {
  status: null,
  id: null,
  isAcceptingRequestInProgress: false,
  isCancelRequestInProgress: false,
  isTroubleShootingFinishing: false
};

export const reducers = reducerWithInitialState(initialState)
  .case(
    actions.getActiveTroubleShootingRequest.done,
    (
      state: IReducer,
      {
        result
      }: Success<
        getActiveTroubleShootingRequestVariables,
        getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest | null
      >
    ): IReducer => ({
      ...state,
      status: result?.status || null,
      id: result?.id || null
    })
  )
  .case(
    actions.doCaseTroubleshootingRequestUpdate.started,
    (
      state: IReducer,
      payload: doCaseTroubleshootingRequestUpdateVariables
    ): IReducer => ({
      ...state,
      isCancelRequestInProgress:
        payload.input.status ===
        TroubleshootingRequestStatus.CANDIDATE_REJECTED,
      isAcceptingRequestInProgress:
        payload.input.status ===
        TroubleshootingRequestStatus.CANDIDATE_ACCEPTED,
      isTroubleShootingFinishing:
        payload.input.status ===
        TroubleshootingRequestStatus.TROUBLESHOOTING_FINISHED
    })
  )
  .case(
    actions.doCaseTroubleshootingRequestUpdate.done,
    (
      state: IReducer,
      {
        result
      }: Success<
        doCaseTroubleshootingRequestUpdateVariables,
        doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest
      >
    ): IReducer => ({
      ...state,
      id: result.id,
      status: result.status,
      isAcceptingRequestInProgress: false,
      isCancelRequestInProgress: false,
      isTroubleShootingFinishing: false
    })
  )
  .case(
    actions.doCaseTroubleshootingRequestUpdate.failed,
    (state: IReducer): IReducer => ({
      ...state,
      isAcceptingRequestInProgress: false,
      isCancelRequestInProgress: false,
      isTroubleShootingFinishing: false
    })
  );
