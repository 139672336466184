import React from "react";
import { ROUTES } from "src/constants";
import * as s from "./styles";
import { LogoWhiteIcon } from "@security-watchdog/sw-ui-kit";
import { getYear } from "date-fns";

export const Footer: React.FC = () => (
  <s.ContainerFooter role="contentinfo">
    <s.LayoutFooterContent>
      <s.ContainerNavPanelFooter>
        <s.NavItem>
          <s.NavItemLink
            href="https://www.securitywatchdog.org.uk/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </s.NavItemLink>
        </s.NavItem>
        <s.NavItem>
          <s.NavItemLink
            href={`${ROUTES.COOKIES_PAGE}${window.location.search}`}
          >
            Use of Cookies
          </s.NavItemLink>
        </s.NavItem>
        <s.NavItem>
          <s.NavItemLink
            href="https://www.securitywatchdog.org.uk/copyright"
            target="_blank"
          >
            Copyright
          </s.NavItemLink>
        </s.NavItem>
        <s.NavItem>
          <s.NavItemLink
            href="https://www.securitywatchdog.org.uk/terms-of-use"
            target="_blank"
          >
            Terms of Use
          </s.NavItemLink>
        </s.NavItem>
        <s.NavItem>
          <s.NavItemLink
            href="https://teammatrix.com/modern-slavery-statement/"
            target="_blank"
          >
            Modern Slavery Act
          </s.NavItemLink>
        </s.NavItem>
      </s.ContainerNavPanelFooter>
      <s.CopyrightBlock>
        <s.Logo>
          <LogoWhiteIcon />
        </s.Logo>
        <s.CopyrightText>
          Copyright © {getYear(new Date())}{" "}
          <s.MatrixLink
            href="https://teammatrix.com/"
            target="_blank"
            rel="noreferrer"
          >
            Matrix
          </s.MatrixLink>
          . All rights reserved. Security Watchdog is a trading name of Sec
          Watchdog Ltd T/A Security Watchdog, Company Number 14616198. Part of{" "}
          <s.MatrixLink
            href="https://teammatrix.com/"
            target="_blank"
            rel="noreferrer"
          >
            Matrix
          </s.MatrixLink>{" "}
          2nd Floor Partis House, Davy Avenue, Knowlhill, Milton Keynes MK5 8HJ.
          Security Watchdog are certified to ISO9001 & ISO 27001 by a UKAS
          accredited certification body.
        </s.CopyrightText>
      </s.CopyrightBlock>
    </s.LayoutFooterContent>
  </s.ContainerFooter>
);
