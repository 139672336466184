import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE } from "@security-watchdog/ui-form-validation";
import {
  GRADE_SUBJECT_VALIDATION_RULES,
  GRADE_VALIDATION_RULES
} from "src/constants/form.validation";

enum FORM_FIELDS {
  GRADES = "grades",
  QUALIFICATION = "qualification"
}

const validationSchema = yup.object().shape({
  [FORM_FIELDS.QUALIFICATION]: yup
    .string()
    .required(REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE),
  [FORM_FIELDS.GRADES]: yup.array().of(
    yup.object().shape({
      grade: GRADE_VALIDATION_RULES.required(
        REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE
      ),
      subject: GRADE_SUBJECT_VALIDATION_RULES.required(
        REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE
      )
    })
  )
});

export const validationResolver = yupResolver(validationSchema);
