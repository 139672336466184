import React, { memo, FC } from "react";
import { useSelector } from "react-redux";
import {
  takeCaseSectionsByAffectingField,
  takeProfessionalMemberShipQuestions,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageData } from "modules/case/types";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { SectionDetails } from "../common/SectionDetails";

const ProfessionalMemberShipComponents: FC = () => {
  const data: IPageData = useSelector(takeProfessionalMemberShipQuestions);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const sectionsByAffectingField = useSelector(
    takeCaseSectionsByAffectingField
  );

  return (
    <SectionDetails
      isFieldsDisabled={isAllFormsDisabled}
      sectionStatus={data.status}
      saveDraftStatusState={saveDraftStatusState}
      section={Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION}
      sectionsByAffectingField={sectionsByAffectingField}
      title="Qualifications and Memberships"
      description="Please let us know details of your qualifications and/or if you are a member of any professional institutions relevant to your role"
      questions={data.questions}
    />
  );
};

export const ProfessionalMemberShip = memo(ProfessionalMemberShipComponents);

export default memo(ProfessionalMemberShipComponents);
