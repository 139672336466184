import { ButtonBack } from "components/ButtonBack";
import { AcceptableDocuments } from "components/common/AcceptableDocuments";
import { Modal } from "components/Modal";
import { PageHeader } from "components/PageHeader";
import * as caseActions from "modules/case/actions";
import {
  takeCaseId,
  takeIsCaseUpdating,
  takeProofOfIdentityField
} from "modules/case/selectors";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import {
  AlertCircleIcon,
  BUTTON_TYPE,
  DIRECTION,
  Dropzone
} from "@security-watchdog/sw-ui-kit";
import { ROUTES } from "src/constants";
import { caseUpdateVariables } from "src/graphQLTypes";
import { usePrevious } from "src/hooks/usePrevious";
import { IProofOfIdentityField } from "src/types";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { OCRModal } from "components/OCRModal";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import * as s from "./styles";
import { getUploadSizeString } from "../../utils";
import {
  FILE_MAX_SIZE_ERROR_MESSAGE,
  FILE_ACCEPT_TYPES
} from "src/constants/form.validation";
import { validateFilesSize } from "src/utils/validateUploadedFiles";

const ProofOfIdentityComponent: React.FC = () => {
  const navigateTo = useNavigate();

  const dispatch: Dispatch = useDispatch();

  useScrollToTop();

  const caseId: string = useSelector(takeCaseId);

  const isCaseUpdating: boolean = useSelector(takeIsCaseUpdating);

  const proofOfIdentityField: IProofOfIdentityField | undefined = useSelector(
    takeProofOfIdentityField
  );

  const prevIsCaseUpdating: boolean | undefined = usePrevious(isCaseUpdating);

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const [isShowModal, setShowModal]: [
    boolean,
    React.Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [isOCRModalActive, setIsOCRModalActive]: [
    boolean,
    React.Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const toggleWarningModal = () =>
    setIsOCRModalActive(
      (prevIsOCRModalActive: boolean) => !prevIsOCRModalActive
    );

  const toggleShowModal = useCallback(
    () => setShowModal((prevIsShowModal: boolean) => !prevIsShowModal),
    []
  );

  const [uploadErrorMessage, setUploadErrorMessage]: [
    string,
    React.Dispatch<React.SetStateAction<string>>
  ] = useState<string>("");

  const goToPersonalDetailsForm = useCallback(
    () =>
      navigateTo({
        pathname: ROUTES.PERSONAL_DETAILS,
        search: window.location.search
      }),
    [navigateTo]
  );

  useEffect(() => {
    if (
      prevIsCaseUpdating &&
      !isCaseUpdating &&
      proofOfIdentityField &&
      proofOfIdentityField.isAnswered &&
      !isOCRModalActive
    ) {
      goToPersonalDetailsForm();
    }
  }, [
    isCaseUpdating,
    prevIsCaseUpdating,
    goToPersonalDetailsForm,
    proofOfIdentityField,
    isOCRModalActive
  ]);

  const handleCloseOCRModal = () => {
    toggleWarningModal();
    if (
      prevIsCaseUpdating &&
      !isCaseUpdating &&
      proofOfIdentityField &&
      proofOfIdentityField.isAnswered
    ) {
      goToPersonalDetailsForm();
    }
  };

  const handleUploadFiles = useCallback(
    (files: File[]) => {
      if (validateFilesSize(files) && proofOfIdentityField) {
        if (uploadErrorMessage) {
          setUploadErrorMessage("");
        }
        const caseInput: caseUpdateVariables = {
          files,
          input: {
            consentGiven: false,
            caseId,
            answers: [
              {
                questionId: proofOfIdentityField.questionId,
                answers: files.map((file: File, index: number) => ({
                  fields: [
                    {
                      fieldId: proofOfIdentityField.fieldId,
                      uploadIndex: index
                    }
                  ]
                }))
              }
            ],
            parseAnswers: true
          }
        };
        toggleWarningModal();
        return dispatch(caseActions.caseUpdate.started({ caseInput }));
      }

      return setUploadErrorMessage(FILE_MAX_SIZE_ERROR_MESSAGE);
    },
    [proofOfIdentityField, uploadErrorMessage, caseId, dispatch]
  );

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  return (
    <>
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
        <PageHeader
          title="Proof of identity"
          description="Confirm your identity by attaching a copy of your current passport (or
          similar), or snapping a clear picture of the passport itself"
        />
        <s.ContentContainer>
          <s.MainContent>
            <s.ContainerAttachDocuments>
              <Dropzone
                isDisabled={isAllFormsDisabled}
                isLoading={isCaseUpdating}
                multiple={false}
                accept={FILE_ACCEPT_TYPES}
                primaryText="Add proof of ID"
                secondaryText={`You can attach a photograph or document up to ${getUploadSizeString()} in size`}
                onAddFiles={handleUploadFiles}
              />
              {uploadErrorMessage && (
                <s.ErrorText>{uploadErrorMessage}</s.ErrorText>
              )}
            </s.ContainerAttachDocuments>
            <s.ContainerButtons>
              <s.CustomButton
                buttonType={BUTTON_TYPE.Secondary}
                onClick={goToPersonalDetailsForm}
              >
                Skip for now
              </s.CustomButton>
              <s.CustomButton
                buttonType={BUTTON_TYPE.Link}
                onClick={toggleShowModal}
              >
                See list of acceptable documents
              </s.CustomButton>
            </s.ContainerButtons>
          </s.MainContent>
          <s.InfoContainer>
            <s.InfoTitleContainer>
              <s.InfoTitle>OCR information.</s.InfoTitle>
              <AlertCircleIcon size={16} direction={DIRECTION.UP} />
            </s.InfoTitleContainer>
            <s.InfoDescription>
              We use optical character recognition to complete some fields for
              you. Please don&apos;t forget to check all the pre-populated
              information.
            </s.InfoDescription>
          </s.InfoContainer>
        </s.ContentContainer>
        {isOCRModalActive && <OCRModal onClose={handleCloseOCRModal} />}
        {isShowModal && (
          <Modal
            isShowModal={isShowModal}
            onClose={toggleShowModal}
            title="List of acceptable documents"
            actionButtons={
              <s.CustomLink to={`${ROUTES.HELP}${window.location.search}`}>
                Contact Support
              </s.CustomLink>
            }
          >
            <AcceptableDocuments />
          </Modal>
        )}
      </ContentLayout>
    </>
  );
};

export const ProofOfIdentity = React.memo(ProofOfIdentityComponent);

export default ProofOfIdentity;
