import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { IListProps, IDotProps } from "src/types";

export const Title = styled.div`
  ${getTypographyStyles({ variant: "20MediumL30" })};
  margin-bottom: 16px;
`;

export const SecondaryText = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;

export const MainText = styled.span`
  ${getTypographyStyles({ variant: "16MediumL24" })};
  margin-right: 5px;
`;

export const List = styled.ul<IListProps>`
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${({ marginLeft }): number => marginLeft || 15}px;
`;

export const Dot = styled.div<IDotProps>`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-right: 9px;
  position: relative;
  top: 10px;
  background-color: ${({ isRed, theme }): string =>
    isRed ? theme.colors.mojo : theme.colors.mineShaft};
`;

export const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  margin-bottom: 5px;
`;

export const TextContainer = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24" })};
  flex-grow: 1;
  flex-basis: 0;
`;

export const BoxFooter = styled.div`
  margin-top: 16px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Divider = styled.div<IDotProps>`
  border-bottom: 1px solid ${({ theme }): string => theme.colors.snuff};
  margin: 32px 0;
`;

export const TextBlock = styled.div`
  display: block;
  margin: 5px 0 16px;
`;

export const StyledReference = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24", color: "lightGray" })};
`;
