import { reducerWithInitialState } from "typescript-fsa-reducers";
import { IMessagesNtc } from "../../types";
import * as actions from "./actions";

export interface IReducer {
  messages: IMessagesNtc[];
}

export const initialState: IReducer = {
  messages: []
};

export const reducers = reducerWithInitialState(initialState)
  .case(actions.addMessageNtc, (state: IReducer, data: IMessagesNtc) => ({
    ...state,
    messages: [...state.messages, data]
  }))
  .case(
    actions.updateMessageNtc,
    (state: IReducer, updatedNtc: IMessagesNtc[]) => ({
      ...state,
      messages: updatedNtc
    })
  );
