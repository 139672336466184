import { Portal } from "components/Portal";
import React, { memo, NamedExoticComponent } from "react";
import {
  Button,
  BUTTON_TYPE,
  CloseIcon,
  theme,
  FocusTrap
} from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";

const ModalComponent: React.FC<IProps> = ({
  children,
  title,
  onClose,
  isShowModal,
  actionButtons,
  description
}: IProps) => {
  return isShowModal ? (
    <Portal>
      <s.extraContainer>
        <FocusTrap isActive>
          <s.ContainerModal role="dialog" aria-modal aria-label={title}>
            <s.HeaderModal>
              <s.HeaderContainer>
                <s.HeaderTitle>{title}</s.HeaderTitle>
                {description && (
                  <s.HeaderDescription>{description}</s.HeaderDescription>
                )}
              </s.HeaderContainer>
              <Button
                ariaLabel="close"
                buttonType={BUTTON_TYPE.Link}
                icon={<CloseIcon color={theme.colors.tangaroa} />}
                onClick={onClose}
              />
            </s.HeaderModal>
            <s.BodyModal>{children}</s.BodyModal>
            {actionButtons && <s.FooterModal>{actionButtons}</s.FooterModal>}
          </s.ContainerModal>
        </FocusTrap>
      </s.extraContainer>
    </Portal>
  ) : null;
};

export const Modal: NamedExoticComponent<IProps> = memo<IProps>(ModalComponent);
