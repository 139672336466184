import { InMemoryCache, makeVar } from "@apollo/client";
import { AzureAppConfig, AzureConfig } from "config/azureAppConfig";
import possibleTypes from "./possibleTypes.json";
import { TempKonfirFlowData } from "src/types";

export const azureAppConfigVar = makeVar<AzureAppConfig>(
  new AzureConfig().config
);

export const sessionIsTerminatedError = makeVar<string | null>(null);

export const tempKonfirFlowData = makeVar<TempKonfirFlowData>({
  authFlow: null,
  caseId: null
});

export const cache = new InMemoryCache({ possibleTypes });
