import { TrustIdButtonState } from "containers/TrustIdPage/components/TrustIdPageContent/types";
import {
  getCase_screeningCase_sections_answers,
  TrustIDApplicationStatus
} from "src/graphQLTypes";
import {
  getTrustIdFirstDifficultiesBooleanAnswerValue,
  isTrustIdApplicationSubmitted
} from "containers/TrustIdPage/components/TrustIdPageContent/utils";

type UseGoToTrustIdBtnState = {
  buttonState: TrustIdButtonState;
};

type UseGoToTrustIdBtnStateArgs = {
  sectionAnswers: getCase_screeningCase_sections_answers[];
  trustIdStatus: TrustIDApplicationStatus | null;
};

export const useGoToTrustIdBtnState = ({
  sectionAnswers,
  trustIdStatus
}: UseGoToTrustIdBtnStateArgs): UseGoToTrustIdBtnState => {
  const difficultiesQuestionAnswerValue =
    getTrustIdFirstDifficultiesBooleanAnswerValue(sectionAnswers);

  const getButtonState = () => {
    if (
      isTrustIdApplicationSubmitted(
        trustIdStatus,
        difficultiesQuestionAnswerValue
      )
    ) {
      return {
        isVisible: true,
        isDisabled: true
      };
    }

    if (difficultiesQuestionAnswerValue) {
      return {
        isVisible: true,
        isDisabled: true
      };
    }

    return {
      isVisible: true,
      isDisabled: false
    };
  };

  return {
    buttonState: getButtonState()
  };
};
