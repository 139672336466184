import { ApolloClient, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { cache } from "./cache";
import { getSessionAuthToken } from "./msal";
import { DefaultContext } from "@apollo/client/core";
import {
  logGraphQLError,
  logNetworkError,
  onSessionTerminatedError,
  onUnauthenticatedError,
  processErrorResponses
} from "src/utils";

const GRAPHQL_PUBLIC_REQUEST_OPERATION_NAME = ["GetAuthFlow"];

const apolloClient = new ApolloClient({
  name: "sw-candidate-portal",
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  link: ApolloLink.from([
    onError(
      ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            // eslint-disable-next-line @typescript-eslint/typedef
            if (onSessionTerminatedError(error)) {
              return;
            }

            if (onUnauthenticatedError({ forward, operation, ...error })) {
              return;
            }

            processErrorResponses(error);

            logGraphQLError(error);
          }
        }
        logNetworkError(networkError);
      }
    ),
    setContext(async (_request, { headers }: DefaultContext) => {
      return {
        headers: {
          ...headers,
          authorization: GRAPHQL_PUBLIC_REQUEST_OPERATION_NAME.includes(
            _request.operationName || ""
          )
            ? undefined
            : await getSessionAuthToken()
        }
      };
    }),
    createUploadLink({
      uri: "/api/graphql",
      headers: {
        "apollo-require-preflight": true
      }
    })
  ]),
  cache,
  assumeImmutableResults: true,
  defaultOptions: {
    // Configuration to disable Apollo cache
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "none"
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "none"
    },
    mutate: {
      errorPolicy: "none"
    }
  }
});

export default apolloClient;
