export const createEntityIdFromInt = (
  entityName: string,
  entityId?: number | string | null
): string | null => {
  if (entityId && Number(entityId)) {
    return encodeURI(
      JSON.stringify({
        id: entityId || "",
        type: entityName
      })
    );
  }

  return null;
};
