import styled from "styled-components";
import { getOutlineStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";
import { ITileContainerProps } from "./types";

export const TileContainer = styled.div<ITileContainerProps>`
  padding: 17px 24px;
  height: 80px;
  margin-bottom: 8px;
  cursor: ${({ $isClickable }): string =>
    $isClickable ? "pointer" : "default"};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 4px;
  border: 2px solid transparent;

  &:hover {
    transition: border-color 0.3s;
    border-color: ${({ theme, $isClickable }): string =>
      $isClickable ? theme.colors.snuff : "transparent"};
  }

  &:focus {
    ${getOutlineStyles()};
  }

  @media (max-width: ${DEVICE.TABLET}) {
    min-height: 104px;
    height: auto;
  }
`;
