import React, {
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { v4 } from "uuid";

import * as s from "./styles";

export type TabLabelProps = {
  $isTabActive?: boolean;
  $isDisabled?: boolean;
};

export type TabProps = {
  label: string;
  value: number;
  isDisabled?: boolean;
};

type TabsProps = {
  tabs: TabProps[];
  activeTab?: number;
  onTabChange?: (tab: number) => void;
};

export const TabsComponent: React.FC<TabsProps> = ({
  tabs,
  activeTab: _activeTab,
  onTabChange
}) => {
  const componentId = useMemo<string>(() => v4(), []);

  const [activeTab, setActiveTab]: [
    number,
    React.Dispatch<SetStateAction<number>>
  ] = useState<number>(_activeTab || tabs[0].value);

  const handleTabClick = useCallback(
    (value: number, isDisabled: boolean) => (): void => {
      if (!isDisabled) {
        onTabChange?.(value);
        setActiveTab(value);
      }
    },
    [onTabChange]
  );

  const handleKeyDown = useCallback(
    (value: number, isDisabled: boolean) =>
      (e: React.KeyboardEvent): void => {
        if ([" ", "Enter"].includes(e.key) && !isDisabled) {
          onTabChange?.(value);
          setActiveTab(value);
        }
      },
    [onTabChange]
  );

  useEffect(() => {
    if (_activeTab) {
      setActiveTab(_activeTab);
    }
  }, [_activeTab]);

  const tabHeaders: ReactNode[] = useMemo<ReactNode[]>(
    () =>
      tabs.map(({ value, label, isDisabled }: TabProps): ReactNode => {
        const isTabActive: boolean = value === activeTab;

        return (
          <s.TabWrap key={value}>
            <s.TabLabel
              role="tab"
              tabIndex={0}
              aria-controls={`panel${value}${componentId}`}
              id={`tab${value}${componentId}`}
              aria-selected={isTabActive}
              $isTabActive={isTabActive}
              $isDisabled={Boolean(isDisabled)}
              onClick={handleTabClick(value, Boolean(isDisabled))}
              onKeyDown={handleKeyDown(value, Boolean(isDisabled))}
            >
              {label}
            </s.TabLabel>
          </s.TabWrap>
        );
      }),
    [activeTab, tabs, handleTabClick, handleKeyDown, componentId]
  );

  return (
    <s.Container>
      <s.TabsWrap role="tablist">{tabHeaders}</s.TabsWrap>
    </s.Container>
  );
};

export const Tabs = React.memo(TabsComponent);
