import { useEffect, useRef } from "react";

type IUsePrevious = <T>(value: T) => T | undefined;

export const usePrevious: IUsePrevious = <T>(value: T) => {
  const ref: React.MutableRefObject<T | undefined> = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
