import React, { FC } from "react";
import { Badge, Icon } from "@security-watchdog/ui-components";

import * as s from "./styles";
import { CandidateCaseProductStatus } from "src/graphQLTypes";
import {
  CANDIDATE_CASE_PRODUCT_STATUS_TEXT,
  CANDIDATE_CASE_PRODUCT_STATUS_TOOLTIP_TEXT
} from "src/constants";
import { DeviceDependedTooltip } from "components/DeviceDependedTooltip";

type Props = {
  status: CandidateCaseProductStatus;
};

export const ProductStatusBadge: FC<Props> = ({ status }) => {
  const statusTitle = CANDIDATE_CASE_PRODUCT_STATUS_TEXT[status];

  return (
    <s.Container>
      <Badge variant="ghost-active">
        <s.Icon name="placeholder" size={8} status={status} />
        {statusTitle}
      </Badge>
      <DeviceDependedTooltip
        title={statusTitle}
        renderTooltipContent={() =>
          CANDIDATE_CASE_PRODUCT_STATUS_TOOLTIP_TEXT[status]
        }
      >
        <Icon name="info" size={16} className="color-icon-default" />
      </DeviceDependedTooltip>
    </s.Container>
  );
};
