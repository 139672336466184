import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const PageTitle = styled.h1`
  ${getTypographyStyles({ color: "tangaroa", variant: "32BoldL40" })};
  margin: 0;
`;

export const PageDescription = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;
