import React, { NamedExoticComponent, useCallback } from "react";
import {
  Dropdown,
  DropDownOnChange,
  Input,
  CraftyClicksAutocomplete,
  ICraftyClicksOnChange
} from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";
import { IProps } from "./types";
import { Countries_countries } from "../../../graphQLTypes";
import { azureAppConfigVar } from "src/cache";
import {
  AddressFormField,
  AddressFormFieldValidation
} from "src/types/address";
import { CRAFTY_CLICKS_URLS } from "src/constants/craftyClicks";

export const AddressFieldComponent: React.FC<IProps> = ({
  countries,
  value,
  onChange,
  errorMessage,
  id,
  isDisabled
}: IProps) => {
  const handleChangeSelect = useCallback(
    (country: DropDownOnChange<Countries_countries>) => {
      onChange({
        ...value,
        [AddressFormField.COUNTRY]: country
      });
    },
    [onChange, value]
  );

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [e.target.name]: e.target.value
      });
    },
    [onChange, value]
  );

  const handleChangeAutocomplete = useCallback(
    (address: ICraftyClicksOnChange): void =>
      onChange({
        ...value,
        ...address
      }),
    [onChange, value]
  );

  const getErrorMessage = useCallback(
    (fieldName: AddressFormField): string => {
      if (!errorMessage) {
        return "";
      }

      try {
        const errors: AddressFormFieldValidation = JSON.parse(
          errorMessage
        ) as AddressFormFieldValidation;

        return errors[fieldName] || "";
      } catch (e) {
        return "";
      }
    },
    [errorMessage]
  );

  return (
    <>
      <s.StyledField label="Country" id={`${id}${AddressFormField.COUNTRY}`}>
        <Dropdown<Countries_countries>
          isDisabled={isDisabled}
          id={`${id}${AddressFormField.COUNTRY}`}
          isSearchable
          value={value?.country}
          options={countries}
          onChange={handleChangeSelect}
          errorMessage={getErrorMessage(AddressFormField.COUNTRY)}
          popoverPositionFixed={false}
        />
      </s.StyledField>
      <s.StyledField label="Address finder" id={id}>
        <CraftyClicksAutocomplete
          isDisabled={isDisabled}
          id={id}
          placeholderText="Type postcode..."
          apiKey={azureAppConfigVar().CRAFTY_CLICKS_AUTH_KEY}
          countryIsoCode={value?.country?.isoCode}
          onChange={handleChangeAutocomplete}
          popoverPositionFixed={false}
          craftyClicksUrls={CRAFTY_CLICKS_URLS}
        />
      </s.StyledField>
      <s.StyledField
        label="Address line 1"
        id={`${id}${AddressFormField.ADDRESS_LINE_1}`}
      >
        <Input
          isDisabled={isDisabled}
          inputId={`${id}${AddressFormField.ADDRESS_LINE_1}`}
          name={AddressFormField.ADDRESS_LINE_1}
          value={value?.addressLine1 || ""}
          onChange={handleChangeInput}
          errorMessage={getErrorMessage(AddressFormField.ADDRESS_LINE_1)}
        />
      </s.StyledField>
      <s.StyledField
        label="Address line 2"
        id={`${id}${AddressFormField.ADDRESS_LINE_2}`}
      >
        <Input
          isDisabled={isDisabled}
          inputId={`${id}${AddressFormField.ADDRESS_LINE_2}`}
          placeholder="Optional"
          name={AddressFormField.ADDRESS_LINE_2}
          value={value?.addressLine2 || ""}
          onChange={handleChangeInput}
          errorMessage={getErrorMessage(AddressFormField.ADDRESS_LINE_2)}
        />
      </s.StyledField>
      <s.StyledField
        label="Address line 3"
        id={`${id}${AddressFormField.ADDRESS_LINE_3}`}
      >
        <Input
          isDisabled={isDisabled}
          inputId={`${id}${AddressFormField.ADDRESS_LINE_3}`}
          placeholder="Optional"
          name={AddressFormField.ADDRESS_LINE_3}
          value={value?.addressLine3 || ""}
          onChange={handleChangeInput}
          errorMessage={getErrorMessage(AddressFormField.ADDRESS_LINE_3)}
        />
      </s.StyledField>
      <s.StyledField label="Town" id={`${id}${AddressFormField.TOWN}`}>
        <Input
          isDisabled={isDisabled}
          inputId={`${id}${AddressFormField.TOWN}`}
          name={AddressFormField.TOWN}
          value={value?.town || ""}
          onChange={handleChangeInput}
          errorMessage={getErrorMessage(AddressFormField.TOWN)}
        />
      </s.StyledField>
      <s.StyledField label="County" id={`${id}${AddressFormField.COUNTY}`}>
        <Input
          isDisabled={isDisabled}
          inputId={`${id}${AddressFormField.COUNTY}`}
          placeholder="Optional"
          name={AddressFormField.COUNTY}
          value={value?.county || ""}
          onChange={handleChangeInput}
          errorMessage={getErrorMessage(AddressFormField.COUNTY)}
        />
      </s.StyledField>
      <s.StyledFieldHalf
        label="Postcode"
        id={`${id}${AddressFormField.POST_CODE}`}
      >
        <Input
          isDisabled={isDisabled}
          inputId={`${id}${AddressFormField.POST_CODE}`}
          name={AddressFormField.POST_CODE}
          value={value?.postcode || ""}
          onChange={handleChangeInput}
          errorMessage={getErrorMessage(AddressFormField.POST_CODE)}
        />
      </s.StyledFieldHalf>
    </>
  );
};

export const AddressField: NamedExoticComponent<IProps> = React.memo<IProps>(
  AddressFieldComponent
);
