export const loadExternalScript = (
  src: string,
  id: string,
  cb: Function
): void => {
  const scriptEl: HTMLScriptElement = document.createElement("script");

  scriptEl.setAttribute("id", id);

  scriptEl.setAttribute("src", src);

  document.body.appendChild(scriptEl);

  scriptEl.onload = (): void => cb();
};
