import { AddNotificationArgs } from "@security-watchdog/ui-components";

export const NOTIFICATION_TIME = 5000;

type Config = Partial<AddNotificationArgs>;

export const NOTIFICATION_DEFAULT_CONFIG: Config = {
  showCloseIcon: true,
  showIcon: true,
  closeAfter: NOTIFICATION_TIME
};

export const NOTIFICATION_INFO_CONFIG: Config = {
  type: "info",
  ...NOTIFICATION_DEFAULT_CONFIG
};

export const NOTIFICATION_ERROR_CONFIG: Config = {
  type: "error",
  ...NOTIFICATION_DEFAULT_CONFIG
};

export const NOTIFICATION_SUCCESS_CONFIG: Config = {
  type: "success",
  ...NOTIFICATION_DEFAULT_CONFIG
};
