import {
  getCase_screeningCase_sections_answers,
  TrustIDApplicationStatus
} from "src/graphQLTypes";
import { isBooleanValue } from "src/utils/typeGuards";

export const isTrustIdApplicationSubmitted = (
  trustIdStatus: TrustIDApplicationStatus | null,
  difficultiesQuestionAnswerValue: boolean | null
): boolean =>
  Boolean(
    trustIdStatus &&
      trustIdStatus !== TrustIDApplicationStatus.GUEST_LINK_CREATED &&
      difficultiesQuestionAnswerValue !== null
  );

export const getTrustIdFirstDifficultiesBooleanAnswerValue = (
  sectionAnswers: getCase_screeningCase_sections_answers[]
): boolean | null => {
  return sectionAnswers.reduce<boolean | null>((acc, sectionAnswer) => {
    let value: boolean | null = acc;

    sectionAnswer.answers.forEach((answer) => {
      answer.fieldValues.forEach((fieldValue) => {
        value =
          fieldValue.value && isBooleanValue(fieldValue.value)
            ? fieldValue.value.booleanValue
            : null;
      });
    });

    return acc === null ? value : acc;
  }, null);
};
