import { FieldType, HandleableFieldType } from "../graphQLTypes";
import { IBuildFieldKeyProps } from "../types";

export const buildFieldKey = ({
  questionId,
  answerId,
  fieldId,
  fieldType,
  type,
  isMulti
}: IBuildFieldKeyProps): string =>
  encodeURI(
    `${questionId}/${answerId}/${fieldId}/${type}/${fieldType}/${isMulti}`
  );

export const parseFieldKey = (fieldKey: string): IBuildFieldKeyProps => {
  const fieldParams: string[] = decodeURI(fieldKey).split("/");
  return {
    questionId: fieldParams[0],
    answerId: fieldParams[1],
    fieldId: fieldParams[2],
    type: FieldType[fieldParams[3]],
    fieldType: HandleableFieldType[fieldParams[4]],
    isMulti: fieldParams[5] === "true"
  };
};
