import { CandidatePortalAuthFlow } from "src/graphQLTypes";
import { getB2cPolicies } from "./getB2CPolicies";

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const getAuthorityLink = (authFlow: CandidatePortalAuthFlow): string => {
  const policies = getB2cPolicies();

  switch (authFlow) {
    case CandidatePortalAuthFlow.AZURE_AD_SIGN_IN:
      return policies.authorities.signIn.authority;
    case CandidatePortalAuthFlow.AZURE_AD_SIGN_IN_OR_SIGN_UP:
      return policies.authorities.signUp_or_signIn.authority;
    case CandidatePortalAuthFlow.AZURE_AD_SIGN_UP:
      return policies.authorities.signUp.authority;
    default:
      return "";
  }
};
