import { ISection } from "containers/SectionList/types";
import { v4 } from "uuid";
import {
  getEmploymentEducationDescription,
  takeAddressTimeLineDescription,
  takeProofOfIdentityField
} from "modules/case/selectors";
import { takeIsAdminFlow } from "modules/main/selectors";
import {
  ROUTES,
  SECTION_SORT_INDEX,
  SECTION_TITLE,
  SECTION_DESCRIPTION,
  SECTION_ICON,
  SECTION_ROUTE
} from "../constants";
import { getSectionStatus } from "./getSectionStatus";
import { Section, getCase_screeningCase_sections } from "../graphQLTypes";
import { DraftValues } from "./buildInputForSaveDraftValues";
import { IRootReducer } from "../reducers";
import { IProofOfIdentityField } from "../types";

const getTimelineSectionDescription = (
  section: Section,
  state: IRootReducer
): string => {
  if (section === Section.ADDRESS_HISTORY) {
    return takeAddressTimeLineDescription(state);
  }

  return getEmploymentEducationDescription(state);
};

const getPersonalRefereesRoute = (
  isAdminFlow: boolean,
  proofOfIdentityField?: IProofOfIdentityField
) => {
  if (isAdminFlow) {
    return ROUTES.PERSONAL_DETAILS;
  }

  return proofOfIdentityField && !proofOfIdentityField.isAnswered
    ? ROUTES.PROOF_OF_IDENTITY
    : ROUTES.PERSONAL_DETAILS;
};

export const getSectionData = (
  { completed, section }: getCase_screeningCase_sections,
  draftValues: DraftValues,
  state: IRootReducer
): ISection => {
  const isAdminFlow: boolean = takeIsAdminFlow(state);

  const proofOfIdentityField: IProofOfIdentityField | undefined =
    takeProofOfIdentityField(state);

  return {
    title: SECTION_TITLE[section],
    description:
      SECTION_DESCRIPTION[section] ||
      getTimelineSectionDescription(section, state),
    icon: SECTION_ICON[section],
    id: v4(),
    path:
      SECTION_ROUTE[section] ||
      getPersonalRefereesRoute(isAdminFlow, proofOfIdentityField),
    sortNumber: SECTION_SORT_INDEX[section],
    status: getSectionStatus({
      completed,
      draftValues: draftValues[section]
    })
  };
};
