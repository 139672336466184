import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  BUTTON_TYPE,
  ExitIcon,
  QuestionCircleIcon
} from "@security-watchdog/sw-ui-kit";
import { ROUTES } from "src/constants";
import { AuthContext, IAuthContextValueProps } from "src/context/auth";
import * as s from "./styles";
import { IProps } from "./types";

export const NavItems: React.FC<IProps> = ({ className }: IProps) => {
  const navigateTo = useNavigate();

  const { logout, isAuthenticated }: IAuthContextValueProps =
    useContext<IAuthContextValueProps>(AuthContext);

  const goToHelpPage = useCallback(
    () => navigateTo({ pathname: ROUTES.HELP, search: window.location.search }),
    [navigateTo]
  );

  return (
    <s.Container className={className}>
      <s.NavButton
        icon={<QuestionCircleIcon />}
        buttonType={BUTTON_TYPE.Link}
        onClick={goToHelpPage}
      >
        <s.TextButton>Help</s.TextButton>
      </s.NavButton>
      {isAuthenticated && (
        <s.NavButton
          icon={<ExitIcon />}
          buttonType={BUTTON_TYPE.Link}
          onClick={logout}
        >
          <s.TextButton>Log out</s.TextButton>
        </s.NavButton>
      )}
    </s.Container>
  );
};
