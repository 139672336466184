export const removeScriptsFromDOM = (ids: string[]): void => {
  const scriptEls: Array<HTMLElement | null> = ids.map((id: string) =>
    document.getElementById(id)
  );

  const filteredScripts: HTMLElement[] = scriptEls.filter(
    Boolean
  ) as HTMLElement[];

  filteredScripts.forEach((el: HTMLElement) => document.body.removeChild(el));
};
