import {
  AnyAction,
  applyMiddleware,
  CombinedState,
  compose,
  createStore,
  Store
} from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer, { IRootReducer } from "./reducers";
import { rootSaga } from "./sagas";
import { IExtendedWindow } from "./types";

const configureStore = (): Store<CombinedState<IRootReducer>, AnyAction> => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  const composeEnhancers =
    (window as IExtendedWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
