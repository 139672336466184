import React, { FC } from "react";
import { Portal } from "components/Portal";
import { BUTTON_TYPE, FocusTrap } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";

export interface IOCRModalProps {
  onClose: () => void;
}

export const OCRModal: FC<IOCRModalProps> = ({ onClose }: IOCRModalProps) => (
  <Portal>
    <s.Container>
      <FocusTrap isActive>
        <s.ModalContainer>
          <s.Title>To speed things up</s.Title>
          <s.MainText>
            We use optical character recognition to complete some of the fields
            for you - we&apos;ve highlighted which ones. Please don&apos;t
            forget to check all the pre-populated information is correct before
            you click &apos;Complete&apos;
          </s.MainText>
          <s.CloseButton buttonType={BUTTON_TYPE.Secondary} onClick={onClose}>
            Close
          </s.CloseButton>
        </s.ModalContainer>
      </FocusTrap>
    </s.Container>
  </Portal>
);
