import {
  AppConfigurationClient,
  ConfigurationSetting,
  ListConfigurationSettingPage
} from "@azure/app-configuration";

async function getMappedAzureConfig<
  T extends { featureToggle: Record<keyof T["featureToggle"], boolean> }
>(
  connectionStrings: string[],
  serviceEnvironment: string,
  mapFeatureFlagKey: (key: string) => keyof T["featureToggle"] | null,
  mapConfigKey: (key: string) => Exclude<keyof T, "featureToggle"> | null
): Promise<T> {
  const configEntries = await fetchAzureConfigurationEntries(connectionStrings);

  return getAppConfigurationFromAzureEntries<T>(
    configEntries,
    serviceEnvironment,
    mapFeatureFlagKey,
    mapConfigKey
  );
}

async function fetchAzureConfigurationEntries(
  connectionStrings: string[]
): Promise<Array<ConfigurationSetting<string>>> {
  const AzureAppConfigurationClient =
    await getAzureAppConfigurationClient(connectionStrings);

  const configEntries = await getAllPages(
    AzureAppConfigurationClient.listConfigurationSettings().byPage()
  );

  return configEntries;
}

function getAppConfigurationFromAzureEntries<
  T extends { featureToggle: Record<keyof T["featureToggle"], boolean> }
>(
  configEntries: Array<ConfigurationSetting<string>>,
  serviceEnvironment: string,
  mapFeatureFlagKey: (key: string) => keyof T["featureToggle"] | null,
  mapConfigKey: (key: string) => Exclude<keyof T, "featureToggle"> | null
): T {
  const FEATURE_TOGGLE_STRING = ".appconfig.featureflag/";

  return configEntries.reduce<T>((acc, { key, label, value }) => {
    const isFeatureFlag = key.includes(FEATURE_TOGGLE_STRING);
    const isMatchingEnvironment = label?.toLowerCase() === serviceEnvironment;
    const featureFlagKey =
      isFeatureFlag &&
      mapFeatureFlagKey(key.replace(FEATURE_TOGGLE_STRING, ""));
    const configurationVariableKey = !isFeatureFlag && mapConfigKey(key);

    // Mapping of feature toggle variables
    if (featureFlagKey && isMatchingEnvironment) {
      const featureToggleObject = acc.featureToggle || {};
      const featureFlagValue =
        Boolean(value && JSON.parse(value)?.enabled) ?? false;
      acc.featureToggle = {
        ...featureToggleObject,
        [featureFlagKey]: featureFlagValue
      };
    }

    // Mapping of plain configuration variables
    else if ((!label || isMatchingEnvironment) && configurationVariableKey) {
      acc[configurationVariableKey as string] = value ?? "";
    }

    return acc;
  }, {} as T);
}

async function getAzureAppConfigurationClient(
  connectionStrings: string[]
): Promise<AppConfigurationClient> {
  for (let i = 0; i < connectionStrings.length; i++) {
    try {
      const client = new AppConfigurationClient(connectionStrings[i]);
      await client.listConfigurationSettings().next();
      return client;
    } catch (err) {
      const errorText = err instanceof Error ? err.message : "unknown error";
      // eslint-disable-next-line no-console
      console.error(
        `Failed to connect with Azure Connection String: ${connectionStrings[i]}\n${errorText}`
      );
    }
  }

  return new AppConfigurationClient(
    connectionStrings[connectionStrings.length - 1]
  );
}

async function getAllPages(
  iterator: AsyncIterableIterator<ListConfigurationSettingPage>,
  pages = [] as Array<ConfigurationSetting<string>>
): Promise<Array<ConfigurationSetting<string>>> {
  const { done, value } = await iterator.next();
  if (done) {
    return pages;
  }
  return getAllPages(iterator, [...pages, ...value.items]);
}

export default getMappedAzureConfig;
