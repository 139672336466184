import {
  Control,
  FieldErrors,
  useForm,
  UseFormReset,
  UseFormReturn,
  UseFormTrigger
} from "react-hook-form";
import * as yup from "yup";
import { REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE } from "@security-watchdog/ui-form-validation";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  DESCRIPTION_VALIDATION_RULES,
  MULTI_FILES_VALIDATION_RULES
} from "src/constants/form.validation";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
  respondToDataRequestVariables,
  respondToDataRequest
} from "src/graphQLTypes";
import { RESPOND_TO_DATA_REQUEST_MUTATION } from "containers/SuccessSubmit/api";
import { NOTIFICATION_INFO_CONFIG } from "src/constants/notifications";
import { useNotifications } from "@security-watchdog/ui-components";

export interface IFormFields {
  description: string;
  attachments: File[];
}

export enum FORM_FIELDS {
  DESCRIPTION = "description",
  ATTACHMENTS = "attachments"
}

const validationSchema = yup.object().shape({
  [FORM_FIELDS.DESCRIPTION]: DESCRIPTION_VALIDATION_RULES.required(
    REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE
  ),
  [FORM_FIELDS.ATTACHMENTS]: MULTI_FILES_VALIDATION_RULES
});

export const validationResolver = yupResolver(validationSchema);

type UseProductActionAnswerForm = {
  formValues: IFormFields;
  formErrors: FieldErrors<IFormFields>;
  formTrigger: UseFormTrigger<IFormFields>;
  formControl: Control<IFormFields>;
  formReset: UseFormReset<IFormFields>;
  submitForm: () => Promise<boolean>;
};

export const useProductActionAnswerForm = (
  requestId: string
): UseProductActionAnswerForm => {
  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    reset
  }: UseFormReturn<IFormFields> = useForm<IFormFields>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: validationResolver as any
  });

  const { addNotification } = useNotifications();

  const [respondToDataRequest] = useMutation<
    respondToDataRequest,
    respondToDataRequestVariables
  >(RESPOND_TO_DATA_REQUEST_MUTATION);

  const formValues: IFormFields = watch();

  const submitForm = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      handleSubmit(async (data: IFormFields) => {
        try {
          const response = await respondToDataRequest({
            variables: {
              files: data.attachments,
              input: {
                content: data.description,
                id: requestId
              }
            }
          });

          if (response.data?.respondToDataRequest.product.id) {
            addNotification({
              ...NOTIFICATION_INFO_CONFIG,
              showCloseIcon: false,
              content: "Message has been successfully sent"
            });
            return resolve(true);
          }

          return resolve(false);
        } catch (e) {
          return resolve(false);
        }
      })();
    });
  }, [respondToDataRequest, addNotification, handleSubmit, requestId]);

  return {
    submitForm,
    formValues,
    formReset: reset,
    formErrors: errors,
    formTrigger: trigger,
    formControl: control
  };
};
