import { IAnswer, IFieldAnswer } from "../types";
import { FieldType } from "../graphQLTypes";

export function sortingRuleForFromToPairs(
  a: { from: number; to: number | null },
  b: { from: number; to: number | null }
): -1 | 1 | 0 {
  if (a.from && b.from) {
    // a is current address
    if (!a.to) {
      return 1;
    }
    // b is current address
    if (!b.to) {
      return -1;
    }
    if (a.from.valueOf() < b.from.valueOf()) {
      return -1;
    }
    if (a.from.valueOf() > b.from.valueOf()) {
      return 1;
    }
  }
  return 0;
}

export const sortTimeLineAnswers = (answers: IAnswer[]): IAnswer[] => {
  return [...answers].sort((a: IAnswer, b: IAnswer): number => {
    const aDateRange: IFieldAnswer | undefined = a.fields.find(
      (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
    );
    const bDateRange: IFieldAnswer | undefined = b.fields.find(
      (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
    );
    return sortingRuleForFromToPairs(aDateRange?.value, bDateRange?.value);
  });
};
