import React, { PropsWithChildren, useEffect, useState } from "react";
import { TIME_FOR_CONFIRM } from "src/constants";
import * as s from "./styles";
import { IProps } from "./types";

const getSeconds = (ms: number): number => Math.floor((ms / 1000) % 60);

const getMinutes = (ms: number): number => Math.floor(ms / 1000 / 60);

const TimerCountDownComponent: React.FC<PropsWithChildren<IProps>> = ({
  onComplete
}: PropsWithChildren<IProps>) => {
  const startTime: number = new Date().valueOf();
  const [remainingTime, setRemainingTime] = useState<number>(TIME_FOR_CONFIRM);

  useEffect(() => {
    const interval: number = 1000; // in milliseconds

    if (remainingTime < interval) {
      onComplete();
      return;
    }

    const timerId: number = window.setTimeout(() => {
      const currentTime: number = new Date().valueOf() - startTime;
      setRemainingTime(remainingTime - currentTime);
    }, interval);

    // eslint-disable-next-line consistent-return
    return (): void => {
      clearTimeout(timerId);
    };
  }, [onComplete, remainingTime, startTime]);

  return remainingTime > 0 ? (
    <s.Timer>
      {String(getMinutes(remainingTime)).padStart(2, "0")} :{" "}
      {String(getSeconds(remainingTime)).padStart(2, "0")} minutes
    </s.Timer>
  ) : null;
};

export const TimerCountDown = React.memo(TimerCountDownComponent);
