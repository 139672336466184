import styled from "styled-components";
import {
  Field as FieldComponent,
  getTypographyStyles
} from "@security-watchdog/sw-ui-kit";

export const Field = styled(FieldComponent)`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DateRangeWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const DatePickerWrapper = styled.div`
  width: 50%;
  max-width: 250px;
  &:first-child {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 8px;
  }
`;

export const SubLabel = styled.div`
  ${getTypographyStyles({ color: "stormGray", variant: "16RegularL24" })};
`;
