import React, { useMemo } from "react";
import { ITimeLineRestriction, TimelineEntityName } from "src/types";
import {
  takeEmploymentEducationQuestion,
  takeEmploymentEducationRestriction,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import { useSelector } from "react-redux";
import { getDateUnitText } from "src/utils";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageTimelineData } from "modules/case/types";
import { TimelineSectionDetails } from "../common/TimelineSectionDetails";

const EmploymentAndEducationComponent: React.FC = () => {
  const data: IPageTimelineData = useSelector(takeEmploymentEducationQuestion);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const timeLineRestriction: ITimeLineRestriction | undefined = useSelector(
    takeEmploymentEducationRestriction
  );

  const description: string = useMemo(() => {
    if (!data.question?.isMultipleAnswers) {
      return "Please give us details of your current employer";
    }

    return timeLineRestriction?.duration
      ? `Please share your employment and education history (including your activities in between) for the past ${
          timeLineRestriction.duration
        } ${getDateUnitText(
          timeLineRestriction.durationUnit,
          timeLineRestriction.duration
        )}`
      : "Please share your employment and education history (including your activities in between)";
  }, [data.question, timeLineRestriction]);

  return (
    <TimelineSectionDetails
      sectionStatus={data.status}
      saveDraftStatusState={saveDraftStatusState}
      section={Section.EMPLOYMENT_EDUCATION}
      title="Employment and education"
      description={description}
      entityName={TimelineEntityName.ACTIVITY}
      question={data.question}
      timeLineRestriction={timeLineRestriction}
    />
  );
};

export const EmploymentAndEducation = React.memo(
  EmploymentAndEducationComponent
);

export default EmploymentAndEducation;
