import { MODAL_TAB } from "./types";

export const MODAL_TABS = [
  {
    label: "Draw",
    value: MODAL_TAB.Draw
  },
  {
    label: "Type",
    value: MODAL_TAB.Type
  },
  {
    label: "Upload",
    value: MODAL_TAB.Upload
  }
];

export const TYPE_SIGNATURE_TEXT_SIZE: number = 40;

export const SELECT_SIGNATURE_MESSAGE = "Please select signature.";
