type B2CPoliciesProps = {
  AZURE_AUTHORITY_DOMAIN: string;
  AZURE_SIGN_IN_NAME: string;
  AZURE_SIGN_UP_OR_SIGN_IN_NAME: string;
  AZURE_SIGN_UP_NAME: string;
  AZURE_RESET_NAME: string;
};

export type B2CPolicies = {
  names: {
    signUp: string;
    signIn: string;
    signUp_or_signIn: string;
  };
  authorities: {
    signUp: {
      authority: string;
    };
    signIn: {
      authority: string;
    };
    signUp_or_signIn: {
      authority: string;
    };
    reset: {
      authority: string;
    };
  };
  authorityDomain: string;
};

export const getB2cPolicies = (() => {
  let instance: B2CPolicies | null = null;

  const createInstance = ({
    AZURE_AUTHORITY_DOMAIN,
    AZURE_SIGN_IN_NAME,
    AZURE_SIGN_UP_OR_SIGN_IN_NAME,
    AZURE_SIGN_UP_NAME,
    AZURE_RESET_NAME
  }: B2CPoliciesProps) => {
    const CANDIDATE_AZURE_AUTHORITY_DOMAIN_NAME =
      AZURE_AUTHORITY_DOMAIN.split(".")[0];

    return {
      names: {
        signUp: AZURE_SIGN_UP_NAME,
        signIn: AZURE_SIGN_IN_NAME,
        signUp_or_signIn: AZURE_SIGN_UP_OR_SIGN_IN_NAME
      },
      authorities: {
        signUp: {
          authority: `https://${AZURE_AUTHORITY_DOMAIN}/${CANDIDATE_AZURE_AUTHORITY_DOMAIN_NAME}.onmicrosoft.com/${AZURE_SIGN_UP_NAME}`
        },
        signIn: {
          authority: `https://${AZURE_AUTHORITY_DOMAIN}/${CANDIDATE_AZURE_AUTHORITY_DOMAIN_NAME}.onmicrosoft.com/${AZURE_SIGN_IN_NAME}`
        },
        signUp_or_signIn: {
          authority: `https://${AZURE_AUTHORITY_DOMAIN}/${CANDIDATE_AZURE_AUTHORITY_DOMAIN_NAME}.onmicrosoft.com/${AZURE_SIGN_UP_OR_SIGN_IN_NAME}`
        },
        reset: {
          authority: `https://${AZURE_AUTHORITY_DOMAIN}/${CANDIDATE_AZURE_AUTHORITY_DOMAIN_NAME}.onmicrosoft.com/${AZURE_RESET_NAME}`
        }
      },
      authorityDomain: AZURE_AUTHORITY_DOMAIN
    };
  };

  return (azureVariables?: Partial<B2CPoliciesProps>) => {
    if (!instance) {
      instance = createInstance(azureVariables as B2CPoliciesProps);
    }
    return instance;
  };
})();
