import {
  IFormFields,
  IPartialTroubleShootHistoryRecord,
  IQuestion
} from "src/types";
import {
  getCase_screeningCase_trustIdApplication,
  Section
} from "src/graphQLTypes";
import { CardStatuses } from "components/StatusCard/types";
import { IGetSection } from "src/utils/getSection";

export interface IAutoSaveVariables {
  data: IFormFields;
  questions: IQuestion[];
  caseId: string;
  isTimelineSection?: boolean;
  section: Section;
  draftTroubleshootingHistory: IPartialTroubleShootHistoryRecord[];
}

export enum IDraftStatuses {
  NO_CHANGES = "NO_CHANGES",
  UNSAVED_CHANGES = "UNSAVED_CHANGES",
  SAVING = "SAVING",
  SAVED = "SAVED"
}

export interface IDraftStatusState {
  status: IDraftStatuses;
  inProgress: boolean;
  clearDataInProgress: boolean;
  error?: string | null;
}

export interface IPageTimelineData {
  question: IQuestion | undefined;
  status: CardStatuses;
}

export interface IPageData {
  questions: IQuestion[];
  status: CardStatuses;
}

export type TrustIdPageData = IGetSection & {
  trustIdApplication: getCase_screeningCase_trustIdApplication | null;
};

export interface IClearSectionDraftValuesVariables {
  section?: Section;
  caseId: string;
  history: IPartialTroubleShootHistoryRecord[];
}

export interface IAdditionalDocumentsList {
  noteForScreener: string;
  files: File[];
}

export interface IAdditionalDocumentsState {
  status: CardStatuses;
  data: IAdditionalDocumentsList;
}

export interface ISaveUploadHistoryVariables {
  caseId: string;
  history: IPartialTroubleShootHistoryRecord[];
}
