import React, { FC } from "react";
import * as s from "containers/ScreenerAccess/styles";
import {
  BUTTON_TYPE,
  CheckOutsideCircleIcon,
  CloseCircleIcon,
  SMALL_ICON_SIZE
} from "@security-watchdog/sw-ui-kit";
import { DefaultTheme, useTheme } from "styled-components";
import { TroubleshootingRequestStatus } from "src/graphQLTypes";

export interface IActionsProps {
  status: TroubleshootingRequestStatus | null;
  isAcceptingRequestInProgress: boolean;
  isCancelRequestInProgress: boolean;
  handleAcceptRequest: () => void;
  handleCancelRequest: () => void;
}

export const Actions: FC<IActionsProps> = ({
  status,
  handleAcceptRequest,
  handleCancelRequest,
  isCancelRequestInProgress,
  isAcceptingRequestInProgress
}: IActionsProps) => {
  const theme: DefaultTheme = useTheme();

  switch (status) {
    case TroubleshootingRequestStatus.SCREENER_REQUESTED:
      return (
        <>
          <s.ActionButton
            buttonType={BUTTON_TYPE.Link}
            isLoading={isAcceptingRequestInProgress}
            icon={
              <CheckOutsideCircleIcon
                size={SMALL_ICON_SIZE}
                color={theme.colors.white}
              />
            }
            onClick={handleAcceptRequest}
          >
            Allow
          </s.ActionButton>
          <s.ActionButton
            buttonType={BUTTON_TYPE.Link}
            onClick={handleCancelRequest}
            isLoading={isCancelRequestInProgress}
            icon={
              <CloseCircleIcon
                size={SMALL_ICON_SIZE}
                color={theme.colors.white}
              />
            }
          >
            Cancel
          </s.ActionButton>
        </>
      );
    default:
      return null;
  }
};
