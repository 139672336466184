export enum AddressFormField {
  COUNTY = "county",
  COUNTRY = "country",
  TOWN = "town",
  POST_CODE = "postcode",
  ADDRESS_LINE_1 = "addressLine1",
  ADDRESS_LINE_2 = "addressLine2",
  ADDRESS_LINE_3 = "addressLine3"
}

export type AddressFormFieldValidation = {
  [key in AddressFormField]: string;
};
