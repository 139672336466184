import { FilesError } from "@security-watchdog/ui-components";

import { fieldAnyValueFragment_Attachment } from "src/graphQLTypes";
import { isUploadFile } from "src/utils/typeGuards";
import { getYupErrorForField } from "src/utils/validationResolver";
import { getDropzoneFilesToValidate } from "src/utils/attachments";
import { MULTI_FILES_VALIDATION_RULES } from "src/constants/form.validation";
import { getMultiUploadFilesError } from "src/utils/validateUploadedFiles";

type SplitDroppedFilesToValidAndInvalidResult = {
  validFiles: File[];
  invalidFiles: File[];
};

const getGeneralValidationError = (
  errors: FilesError[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
): FilesError | undefined =>
  errors?.find((error) => !error.invalidFileIndexes?.length);

export const splitDroppedFilesToValidAndInvalid = (
  files: Array<fieldAnyValueFragment_Attachment | File>,
  errors: FilesError[] | undefined
): SplitDroppedFilesToValidAndInvalidResult => {
  const validFiles = getGeneralValidationError(errors)
    ? []
    : files
        .filter((_, idx) =>
          errors?.every((error) => !error.invalidFileIndexes?.includes(idx))
        )
        .filter(isUploadFile);
  const invalidFiles = getGeneralValidationError(errors)
    ? files.filter(isUploadFile)
    : files
        .filter((_, idx) =>
          errors?.some((error) => error.invalidFileIndexes?.includes(idx))
        )
        .filter(isUploadFile);

  return { validFiles, invalidFiles };
};

export const getViewerFiles = (
  files: fieldAnyValueFragment_Attachment[],
  pendingFiles: File[]
): Array<fieldAnyValueFragment_Attachment | File> => [
  ...(files || []),
  ...(pendingFiles || [])
];

export const getFilesViewerError = (
  files: Array<fieldAnyValueFragment_Attachment | File>
): FilesError[] | undefined => {
  const error = getYupErrorForField(
    getDropzoneFilesToValidate(files),
    MULTI_FILES_VALIDATION_RULES
  );

  return getMultiUploadFilesError(error || undefined);
};
