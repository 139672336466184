/* eslint-disable */
import { Spinner } from "components/common/Spinner";
import { ErrorPage } from "components/ErrorPage";
import { SuccessSubmit } from "containers/SuccessSubmit";
import { AdditionalDocuments } from "containers/AdditionalDocuments";
import {
  takeCaseError,
  takeCaseStatus,
  takeIsCaseLoaded,
  takeIsCaseLoading,
  takeShowAdditionalDocumentsSection
} from "modules/case/selectors";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CandidateCaseStatus } from "src/graphQLTypes";
import { CaseErrorTypes, IErrorType } from "src/types";
import { Header as CommonHeader } from "components/Header";
import { AuthContext, IAuthContextValueProps } from "src/context/auth";
import { ROUTES } from "src/constants";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";

type Props = PropsWithChildren<unknown>;

const PrivateRoute: React.FC<Props> = ({ children }: Props) => {
  const { isAuthenticated }: IAuthContextValueProps =
    useContext<IAuthContextValueProps>(AuthContext);

  const caseStatus: CandidateCaseStatus | undefined =
    useSelector(takeCaseStatus);

  const isCaseLoaded: boolean = useSelector(takeIsCaseLoaded);

  const isCaseLoading: boolean = useSelector(takeIsCaseLoading);

  const caseError: IErrorType = useSelector(takeCaseError);

  const showAdditionalDocuments: boolean = useSelector(
    takeShowAdditionalDocumentsSection
  );

  const { logPageView }: IUseGA = googleAnalytics();

  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthenticated) {
    return <Spinner />;
  }

  if (isCaseLoading || !isCaseLoaded) {
    return (
      <>
        <CommonHeader />
        <Spinner />
      </>
    );
  }

  if (caseError) {
    return (
      <>
        <CommonHeader />
        <ErrorPage
          message={
            caseError.type === CaseErrorTypes.INVALID_LINK
              ? "Screening link is invalid"
              : "Access denied"
          }
        />
      </>
    );
  }

  if (
    showAdditionalDocuments &&
    location.pathname === ROUTES.ADDITIONAL_DOCUMENTS
  ) {
    return <AdditionalDocuments />;
  }

  if (caseStatus !== CandidateCaseStatus.ACTIVE) {
    return <SuccessSubmit />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
