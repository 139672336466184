// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areEqualValues = (value: any, oldValue: any): boolean | null => {
  switch (typeof value) {
    case "object":
      try {
        if (value === null) {
          return value === oldValue;
        }

        if (!oldValue) {
          return value === oldValue;
        }

        if (Array.isArray(value)) {
          if (!Array.isArray(oldValue)) {
            return false;
          }

          if (value.length !== oldValue.length) {
            return false;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return value.every((item: any, index: number) =>
            Boolean(areEqualValues(item, oldValue[index]))
          );
        }

        const valueKeys: string[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const key in value) {
          if (Object.prototype.hasOwnProperty.call(value as object, key)) {
            valueKeys.push(key);
          }
        }

        const oldValueKeys: string[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const key in oldValue) {
          if (Object.prototype.hasOwnProperty.call(oldValue as object, key)) {
            oldValueKeys.push(key);
          }
        }

        if (valueKeys.length !== oldValueKeys.length) {
          return false;
        }

        let valueKeyIndex: number = 0;
        while (valueKeyIndex < valueKeys.length) {
          const key: string = valueKeys[valueKeyIndex];

          const areEquals: boolean | null = areEqualValues(
            value[key],
            oldValue[key]
          );

          if (!areEquals) {
            return false;
          }
          valueKeyIndex++;
        }

        return true;
      } catch (error) {
        return null;
      }

    case "function":
      return value.toString() === oldValue.toString();

    case "string":
    case "number":
    case "boolean":
    case "undefined":
    default:
      return value === oldValue;
  }
};
