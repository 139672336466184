import { OptionProps } from "@security-watchdog/sw-ui-kit";
import { Countries_countries } from "../graphQLTypes";

export const mappedCountryOptions = (
  countries: Countries_countries[]
): Array<OptionProps<Countries_countries>> =>
  countries.map(
    (country: Countries_countries): OptionProps<Countries_countries> => ({
      id: country.id,
      value: country,
      label: country.name
    })
  );
