import { IReducer } from "modules/case/reducers";
import {
  getCase_screeningCase_products,
  getCase_screeningCase_sections,
  Section
} from "../graphQLTypes";

export interface IGetSection {
  section: getCase_screeningCase_sections | undefined;
  products: getCase_screeningCase_products[];
}

export const getSection = (
  rootCase: IReducer,
  sectionName: Section
): IGetSection => ({
  section: rootCase.case?.sections.find(
    (section: getCase_screeningCase_sections) => section.section === sectionName
  ),
  products: rootCase.case?.products || []
});
