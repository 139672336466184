import styled from "styled-components";
import { Button } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    display: block;
  }
`;

export const NavButton = styled(Button)`
  margin-left: 34px;

  @media (max-width: ${DEVICE.TABLET}) {
    margin: 0;
    padding: 10px 0;
    width: 100%;
  }
`;

export const TextButton = styled.span`
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.stormGray};
`;
