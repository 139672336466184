import { CardStatuses } from "components/StatusCard/types";
import { IconName, ISection } from "containers/SectionList/types";
import { IReducer } from "modules/case/reducers";
import { createSelector } from "reselect";
import { OptionProps } from "@security-watchdog/sw-ui-kit";
import { v4 as uuidv4 } from "uuid";
import {
  IAdditionalDocumentsState,
  IDraftStatusState,
  IPageData,
  IPageTimelineData,
  TrustIdPageData
} from "modules/case/types";
import {
  ROUTES,
  SECTION_DESCRIPTION,
  SECTION_SORT_INDEX,
  SECTION_TITLE
} from "src/constants";
import {
  attachmentFragment,
  CandidateCaseFragment_consentForm,
  CandidateCaseFragment_sectionsByAffectingField,
  CandidateCaseStatus,
  ConsentType,
  Countries_countries,
  FieldType,
  getCase_screeningCase_additionalCandidateAttachments,
  getCase_screeningCase_candidate,
  getCase_screeningCase_contract_support,
  getCase_screeningCase_products,
  getCase_screeningCase_products_product_conditionalValidations,
  getCase_screeningCase_products_product_conditionalValidations_conditions,
  getCase_screeningCase_sections,
  getCase_screeningCase_sections_answers,
  QuestionType,
  Section
} from "src/graphQLTypes";
import { IRootReducer } from "src/reducers";
import {
  IErrorType,
  IProofOfIdentityField,
  IQuestion,
  ITimeLineRestriction,
  IValidationCondition
} from "src/types";
import { IGetSection } from "src/utils/getSection";
import {
  DraftSectionValues,
  DraftValues
} from "src/utils/buildInputForSaveDraftValues";
import { getSectionStatus } from "src/utils/getSectionStatus";
import { isStringValue } from "src/utils/typeGuards";
import { getSectionData } from "src/utils/getSectionData";
import {
  getAnswersWithFieldTypeUpload,
  getDateUnitText,
  getQuestionsOfMyDocumentSection,
  getQuestionsOfSection,
  getQuestionVisibility,
  getSection,
  getTimeLineRestriction,
  isKonfirIntegrationEnabled,
  isTrustIdProductEnabled,
  mappedCountryOptions,
  sortBy
} from "src/utils";
import * as _ from "lodash";

export const takeSections = (
  state: IRootReducer
): getCase_screeningCase_sections[] =>
  state.case.case ? state.case.case.sections : [];

export const takeCaseContractId = (state: IRootReducer) =>
  state.case.case?.contract.id || "";

export const takeCaseId = (state: IRootReducer): string =>
  state.case.case?.id || "";

export const takeCaseCode = (state: IRootReducer): string =>
  state.case.case?.code || "";

export const takeClientName = (state: IRootReducer): string =>
  state.case.case?.client.name || "";

export const takeIsLiveChatEnabledForClient = (
  state: IRootReducer
): boolean | null => state.case.case?.client.liveChatEnabled ?? null;

export const rootSelector = (state: IRootReducer): IReducer => state.case;

export const takeCaseStatus = (
  state: IRootReducer
): CandidateCaseStatus | undefined => state.case.case?.status;

export const takeCaseError = (state: IRootReducer): IErrorType =>
  state.case.caseError;

export const takeIsCaseLoading = (state: IRootReducer): boolean =>
  state.case.isCaseLoading;

export const takeCountries = (state: IRootReducer): Countries_countries[] =>
  state.case.countries;

export const takeAreCountriesLoading = (state: IRootReducer): boolean =>
  state.case.areCountriesLoading;

export const takeConsentGiven = (state: IRootReducer): boolean =>
  state.case.case?.consentGiven || false;

export const takeConsentType = (state: IRootReducer): ConsentType =>
  state.case.case?.consentType || ConsentType.DIGITAL;

export const takeConsentText = (state: IRootReducer): string =>
  state.case.case?.consentText || "";

export const takeOriginalConsentForm = (
  state: IRootReducer
): CandidateCaseFragment_consentForm | null | undefined =>
  state.case.case?.originalConsentForm;

export const takeConsentForm = (
  state: IRootReducer
): CandidateCaseFragment_consentForm | null | undefined =>
  state.case.case?.consentForm;

export const takeWhenCreated = (state: IRootReducer): number | undefined =>
  state.case.case?.whenCreated;

export const takeIsCaseUpdating = (state: IRootReducer): boolean =>
  state.case.isCaseUpdating;

export const takeIsCaseSubmitting = (state: IRootReducer): boolean =>
  state.case.isCaseSubmitting;

export const takeUpdatedFieldIds = (state: IRootReducer): string[] =>
  state.case.updatedFieldIds;

export const takeCountryOptions = createSelector(
  takeCountries,
  (countries: Countries_countries[]): Array<OptionProps<Countries_countries>> =>
    mappedCountryOptions(countries)
);

export const takeDeclarationSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection => getSection(rootCase, Section.DECLARATION)
);

export const takeDraftData = (state: IRootReducer): DraftValues => {
  const stringData: string | null | undefined = state.case.case?.draft;
  if (stringData) {
    const parsedData: DraftSectionValues = JSON.parse(stringData || "{}");

    return parsedData || {};
  }
  return {};
};

export const takePersonalInformationSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection =>
    getSection(rootCase, Section.PERSONAL_INFORMATION)
);

export const takeProfessionalMemberShipSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection =>
    getSection(rootCase, Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION)
);

export const takeEmploymentEducationSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection =>
    getSection(rootCase, Section.EMPLOYMENT_EDUCATION)
);

export const takeAddressTimeLineSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection =>
    getSection(rootCase, Section.ADDRESS_HISTORY)
);

export const takePersonalRefereesSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection =>
    getSection(rootCase, Section.PERSONAL_REFEREES)
);

export const takeTrustIdSection = createSelector(
  rootSelector,
  (rootCase: IReducer): TrustIdPageData => ({
    ...getSection(rootCase, Section.TRUST_ID),
    trustIdApplication: rootCase.case?.trustIdApplication || null
  })
);

export const takeKonfirSection = createSelector(
  rootSelector,
  (rootCase: IReducer): IGetSection => getSection(rootCase, Section.KONFIR)
);

export const takeDeclarationQuestions = createSelector(
  takeDeclarationSection,
  takeDraftData,
  (data: IGetSection, draft: DraftValues): IPageData => ({
    questions: getQuestionsOfSection({
      answers: data.section?.answers,
      isTimelineSection: false,
      products: data.products,
      filterUploadFields: false,
      draftData: draft[Section.DECLARATION]
    }),
    status: getSectionStatus({
      completed: data.section?.completed,
      draftValues: draft[Section.DECLARATION]
    })
  })
);

export const takePersonalInformationQuestions = createSelector(
  takePersonalInformationSection,
  takeDraftData,
  (data: IGetSection, draft: DraftValues): IPageData => ({
    questions: getQuestionsOfSection({
      answers: data.section?.answers,
      isTimelineSection: false,
      products: data.products,
      filterUploadFields: true,
      draftData: draft[Section.PERSONAL_INFORMATION]
    }),
    status: getSectionStatus({
      completed: data.section?.completed,
      draftValues: draft[Section.PERSONAL_INFORMATION]
    })
  })
);

export const takeHasRoleLocationScotlandField = createSelector(
  takePersonalInformationQuestions,
  (data: IPageData): boolean =>
    data.questions.some((q) =>
      q.answers.some((a) =>
        a.fields.some(
          (f) => f.isRoleLocationScotland && typeof f.value !== "undefined"
        )
      )
    )
);

export const takeRoleLocationScotland = createSelector(
  takePersonalInformationQuestions,
  (data: IPageData): boolean =>
    data.questions.some((q) =>
      q.answers.some((a) =>
        a.fields.some((f) => f.isRoleLocationScotland && f.value)
      )
    )
);

export const takeHasEnhancedCriminalityCheck = (state: IRootReducer): boolean =>
  Boolean(state.case.case?.hasEnhancedCriminalityCheck);

export const takeProfessionalMemberShipQuestions = createSelector(
  takeProfessionalMemberShipSection,
  takeDraftData,
  (data: IGetSection, draft: DraftValues): IPageData => ({
    questions: getQuestionsOfSection({
      answers: data.section?.answers,
      isTimelineSection: false,
      products: data.products,
      filterUploadFields: true,
      draftData: draft[Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]
    }),
    status: getSectionStatus({
      completed: data.section?.completed,
      draftValues: draft[Section.PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION]
    })
  })
);

export const takeEmploymentEducationQuestion = createSelector(
  takeEmploymentEducationSection,
  takeDraftData,
  (data: IGetSection, draft: DraftValues): IPageTimelineData => ({
    question: getQuestionsOfSection({
      answers: data.section?.answers,
      isTimelineSection: true,
      products: data.products,
      filterUploadFields: false,
      draftData: draft[Section.EMPLOYMENT_EDUCATION]
    }).find(
      (question: IQuestion) =>
        question.questionType === QuestionType.EMPLOYMENT_EDUCATION_HISTORY
    ),
    status: getSectionStatus({
      completed: data.section?.completed,
      draftValues: draft[Section.EMPLOYMENT_EDUCATION]
    })
  })
);

export const takeAddressTimeLineQuestion = createSelector(
  takeAddressTimeLineSection,
  takeDraftData,
  (data: IGetSection, draft: DraftValues): IPageTimelineData => ({
    question: getQuestionsOfSection({
      answers: data.section?.answers,
      isTimelineSection: true,
      products: data.products,
      filterUploadFields: false,
      draftData: draft[Section.ADDRESS_HISTORY]
    }).find(
      (question: IQuestion) =>
        question.questionType === QuestionType.ADDRESS_HISTORY
    ),
    status: getSectionStatus({
      completed: data.section?.completed,
      draftValues: draft[Section.ADDRESS_HISTORY]
    })
  })
);

export const takeAddressTimelineDateRanges = createSelector(
  takeAddressTimeLineQuestion,
  (data) => {
    const dateRanges = data.question?.answers.reduce<
      Array<{ from: string; to: string }>
    >((acc, answer) => {
      for (const field of answer.fields) {
        if (field.type === FieldType.DATE_RANGE) {
          const value = field.value as { from: string; to: string };
          acc = acc.concat({ from: value.from, to: value.to });
        }
      }
      return acc;
    }, []);
    return _.sortBy(dateRanges, (dr) => dr.from);
  }
);

export const takeHasAddressOverlaps = createSelector(
  takeAddressTimelineDateRanges,
  (data) => {
    for (let i = 1; i < data.length; i++) {
      if (
        new Date(data[i].from).getTime() < new Date(data[i - 1].to).getTime()
      ) {
        return true;
      }
    }
    return false;
  }
);

export const takeMyDocumentQuestions = createSelector(
  takeSections,
  takeConsentType,
  takeConsentForm,
  (state: IRootReducer) => state.case.case?.products || [],
  (
    sections: getCase_screeningCase_sections[],
    consentType: ConsentType,
    consentForm: CandidateCaseFragment_consentForm | null | undefined,
    products: getCase_screeningCase_products[]
  ): IPageData => {
    const allAnswers: getCase_screeningCase_sections_answers[] =
      getAnswersWithFieldTypeUpload(sections);

    return {
      questions: getQuestionsOfMyDocumentSection(
        getQuestionsOfSection({
          answers: allAnswers,
          products,
          isTimelineSection: false,
          filterUploadFields: false
        }),
        consentType,
        consentForm
      ),
      status: CardStatuses.HAS_OPTIONAL
    };
  }
);

export const takeEmploymentEducationRestriction = createSelector(
  takeEmploymentEducationQuestion,
  takeWhenCreated,
  (
    data: IPageTimelineData,
    whenCreated: number | undefined
  ): ITimeLineRestriction | undefined => {
    if (!data.question) {
      return undefined;
    }
    return getTimeLineRestriction(data.question, whenCreated);
  }
);

export const takeAddressTimeLineRestriction = createSelector(
  takeAddressTimeLineQuestion,
  takeWhenCreated,
  (
    data: IPageTimelineData,
    whenCreated: number | undefined
  ): ITimeLineRestriction | undefined => {
    if (!data.question) {
      return undefined;
    }
    return getTimeLineRestriction(data.question, whenCreated);
  }
);

export const takeProofOfIdentityField = createSelector(
  takeMyDocumentQuestions,
  (data: IPageData): IProofOfIdentityField | undefined => {
    const proofOfIdentityQuestion: IQuestion | undefined = data.questions.find(
      (question: IQuestion) =>
        question.questionType === QuestionType.IDENTITY_PROOF
    );
    if (proofOfIdentityQuestion) {
      return {
        questionId: proofOfIdentityQuestion.questionId,
        fieldId: proofOfIdentityQuestion.fields[0].fieldId,
        isAnswered: proofOfIdentityQuestion.answers[0].isAnswered
      };
    }
    return undefined;
  }
);

export const takeIsMyDocumentSectionCompleted = createSelector(
  takeMyDocumentQuestions,
  (data: IPageData): boolean =>
    data.questions.every((question: IQuestion) => question.completed)
);

export const takeIsTermsAndConditionsSectionCompleted = (
  state: IRootReducer
): boolean => {
  if (!state.case.case?.consentType) {
    return false;
  }
  switch (state.case.case.consentType) {
    case ConsentType.DIGITAL:
    case ConsentType.WET_SIGNATURE:
      return !!state.case.case?.consentGiven;
    case ConsentType.E_SIGNATURE:
      return (
        state.case.case?.eSignature !== null && !!state.case.case?.consentGiven
      );
    default:
      return false;
  }
};

export const isSubmitDisabledSelector = createSelector(
  takeSections,
  takeIsMyDocumentSectionCompleted,
  takeIsTermsAndConditionsSectionCompleted,
  takeDraftData,
  (
    sections: getCase_screeningCase_sections[],
    isMyDocumentSectionCompleted: boolean,
    isTermsAndConditionSectionCompleted: boolean,
    draftData: DraftValues
  ): boolean => {
    if (!sections.length) {
      return true;
    }
    return (
      !sections.every(
        (section: getCase_screeningCase_sections) =>
          getSectionStatus({
            completed: section.completed,
            draftValues: draftData[section.section]
          }) === CardStatuses.COMPLETE
      ) ||
      !isMyDocumentSectionCompleted ||
      !isTermsAndConditionSectionCompleted
    );
  }
);

export const takeAddressTimeLineDescription = createSelector(
  takeAddressTimeLineRestriction,
  (restriction: ITimeLineRestriction | undefined): string => {
    if (restriction) {
      return `Verify your current address. Share your address history`;
    }
    return "Verify your current address";
  }
);

export const getEmploymentEducationDescription = createSelector(
  takeEmploymentEducationRestriction,
  (restriction: ITimeLineRestriction | undefined): string => {
    if (restriction) {
      const unit: string = getDateUnitText(
        restriction.durationUnit,
        restriction.duration
      );
      return `Share your employment and education history for the last ${restriction.duration} ${unit}`;
    }
    return "Share your employment and education history";
  }
);

export const takeIsEmploymentEducationSectionCompleted = createSelector(
  takeEmploymentEducationSection,
  takeDraftData,
  (employmentEducationSection: IGetSection, draft: DraftValues): boolean => {
    const sectionStatus: CardStatuses = getSectionStatus({
      completed: employmentEducationSection.section?.completed,
      draftValues: draft[Section.EMPLOYMENT_EDUCATION]
    });

    return sectionStatus === CardStatuses.COMPLETE;
  }
);

export const createSectionsList = (state: IRootReducer): ISection[] => {
  const draftValues: DraftValues = takeDraftData(state);

  const newSections: ISection[] = state.case.case
    ? state.case.case.sections.reduce(
        (newSections: ISection[], section: getCase_screeningCase_sections) => {
          const draftSectionValues = draftValues[section.section];

          const areAnySectionQuestionVisible = section.answers.some(
            (answer: getCase_screeningCase_sections_answers) =>
              getQuestionVisibility(
                answer,
                draftSectionValues
                  ? draftSectionValues[answer.question.id]
                  : null
              )
          );

          if (section.section === Section.TRUST_ID) {
            return isTrustIdProductEnabled()
              ? [...newSections, getSectionData(section, draftValues, state)]
              : newSections;
          }

          if (section.section === Section.KONFIR) {
            return isKonfirIntegrationEnabled()
              ? [...newSections, getSectionData(section, draftValues, state)]
              : newSections;
          }

          if (areAnySectionQuestionVisible) {
            return [
              ...newSections,
              getSectionData(section, draftValues, state)
            ];
          }

          return newSections;
        },
        []
      )
    : [];

  const myDocumentQuestions: IPageData = takeMyDocumentQuestions(state);

  if (
    state.case.case &&
    newSections &&
    myDocumentQuestions.questions.some((q) => q.isVisible)
  ) {
    const isMyDocumentSectionCompleted: boolean =
      takeIsMyDocumentSectionCompleted(state);

    newSections.push({
      id: uuidv4(),
      sortNumber: SECTION_SORT_INDEX.DOCUMENTS,
      title: SECTION_TITLE.DOCUMENTS,
      description: SECTION_DESCRIPTION.DOCUMENTS,
      icon: IconName.folder,
      status: isMyDocumentSectionCompleted
        ? CardStatuses.COMPLETE
        : CardStatuses.INCOMPLETE,
      path: ROUTES.MY_DOCUMENTS
    });
  }

  if (newSections && !state.main.isAdminFlow) {
    newSections.push({
      id: uuidv4(),
      sortNumber: SECTION_SORT_INDEX[Section.TERMS_AND_CONDITIONS],
      title: SECTION_TITLE.TERMS_AND_CONDITIONS,
      description: SECTION_DESCRIPTION.TERMS_AND_CONDITIONS,
      icon: IconName.checkShieldIcon,
      status: takeIsTermsAndConditionsSectionCompleted(state)
        ? CardStatuses.COMPLETE
        : CardStatuses.INCOMPLETE,
      path: ROUTES.TERMS_AND_CONDITIONS
    });
  }

  return newSections ? sortBy(newSections, "sortNumber") : newSections;
};

export const takeAdditionalDocumentsStatus = (state: IRootReducer): boolean =>
  state.case.additionalDocumentsState.status === CardStatuses.COMPLETE;

const createSectionsListWhenCaseIsSubmitted = (
  state: IRootReducer
): ISection[] => {
  const isAdditionalDocumentSectionCompleted: boolean =
    takeAdditionalDocumentsStatus(state);

  return [
    {
      id: uuidv4(),
      title: SECTION_TITLE.ADDITIONAL_DOCUMENTS,
      description: SECTION_DESCRIPTION.ADDITIONAL_DOCUMENTS,
      icon: IconName.folder,
      status: isAdditionalDocumentSectionCompleted
        ? CardStatuses.COMPLETE
        : CardStatuses.INCOMPLETE,
      path: ROUTES.ADDITIONAL_DOCUMENTS,
      sortNumber: SECTION_SORT_INDEX.ADDITIONAL_DOCUMENTS
    }
  ];
};

export const takeCustomSectionsList = createSelector(
  createSectionsList,
  (sectionList: ISection[]) => sectionList || []
);

export const takeSectionsListWhenCaseIsSubmitted = createSelector(
  createSectionsListWhenCaseIsSubmitted,
  (sectionList: ISection[]) => sectionList || []
);

export const takeHelpInfo = createSelector(
  rootSelector,
  (state: IReducer): getCase_screeningCase_contract_support | undefined =>
    state.case?.contract.support
);

export const takeESignatureAttachment = (
  state: IRootReducer
): attachmentFragment | null => state.case.case?.eSignature || null;

export const takeSaveDraftStatusState = createSelector(
  rootSelector,
  (state: IReducer): IDraftStatusState => state.saveDraftStatusState
);

export const takeLogoVisibilityStatus = (state: IRootReducer): boolean =>
  state.case.isLogoVisible;

export const takeIsCaseLoaded = (state: IRootReducer): boolean =>
  state.case.isCaseLoaded;

export const takeAdditionalDocuments = (
  state: IRootReducer
): IAdditionalDocumentsState => state.case.additionalDocumentsState;

export const takeAdditionalCandidateAttachments = (
  state: IRootReducer
): getCase_screeningCase_additionalCandidateAttachments[] =>
  state.case.case?.additionalCandidateAttachments || [];

export const takeIsAgreedToPrivacyPolicy = (state: IRootReducer): boolean =>
  state.case.isAgreedToPrivacyPolicy;

export const takeIsAdditionalDocumenstLoading = (
  state: IRootReducer
): boolean => state.case.isAdditionalDocumentsLoading;

export const takeIsAdditionalDocumentsSubmitted = (
  state: IRootReducer
): boolean => state.case.isAdditionalDocumetsSubmitted;

export const takeShowAdditionalDocumentsSection = (
  state: IRootReducer
): boolean => state.case.showAdditionalDocuments;

export const takeCandidateData = (
  state: IRootReducer
): getCase_screeningCase_candidate | undefined => state.case.case?.candidate;

export const takeSubmitApplicationModalText = (state: IRootReducer): string =>
  state.case.case?.troubleshotByScreener
    ? "Following your permission for SW to access or amend your application, please ensure you have reviewed any changes made before clicking Submit"
    : "Are you sure you want to submit your application ?";

export const takeIsOcrTriggered = (state: IRootReducer): boolean =>
  state.case.case?.ocrTriggered || false;

export const takeCaseSectionsByAffectingField = (
  state: IRootReducer
): CandidateCaseFragment_sectionsByAffectingField[] | null =>
  state.case.case?.sectionsByAffectingField || null;

export const takePersonalRefereesQuestions = createSelector(
  takePersonalRefereesSection,
  takeDraftData,
  (data: IGetSection, draft: DraftValues): IPageData => ({
    questions: getQuestionsOfSection({
      answers: data.section?.answers,
      isTimelineSection: false,
      products: data.products,
      filterUploadFields: true,
      draftData: draft[Section.PERSONAL_REFEREES]
    }),
    status: getSectionStatus({
      completed: data.section?.completed,
      draftValues: draft[Section.PERSONAL_REFEREES]
    })
  })
);

export const takeCaseProductsConditionalValidation = createSelector(
  takeEmploymentEducationSection,
  (data: IGetSection): IValidationCondition[] =>
    data.products
      .reduce(
        (
          products: getCase_screeningCase_products_product_conditionalValidations[],
          { product }: getCase_screeningCase_products
        ): getCase_screeningCase_products_product_conditionalValidations[] => [
          ...products,
          ...product.conditionalValidations
        ],
        []
      )
      .reduce(
        (
          conditionalValidations: IValidationCondition[],
          {
            conditions,
            answersCondition,
            validationMessage
          }: getCase_screeningCase_products_product_conditionalValidations
        ): IValidationCondition[] => {
          if (answersCondition) {
            try {
              return [
                ...conditionalValidations,
                {
                  id: conditions[0].id ? JSON.parse(conditions[0].id).id : "",
                  answersCondition,
                  validationMessage,
                  availableAnswers: conditions.reduce(
                    (
                      acc: string[],
                      condition: getCase_screeningCase_products_product_conditionalValidations_conditions
                    ): string[] => {
                      if (isStringValue(condition.fieldValue.value)) {
                        return [...acc, condition.fieldValue.value.stringValue];
                      }

                      return acc;
                    },
                    []
                  )
                }
              ];
            } catch {
              return conditionalValidations;
            }
          }

          return conditionalValidations;
        },
        []
      )
);
