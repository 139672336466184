import { ButtonBack } from "components/ButtonBack";
import { PageHeader } from "components/PageHeader";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { BUTTON_TYPE } from "@security-watchdog/sw-ui-kit";
import { ROUTES } from "src/constants";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { AddNoteToScreener } from "containers/AdditionalDocuments/components/AddNoteToScreener";
import { CardStatuses } from "components/StatusCard/types";
import * as caseActions from "modules/case/actions";
import {
  takeAdditionalCandidateAttachments,
  takeAdditionalDocuments
} from "modules/case/selectors";
import { IAdditionalDocumentsState } from "modules/case/types";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { SectionTitle } from "./components/SectionTitle";
import * as s from "./styles";
import { ListOfUploadedFiles } from "containers/AdditionalDocuments/components/ListOfUploadedFiles";
import { attachmentFragment } from "src/graphQLTypes";
import { downloadFile } from "src/utils";
import { UploadFileViewerValidator } from "components/UploadFileViewerValidator/UploadFileViewerValidator";
import { FilesError } from "@security-watchdog/ui-components";

export const AdditionalDocuments: React.FC = () => {
  const navigateTo = useNavigate();

  const dispatch: Dispatch = useDispatch();

  const additionalDocuments: IAdditionalDocumentsState = useSelector(
    takeAdditionalDocuments
  );

  const additionalCandidateAttachments = useSelector(
    takeAdditionalCandidateAttachments
  );

  useScrollToTop();

  const [addedDocumentsList, setAddedDocumentsList] = useState<File[]>(
    additionalDocuments.data.files
  );

  const [noteForScreener, setNoteForScreener] = useState<string>(
    additionalDocuments.data.noteForScreener
  );

  const [isFileViewerValid, setIsFileViewerValid] = useState<boolean>(false);

  const isCompleteButtonDisabled =
    !addedDocumentsList.length || !isFileViewerValid;

  useEffect(() => {
    setNoteForScreener(additionalDocuments.data.noteForScreener);
  }, [additionalDocuments.data.noteForScreener]);

  useEffect(() => {
    setAddedDocumentsList(additionalDocuments.data.files);
  }, [additionalDocuments.data.files]);

  const goToBack = useCallback(
    (): void =>
      navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  const setAdditionalFilesToStore = (): void => {
    dispatch(
      caseActions.setAddittionalDocumentsToStore({
        ...additionalDocuments,
        data: {
          files: addedDocumentsList,
          noteForScreener
        }
      })
    );
    goToBack();
  };

  const handleDownload = async (attachment: attachmentFragment) =>
    await downloadFile(attachment);

  const handleAddFiles = useCallback(
    (files: File[], errors: FilesError[] | undefined) => {
      setAddedDocumentsList([...addedDocumentsList, ...files]);
      setIsFileViewerValid(!errors?.length);
    },
    [addedDocumentsList]
  );

  const handleDeleteFile = useCallback(
    (_, itemIndex: number, errors: FilesError[] | undefined) => {
      setAddedDocumentsList(
        addedDocumentsList.filter(
          (file: File, idx: number) => idx !== itemIndex
        )
      );
      setIsFileViewerValid(!errors?.length);
    },
    [addedDocumentsList]
  );

  const getSectionStatus = (): CardStatuses =>
    addedDocumentsList.length ? CardStatuses.COMPLETE : CardStatuses.INCOMPLETE;

  return (
    <>
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <s.Container>
          <ButtonBack handlerClick={setAdditionalFilesToStore}>
            Save &amp; Back
          </ButtonBack>
          <PageHeader
            title="Additional documents"
            description="Add additional documents when you get request from screener"
          />
          <s.Content>
            <s.QuestionHeaderContainer>
              <SectionTitle
                status={getSectionStatus()}
                title={getSectionStatus()}
              />
            </s.QuestionHeaderContainer>
            <s.QuestionContentConteiner>
              <AddNoteToScreener
                noteForScreener={noteForScreener}
                setNoteForScreener={setNoteForScreener}
              />
              <UploadFileViewerValidator
                files={addedDocumentsList}
                showFileWithDate
                multiple
                onChange={handleAddFiles}
                onFileDelete={handleDeleteFile}
              />
            </s.QuestionContentConteiner>
          </s.Content>
          {!!additionalCandidateAttachments.length && (
            <ListOfUploadedFiles
              attachments={additionalCandidateAttachments}
              onDownload={handleDownload}
            />
          )}
          <s.CustomCompleteButton
            isDisabled={isCompleteButtonDisabled}
            onClick={setAdditionalFilesToStore}
            buttonType={BUTTON_TYPE.Primary}
          >
            Complete
          </s.CustomCompleteButton>
        </s.Container>
      </ContentLayout>
    </>
  );
};

export default AdditionalDocuments;
