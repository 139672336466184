import styled from "styled-components";
import {
  getTypographyStyles,
  CloseIcon,
  Button
} from "@security-watchdog/sw-ui-kit";

export const TitleContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }): string => theme.colors.stormGray};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const Title = styled.div`
  margin-left: 15px;
  ${getTypographyStyles({ variant: "16RegularL24", color: "white" })};
`;

export const TitleDescription = styled.div`
  display: flex;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  border: none;
  line-height: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
`;
