import {
  CASE_ID_SEARCH_PARAMS_KEY,
  IS_ADMIN_FLOW_SEARCH_PARAMS_KEY
} from "src/constants/system";

export const createQueryParams = (
  caseId: string,
  isAdminFlow?: boolean
): string =>
  `?${CASE_ID_SEARCH_PARAMS_KEY}=${caseId}${
    isAdminFlow ? `&${IS_ADMIN_FLOW_SEARCH_PARAMS_KEY}=true` : ""
  }`;
