export const dataURLtoFile = (dataUrl: string, fileName: string): Blob => {
  const arr = dataUrl.split(",");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const mime: string = arr[0].match(/:(.*?);/)[1];

  const bstr: string = atob(arr[1]);

  let n: number = bstr.length;

  const u8arr: Uint8Array = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const file: any = new Blob([u8arr], { type: mime });

  file.lastModifiedDate = new Date();

  file.name = fileName;

  return file;
};
