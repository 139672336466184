import React, { useCallback, useMemo } from "react";
import {
  BUTTON_TYPE,
  CaseIcon,
  EditSmallIcon,
  formatDate,
  PinIcon,
  theme,
  TrashBinIcon,
  AlertCircleIcon,
  OptionProps
} from "@security-watchdog/sw-ui-kit";
import { IFieldAnswer, TimelineEntityName } from "src/types";
import { FieldType, HandleableFieldType } from "src/graphQLTypes";
import { renderAddressToString } from "src/utils";
import { IProps } from "./types";
import * as s from "./styles";

const TimelineCardComponent: React.FC<IProps> = ({
  answer,
  onDelete,
  onEdit,
  entityName,
  hasAlert,
  isDisabled
}: IProps) => {
  const handleDelete = useCallback(() => {
    onDelete(answer.answerId, answer.isAnswered);
  }, [answer.answerId, answer.isAnswered, onDelete]);

  const handleEdit = useCallback(() => {
    onEdit(answer);
  }, [answer, onEdit]);

  const activityType = useMemo(() => {
    const activityTypeField: IFieldAnswer | undefined = answer.fields.find(
      (field: IFieldAnswer) =>
        field.fieldType === HandleableFieldType.ACTIVITY_TYPE
    );
    return activityTypeField?.options?.find(
      (option: OptionProps<string>) => option.value === activityTypeField?.value
    )?.label;
  }, [answer.fields]);

  const companyName = useMemo(() => {
    const companyField: IFieldAnswer | undefined = answer.fields.find(
      (field: IFieldAnswer) => field.type === FieldType.COMPANY
    );
    return companyField?.value?.name;
  }, [answer.fields]);

  const currentPosition = useMemo(() => {
    const positionField: IFieldAnswer | undefined = answer.fields.find(
      (field: IFieldAnswer) =>
        field.fieldType === HandleableFieldType.CURRENT_POSITION
    );
    return positionField?.value;
  }, [answer.fields]);

  const currentAddress = useMemo(() => {
    const addressField: IFieldAnswer | undefined = answer.fields.find(
      (field: IFieldAnswer) => field.type === FieldType.ADDRESS
    );
    return addressField?.value
      ? renderAddressToString(addressField.value)
      : undefined;
  }, [answer.fields]);

  const dateRange = useMemo(() => {
    const dateRangeField: IFieldAnswer | undefined = answer.fields.find(
      (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
    );
    if (
      !dateRangeField ||
      (dateRangeField &&
        !dateRangeField.value?.from &&
        !dateRangeField.value?.to)
    ) {
      return undefined;
    }
    return `${formatDate(dateRangeField.value.from)} - ${
      dateRangeField.value.to ? formatDate(dateRangeField.value.to) : "Current"
    }`;
  }, [answer.fields]);

  const cardIcon = useMemo(() => {
    if (hasAlert) {
      return <AlertCircleIcon color={theme.colors.mangoTango} />;
    }
    return entityName === TimelineEntityName.ACTIVITY ? (
      <CaseIcon />
    ) : (
      <PinIcon size={24} />
    );
  }, [entityName, hasAlert]);

  return (
    <s.Tile>
      <s.InfoSectionBlock>
        {cardIcon}
        <s.TextBlock>
          {entityName === TimelineEntityName.ACTIVITY ? (
            <>
              {activityType && <s.ActivityType>{activityType}</s.ActivityType>}
              {companyName && <s.CompanyName>{companyName}</s.CompanyName>}
              {currentPosition && <s.Position>{currentPosition}</s.Position>}
            </>
          ) : (
            currentAddress && <s.CompanyName>{currentAddress}</s.CompanyName>
          )}
          {dateRange && <s.DateRange>{dateRange}</s.DateRange>}
        </s.TextBlock>
      </s.InfoSectionBlock>
      <s.ToolsPanel>
        <s.EditButton
          isDisabled={isDisabled}
          buttonType={BUTTON_TYPE.Link}
          icon={
            <EditSmallIcon
              size={18}
              color={
                isDisabled ? theme.colors.stormGray : theme.colors.royalBlue
              }
            />
          }
          onClick={handleEdit}
        >
          Edit
        </s.EditButton>
        <s.DeleteButton
          isDisabled={isDisabled}
          buttonType={BUTTON_TYPE.Link}
          icon={
            <TrashBinIcon
              size={18}
              color={isDisabled ? theme.colors.stormGray : theme.colors.mojo}
            />
          }
          onClick={handleDelete}
        >
          Delete
        </s.DeleteButton>
      </s.ToolsPanel>
    </s.Tile>
  );
};

export const TimelineCard = React.memo(TimelineCardComponent);
