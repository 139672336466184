/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
import { CandidatePortalAuthFlow } from "src/graphQLTypes";
import { ROUTES } from "src/constants";
import { getB2cPolicies } from "./getB2CPolicies";
import { getAuthorityLink } from "./getAuthorityLink";
import { MsalConfig } from "src/config";

type CandidateAzureVariables = {
  AZURE_CLIENT_ID: string;
  AZURE_SIGN_UP_NAME: string;
  AZURE_SIGN_IN_NAME: string;
  AZURE_RESET_NAME: string;
  AZURE_SIGN_UP_OR_SIGN_IN_NAME: string;
  AZURE_AUTHORITY_DOMAIN: string;
};

export const getCandidateAzureConfig = (
  variables: CandidateAzureVariables,
  authFlow?: CandidatePortalAuthFlow
): MsalConfig => {
  const policies = getB2cPolicies(variables);

  return {
    auth: {
      clientId: variables.AZURE_CLIENT_ID,
      authority: getAuthorityLink(
        authFlow || CandidatePortalAuthFlow.AZURE_AD_SIGN_IN_OR_SIGN_UP
      ), // Choose SUSI as your default authority.
      knownAuthorities: [policies.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: `${window.location.origin}${ROUTES.AUTHENTICATION_METHOD_AZURE_AD}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false
    }
  };
};
