import {
  IAnswer,
  IFieldAnswer,
  IFormFields,
  IQuestion,
  IValidateAnswersMap
} from "../types";
import {
  FieldType,
  getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction
} from "../graphQLTypes";
import { buildFieldKey } from "./buildFieldKey";
import { getStartDateByUnit } from "./getStartDateByUnit";
import { getDateUnitText } from "./getDateUnitText";

interface IPrevValuesMap {
  [key: string]: Date;
}

interface IValidation {
  errorMessageMap: IValidateAnswersMap;
  prevValuesMap: IPrevValuesMap;
}

const getGapErrorMessage = (
  previousEndDate: Date | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldValue: any,
  gapRestriction: getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction
): string => {
  if (!previousEndDate || !fieldValue?.from) {
    return "";
  }
  const {
    unit,
    gapValue
  }: getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction =
    gapRestriction;
  if (getStartDateByUnit(unit, gapValue, fieldValue.from) > previousEndDate) {
    return `The dates provided must be continuous and gaps should not exceed ${gapValue} ${getDateUnitText(
      unit,
      gapValue
    )}|`;
  }
  return "";
};

export const validateAnswers = (
  questions: IQuestion[],
  formValues: IFormFields
): IValidateAnswersMap =>
  questions.reduce((acc: IValidateAnswersMap, question: IQuestion) => {
    const { fields, answers, isMultipleAnswers }: IQuestion = question;
    const isHaveDateRangeField: boolean = fields.some(
      (field: IFieldAnswer) =>
        field.type === FieldType.DATE_RANGE && field.validation.gapRestriction
    );
    if (!isMultipleAnswers || !isHaveDateRangeField || answers.length <= 1) {
      return acc;
    }
    const validation: IValidation = answers.reduce(
      (answerAcc: IValidation, answer: IAnswer) =>
        answer.fields.reduce(
          (acc: IValidation, field: IFieldAnswer) => {
            if (
              field.type !== FieldType.DATE_RANGE ||
              !field.validation.gapRestriction
            ) {
              return acc;
            }
            const fieldKey = buildFieldKey({
              questionId: question.questionId,
              answerId: answer.answerId,
              fieldId: field.fieldId,
              type: field.type,
              fieldType: field.fieldType,
              isMulti: field.isMulti
            });
            const errorMessage: string = getGapErrorMessage(
              acc.prevValuesMap[field.fieldId],
              formValues[fieldKey],
              field.validation.gapRestriction
            );
            return {
              prevValuesMap: {
                ...acc.prevValuesMap,
                [field.fieldId]: formValues[fieldKey] && formValues[fieldKey].to
              },
              errorMessageMap: {
                ...acc.errorMessageMap,
                ...(errorMessage ? { [fieldKey]: errorMessage } : {})
              }
            };
          },
          {
            errorMessageMap: { ...answerAcc.errorMessageMap },
            prevValuesMap: answerAcc.prevValuesMap
          }
        ),
      { errorMessageMap: {}, prevValuesMap: {} }
    );

    return {
      ...acc,
      ...validation.errorMessageMap
    };
  }, {});
