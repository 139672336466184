import {
  IFieldAnswer,
  IFieldsValidationMap,
  IQuestion,
  ITimeLineRestriction
} from "../types";
import { FieldType } from "../graphQLTypes";

export const getFieldsValidationMap = (
  questions: IQuestion[],
  timeLineRestriction?: ITimeLineRestriction
): IFieldsValidationMap =>
  questions.reduce(
    (acc: IFieldsValidationMap, question: IQuestion): IFieldsValidationMap => ({
      ...acc,
      ...question.fields.reduce(
        (
          acc: IFieldsValidationMap,
          field: IFieldAnswer
        ): IFieldsValidationMap => {
          if (field.type === FieldType.DATE_RANGE) {
            return {
              ...acc,
              [`${question.questionId}${field.fieldId}`]: {
                ...field.validation,
                timeLineRestriction
              }
            };
          }

          return {
            ...acc,
            [`${question.questionId}${field.fieldId}`]: field.validation
          };
        },
        {}
      )
    }),
    {}
  );
