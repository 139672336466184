import styled from "styled-components";
import {
  getOutlineStyles,
  getTypographyStyles,
  theme
} from "@security-watchdog/sw-ui-kit";

export const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid ${theme.colors.snuff};
`;

export const UploadedContainer = styled.div`
  border-radius: 4px;
  display: flex;
  width: 100%;
  background: ${theme.colors.whiteLilac};
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
`;

export const UploadedHeaderInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UploadedHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.snuff};
`;

export const UploadedHeaderHeading = styled.div`
  ${getTypographyStyles({
    variant: "11MediumUppercaseL16",
    color: "lightGray"
  })}
`;

export const NoteText = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24" })};
  margin-top: 4px;
`;

export const FilesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

export const FileNameContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const FileName = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24" })};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;

  &:focus {
    ${getOutlineStyles()}
  }
`;
