import React, { FC } from "react";
import * as s from "./styles";

export const SuccessMessage: FC = () => (
  <s.Content>
    <s.Title>Here’s what happens next:</s.Title>
    <s.ContainerInfo>
      <s.CircleNumber>1. </s.CircleNumber>
      <s.Text>We will review your details and attached documents</s.Text>
    </s.ContainerInfo>
    <s.ContainerInfo>
      <s.CircleNumber>2. </s.CircleNumber>
      <s.Text>
        If needed, our team will be in touch should we require any further
        information or paperwork from you
      </s.Text>
    </s.ContainerInfo>
    <s.ContainerInfo>
      <s.CircleNumber>3. </s.CircleNumber>
      <s.Text>
        Your new employer will contact you to discuss next steps once your
        screening has been completed
      </s.Text>
    </s.ContainerInfo>
  </s.Content>
);
