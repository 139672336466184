import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  theme
} from "@security-watchdog/sw-ui-kit";

export const ExtraContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  z-index: ${theme.layers.popup};
  padding: 25px;
  width: 350px;
  background-color: ${theme.colors.white};
`;

export const Header = styled.div`
  ${getTypographyStyles({ variant: "20MediumL30" })};
  text-align: center;
`;

export const SecondaryText = styled.div`
  text-align: center;
`;

export const CustomButton = styled(Button)`
  width: 180px;
  margin-top: 24px;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
