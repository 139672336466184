import React, { FC } from "react";
import {
  attachmentFragment,
  getCase_screeningCase_additionalCandidateAttachments
} from "src/graphQLTypes";
import * as s from "./styles";
import { DocumentSmallIcon, formatDate } from "@security-watchdog/sw-ui-kit";
import { DATE_FORMATS } from "@security-watchdog/sw-ui-kit/dist/constants";
import { DefaultTheme, useTheme } from "styled-components";

interface IProps {
  attachment: getCase_screeningCase_additionalCandidateAttachments;
  onDownload: (attachment: attachmentFragment) => void;
}

export const ExpandedContent: FC<IProps> = ({ attachment, onDownload }) => {
  const theme: DefaultTheme = useTheme();

  const date = `${formatDate(
    attachment.whenUploaded,
    DATE_FORMATS.HOUR_AND_MINUTES
  )} • ${formatDate(attachment.whenUploaded, DATE_FORMATS.LONG_DATE)}`;

  // 12:03 PM • 28 March 2019
  const note = attachment.note || "-";

  const handleDownload = (file: attachmentFragment) => () => onDownload(file);

  return (
    <s.ExpandedContainer key={attachment.whenUploaded}>
      <s.UploadedContainer>
        <s.UploadedHeaderContainer>
          <s.UploadedHeaderInfoWrapper>
            <s.UploadedHeaderHeading>
              Add note to the screening team
            </s.UploadedHeaderHeading>
            <s.UploadedHeaderHeading>{date}</s.UploadedHeaderHeading>
          </s.UploadedHeaderInfoWrapper>
          <s.NoteText>{note}</s.NoteText>
        </s.UploadedHeaderContainer>
        <s.FilesContainer>
          {attachment.candidateAttachments.map((file) => (
            <s.FileNameContainer key={file.id} onClick={handleDownload(file)}>
              <DocumentSmallIcon size={24} color={theme.colors.blueRibbon} />
              <s.FileName role="button" tabIndex={0}>
                {file.fileName}
              </s.FileName>
            </s.FileNameContainer>
          ))}
        </s.FilesContainer>
      </s.UploadedContainer>
    </s.ExpandedContainer>
  );
};
