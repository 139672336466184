import { useSearchParams } from "react-router-dom";
import {
  CASE_DATA_LC_KEY,
  CASE_ID_SEARCH_PARAMS_KEY,
  IS_ADMIN_FLOW_SEARCH_PARAMS_KEY
} from "src/constants/system";
import { useEffect } from "react";

type CaseDataInLocalStorage = {
  [IS_ADMIN_FLOW_SEARCH_PARAMS_KEY]: boolean;
  [CASE_ID_SEARCH_PARAMS_KEY]: string;
};

const getParsedDataFromLocalStorage = (): CaseDataInLocalStorage => {
  try {
    const data = localStorage.getItem(CASE_DATA_LC_KEY);

    return JSON.parse(data || "{}");
  } catch {
    return {
      [IS_ADMIN_FLOW_SEARCH_PARAMS_KEY]: false,
      [CASE_ID_SEARCH_PARAMS_KEY]: ""
    };
  }
};

const setDataToLocalStorage = (caseId: string, isAdminFlow: boolean) => {
  const data: CaseDataInLocalStorage = {
    [IS_ADMIN_FLOW_SEARCH_PARAMS_KEY]: isAdminFlow,
    [CASE_ID_SEARCH_PARAMS_KEY]: caseId
  };

  localStorage.setItem(CASE_DATA_LC_KEY, JSON.stringify(data));
};

export const useUrlSearchParams = () => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get(CASE_ID_SEARCH_PARAMS_KEY);

  const isAdminFlow =
    searchParams.get(IS_ADMIN_FLOW_SEARCH_PARAMS_KEY) === "true";

  const dataFromLC = getParsedDataFromLocalStorage();

  useEffect(() => {
    setDataToLocalStorage(
      caseId ?? dataFromLC.caseId,
      isAdminFlow ?? dataFromLC.isAdminFlow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, isAdminFlow]);

  return {
    caseId: caseId || dataFromLC.caseId,
    isAdminFlow: isAdminFlow || dataFromLC.isAdminFlow
  };
};
