import { sessionIsTerminatedError } from "src/cache";
import { GraphQLFormattedError } from "graphql/error";
import { getToken } from "src/msal";
import { ErrorResponse } from "@apollo/client/link/error";
import { NetworkError } from "@apollo/client/errors";
import { store } from "src/store";
import * as actionsMsgNtc from "modules/messages/actions";
import { v4 as uuidv4 } from "uuid";
import { TYPE_NOTIFICATION } from "@security-watchdog/sw-ui-kit";

export const processErrorResponses = ({
  message,
  path
}: GraphQLFormattedError) => {
  let notificationMessage = "";

  if (message.includes("Antivirus check failed.")) {
    notificationMessage = "Antivirus check failed.";
  }

  if (message.includes("503: Service Unavailable")) {
    notificationMessage = `
      We\u2019re sorry – something went wrong with the connection to the database.

      Please try refreshing your browser and if the problem continues, please notify your screening team on the details given in your welcome email.
    `;
  }

  if (message?.includes("Failed to create TrustID Guest Link")) {
    return;
  }

  if (path?.includes("konfirVerificationLinkCreate")) {
    return;
  }

  store.dispatch(
    actionsMsgNtc.addMessageNtc({
      id: uuidv4(),
      type: TYPE_NOTIFICATION.Error,
      message: notificationMessage
    })
  );
};

export const onSessionTerminatedError = (error: GraphQLFormattedError) => {
  if (error.extensions?.code === "sessionIsTerminated") {
    sessionIsTerminatedError(error.extensions.code as string);
    return true;
  }
  return false;
};

export const onUnauthenticatedError = ({
  operation,
  forward,
  extensions
}: Pick<ErrorResponse, "forward" | "operation"> & GraphQLFormattedError) => {
  if (extensions?.code === "UNAUTHENTICATED") {
    let token = "";

    (async () => {
      token = await getToken();
    })();

    const oldHeaders: Record<string, unknown> = operation.getContext()
      .headers as Record<string, unknown>;

    const newHeaders = {
      ...oldHeaders,
      authorization: token,
      "x-refresh-user-ms-token": true
    };

    operation.setContext({
      headers: newHeaders
    });

    return forward(operation);
  }
  return false;
};

export const logGraphQLError = ({
  message,
  locations,
  path
}: GraphQLFormattedError) => {
  // eslint-disable-next-line no-console
  console.error(
    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  );
};

export const logNetworkError = (error?: NetworkError) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${error}`);
  }
};
