import styled, { css } from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  padding: 24px;
  margin-top: 24px;
  border-radius: 4px;
  background-color: ${({ theme }): string => theme.colors.white};
`;

export const Section = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.h3`
  ${getTypographyStyles({ variant: "20MediumL30" })};
  margin: 0;
`;

export const Text = styled.p`
  ${getTypographyStyles({ variant: "16RegularL24" })};
  margin: 0;
`;

export const PageInfoBanner = styled.div`
  display: flex;
  align-content: center;
  gap: 16px;
  padding: 16px 8px 16px 16px;
  background: ${({ theme }): string => theme.colors.surfaceWarning};
  border-radius: 4px;
  margin-bottom: 28px;
`;

export const InfoBannerIcon = styled.div`
  flex: 0 0 auto;
`;

export const ActionsWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      button {
        justify-content: center;
        width: 100%;
      }
    `}
`;

export const ConfirmModalText = styled.div`
  text-align: left;
`;

export const ConfirmModalSubHeader = styled.div`
  text-align: left;
  margin-bottom: 16px;
`;
