import actionCreatorFactory from "typescript-fsa";
import {
  IAutoSaveVariables,
  IClearSectionDraftValuesVariables,
  IDraftStatuses,
  IAdditionalDocumentsState,
  ISaveUploadHistoryVariables
} from "modules/case/types";
import {
  caseSubmit_caseSubmit_case,
  caseSubmitVariables,
  caseUpdate_caseUpdate_case,
  Countries_countries,
  getCase_screeningCase,
  getCaseVariables,
  saveDraft_saveDraft_case,
  additionalDocumentsUpload_uploadAdditionalDocuments,
  additionalDocumentsUploadVariables,
  saveUploadHistory_saveDraft_case
} from "src/graphQLTypes";
import { ICaseUpdateInput, IErrorType } from "src/types";

const actionCreator = actionCreatorFactory("CANDIDATE");

export const getCase = actionCreator.async<
  getCaseVariables,
  getCase_screeningCase,
  IErrorType
>("GET_CASE");

export const caseSubmitRequest = actionCreator.async<
  caseSubmitVariables,
  caseSubmit_caseSubmit_case,
  IErrorType
>("CASE_SUBMIT_REQUEST");

export const caseUpdate = actionCreator.async<
  ICaseUpdateInput,
  caseUpdate_caseUpdate_case,
  IErrorType
>("CASE_UPDATE");

export const getCountries = actionCreator.async<
  void,
  Countries_countries[],
  IErrorType
>("GET_COUNTRIES");

export const clearCountries = actionCreator("CLEAN_COUNTRIES");

export const doAutoSave = actionCreator.async<
  IAutoSaveVariables,
  saveDraft_saveDraft_case,
  IErrorType
>("DO_AUTO_SAVE");

export const clearSectionDraftValues = actionCreator.async<
  IClearSectionDraftValuesVariables,
  saveDraft_saveDraft_case,
  IErrorType
>("CLEAR_SECTION_DRAFT_VALUES");

export const updateSaveDraftStatus = actionCreator<IDraftStatuses>(
  "UPDATE_SAVE_DRAFT_STATUS"
);

export const changeLogoVisibility = actionCreator<boolean>(
  "CHANGE_LOGO_VISIBILITY"
);

export const setAddittionalDocumentsToStore =
  actionCreator<IAdditionalDocumentsState>("SET_ADDITIONAL_DOCUMENTS_TO_STORE");

export const setAgreedToPrivacyPolicy = actionCreator<boolean>(
  "SET_AGREED_TO_PRIVACY_POLICY"
);

export const setIsAdditionalDocumetsSubmitted = actionCreator<boolean>(
  "SET_IS_ADDITIONAL_DOCUMENTS_SUBMITTED"
);

export const additionalDocumentsUpload = actionCreator.async<
  additionalDocumentsUploadVariables,
  additionalDocumentsUpload_uploadAdditionalDocuments,
  IErrorType
>("ADDITIONAL_DOCUMENTS_UPLOAD");

export const pullDraftData = actionCreator.async<
  getCaseVariables,
  getCase_screeningCase,
  IErrorType
>("PULL_DRAFT_DATA");

export const saveUploadFilesHistory = actionCreator.async<
  ISaveUploadHistoryVariables,
  saveUploadHistory_saveDraft_case,
  IErrorType
>("SAVE_UPLOAD_FILES_HISTORY");
