import React, { FC, memo, PropsWithChildren } from "react";
import { MsalProvider } from "@azure/msal-react";
import {
  getMsalInstance,
  getScreenerAzureB2CConfig,
  getScreenerAzureConfig
} from "src/config";
import { azureAppConfigVar } from "src/cache";
import { AzureProviderManager } from "containers/AzureProviderManager";

const ScreenerAzureProviderComponent: FC<PropsWithChildren> = ({
  children
}) => {
  const {
    AZURE_SCREENER_TENANT_ID,
    AZURE_SCREENER_CLIENT_ID,
    AZURE_SCREENER_B2C_CLIENT_ID,
    AZURE_SCREENER_B2C_AUTHORITY_DOMAIN,
    AZURE_SCREENER_B2C_SIGN_IN_NAME,
    featureToggle
  } = azureAppConfigVar();

  const pca = getMsalInstance(
    featureToggle.ENABLE_SCREENER_AZURE_B2C
      ? getScreenerAzureB2CConfig({
          AUTHORITY_DOMAIN: AZURE_SCREENER_B2C_AUTHORITY_DOMAIN,
          CLIENT_ID: AZURE_SCREENER_B2C_CLIENT_ID,
          SIGN_IN_NAME: AZURE_SCREENER_B2C_SIGN_IN_NAME
        })
      : getScreenerAzureConfig({
          AZURE_SCREENER_TENANT_ID,
          AZURE_SCREENER_CLIENT_ID
        })
  );

  return (
    <MsalProvider instance={pca}>
      <AzureProviderManager>{children}</AzureProviderManager>
    </MsalProvider>
  );
};

export const ScreenerAzureProvider = memo<PropsWithChildren>(
  ScreenerAzureProviderComponent
);
