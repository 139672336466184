import styled, { css } from "styled-components";
import { Icon as IconComponent } from "@security-watchdog/ui-components";
import { IconProps } from "@security-watchdog/ui-components/dist/components/Icons/Icon";
import { CandidateCaseProductStatus } from "src/graphQLTypes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--unit);
`;

export const Icon = styled(IconComponent)<
  IconProps & { status: CandidateCaseProductStatus }
>`
  ${({ status }) => {
    switch (status) {
      case CandidateCaseProductStatus.IN_PROGRESS: {
        return css`
          color: var(--color-icon-active) !important;
        `;
      }

      case CandidateCaseProductStatus.READY_TO_START: {
        return css`
          color: var(--color-icon-active) !important;
        `;
      }

      case CandidateCaseProductStatus.AWAITING_CANDIDATE: {
        return css`
          color: var(--color-icon-warning) !important;
        `;
      }

      case CandidateCaseProductStatus.AWAITING_REFERENCE: {
        return css`
          color: var(--color-icon-warning) !important;
        `;
      }

      case CandidateCaseProductStatus.AWAITING_3RD_PARTY: {
        return css`
          color: var(--color-icon-warning) !important;
        `;
      }

      case CandidateCaseProductStatus.COMPLETED: {
        return css`
          color: var(--color-icon-success) !important;
        `;
      }
      default:
        return undefined;
    }
  }}
`;
