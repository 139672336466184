import { useMutation } from "@apollo/client";
import KONFIR_VERIFICATION_LINK_CREATE_MUTATION from "api/graphql/mutations/CandidateSubmitKonfir/konfiirVerificationLinkCreate.gql";
import {
  caseUpdateVariables,
  getCase_screeningCase_sections_answers,
  KonfirVerificationLinkCreate,
  KonfirVerificationLinkCreateVariables
} from "src/graphQLTypes";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import * as actionsMsgNtc from "modules/messages/actions";
import { v4 as uuidv4 } from "uuid";
import { TYPE_NOTIFICATION } from "@security-watchdog/sw-ui-kit";
import { KONFIR_VERIFICATION_LINK_CREATE_ERROR } from "containers/KonfirPage/constants";
import { getKonfirFirstDifficultiesBooleanAnswerValue } from "containers/KonfirPage/utils";
import { buildFieldKey, extractErrorMessage } from "src/utils";
import { buildAnswersInput } from "src/utils/buildInputForCaseUpdate";
import * as caseActions from "modules/case/actions";

type UseKonfirVerificationLinkCreate = {
  createKonfirGuestLink: () => Promise<string>;
  submitKonfirSection: (candidateAnswer: boolean) => void;
  isLoading: boolean;
};

type UseKonfirVerificationLinkCreateArgs = {
  caseId: string;
  sectionAnswers: getCase_screeningCase_sections_answers[];
};

export const useKonfirVerificationLinkCreate = ({
  caseId,
  sectionAnswers
}: UseKonfirVerificationLinkCreateArgs): UseKonfirVerificationLinkCreate => {
  const [createLinkRequest, { loading: isLoading }] = useMutation<
    KonfirVerificationLinkCreate,
    KonfirVerificationLinkCreateVariables
  >(KONFIR_VERIFICATION_LINK_CREATE_MUTATION, {
    variables: {
      input: {
        caseId
      }
    }
  });

  const dispatch: Dispatch = useDispatch();

  const difficultiesQuestionAnswerValue =
    getKonfirFirstDifficultiesBooleanAnswerValue(sectionAnswers);

  const showErrorMessage = useCallback(
    (message?: string) => {
      dispatch(
        actionsMsgNtc.addMessageNtc({
          id: uuidv4(),
          type: TYPE_NOTIFICATION.Error,
          message: message || KONFIR_VERIFICATION_LINK_CREATE_ERROR
        })
      );
    },
    [dispatch]
  );

  const getKonfirCandidateAnswerForCaseUpdate = useCallback(
    (candidateAnswer: boolean): caseUpdateVariables => {
      const questionId = sectionAnswers?.[0].question.id;
      const answerId = sectionAnswers?.[0].answers?.[0]?.id;
      const field = sectionAnswers?.[0].question.fields?.[0];
      const formFieldKey: string = buildFieldKey({
        questionId,
        answerId,
        fieldId: field?.id,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: false
      });
      const formData = { [formFieldKey]: candidateAnswer };
      const updatedField = {
        fieldId: field?.id,
        title: field?.title.current,
        type: field?.type,
        fieldType: field?.fieldType,
        description: field?.description.current,
        value: candidateAnswer,
        isConditional: field?.conditional,
        isVisible: true,
        hasDependentFields: false,
        conditions: [],
        validation: {
          isMulti: false,
          isRequired: false,
          dependentField: undefined
        },
        isMulti: false,
        isRoleLocationScotland: false,
        answerIndex: 0,
        isHighlightedWhenOcrUploaded: false
      };
      const updatedAnswer = {
        answerId,
        isAnswered: true,
        fields: [updatedField],
        index: 0
      };

      return {
        files: [],
        input: {
          caseId,
          consentGiven: false,
          answers: [
            {
              questionId: questionId,
              answers: buildAnswersInput({
                answers: [updatedAnswer],
                data: formData,
                questionId: questionId
              })
            }
          ]
        }
      };
    },
    [sectionAnswers, caseId]
  );

  const submitKonfirSection = useCallback(
    (candidateAnswer: boolean): void => {
      const isAlreadyAnswered =
        difficultiesQuestionAnswerValue === candidateAnswer;

      if (!isAlreadyAnswered) {
        dispatch(
          caseActions.caseUpdate.started({
            caseInput: getKonfirCandidateAnswerForCaseUpdate(candidateAnswer)
          })
        );
      }
    },
    [
      difficultiesQuestionAnswerValue,
      dispatch,
      getKonfirCandidateAnswerForCaseUpdate
    ]
  );

  const createKonfirGuestLink = useCallback(async (): Promise<string> => {
    try {
      const response = await createLinkRequest();
      const link = response.data?.konfirVerificationLinkCreate.verificationLink;

      if (link) {
        return Promise.resolve(link);
      }

      showErrorMessage();
      return Promise.resolve("");
    } catch (error) {
      if (error instanceof Error) {
        showErrorMessage(
          error.message.includes("Internal Server Error")
            ? undefined
            : extractErrorMessage(error.message)
        );
      }

      return Promise.resolve("");
    }
  }, [showErrorMessage, createLinkRequest]);

  return {
    createKonfirGuestLink,
    submitKonfirSection,
    isLoading
  };
};
