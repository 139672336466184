import { MsalConfig } from "src/config";
import {
  getScreenerAzureB2CPolicies,
  ScreenerB2CPoliciesProps
} from "config/azure/screenerAzureB2CConfig/getScreenerAzureB2CPolicies";
import { ROUTES } from "src/constants";

type ScreenerAzureB2CConfigVariables = ScreenerB2CPoliciesProps & {
  CLIENT_ID: string;
  AUTHORITY_DOMAIN: string;
};

export const getScreenerAzureB2CConfig = ({
  AUTHORITY_DOMAIN,
  CLIENT_ID,
  SIGN_IN_NAME
}: ScreenerAzureB2CConfigVariables): MsalConfig => {
  const policies = getScreenerAzureB2CPolicies({
    SIGN_IN_NAME,
    AUTHORITY_DOMAIN
  });

  return {
    auth: {
      clientId: CLIENT_ID,
      authority: policies.authorities.signIn.authority,
      knownAuthorities: [policies.authorityDomain],
      postLogoutRedirectUri: `https://login.microsoftonline.com/guid/oauth2/v2.0/logout?post_logout_redirect_url=${window.location.origin}`,
      redirectUri: `${window.location.origin}${ROUTES.AUTHENTICATE_SCREENER}`,
      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true,
      supportsNestedAppAuth: true
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false
    }
  };
};
