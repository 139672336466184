import { AddressInput } from "../graphQLTypes";

export const renderAddressToString = ({
  town,
  postcode,
  county,
  addressLine1,
  addressLine2,
  addressLine3
}: AddressInput): string =>
  [addressLine1, addressLine2, addressLine3, town, postcode, county]
    .filter(Boolean)
    .join(", ");
