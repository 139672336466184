import { useQuery } from "@apollo/client";
import { GET_CASE_INFORMATION } from "containers/SuccessSubmit/api";
import {
  GetCaseInformation,
  GetCaseInformationVariables
} from "src/graphQLTypes";

export const useGetCaseInformation = (id?: string | null) => {
  const {
    data,
    loading: isLoading,
    refetch
  } = useQuery<GetCaseInformation, GetCaseInformationVariables>(
    GET_CASE_INFORMATION,
    {
      skip: !id,
      variables: {
        id: id as string
      }
    }
  );

  return {
    isLoading,
    data: data?.screeningCase,
    refetch
  };
};
