import styled from "styled-components";

export const FooterActionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: calc(var(--unit) * 2);

  & button:first-child {
    width: 112px;
  }

  & button:last-child {
    width: 240px;
    margin-left: 16px;
  }
`;
