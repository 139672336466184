import React, { FC } from "react";
import { EditSmallIcon, SMALL_ICON_SIZE } from "@security-watchdog/sw-ui-kit";
import { DefaultTheme, useTheme } from "styled-components";
import * as s from "./styles";

export interface IOCRHighlightFieldProps {
  isHighlightedField: boolean;
}

export const OCRHighlightField: FC<IOCRHighlightFieldProps> = ({
  isHighlightedField
}: IOCRHighlightFieldProps) => {
  const theme: DefaultTheme = useTheme();

  return isHighlightedField ? (
    <s.Container>
      <EditSmallIcon color={theme.colors.lightGray} size={SMALL_ICON_SIZE} />
      <s.Text>Automatically populated</s.Text>
    </s.Container>
  ) : null;
};
