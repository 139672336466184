import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const Container = styled.div`
  margin-bottom: 23px;
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  margin-left: 10px;
  ${getTypographyStyles({
    color: "lightGray",
    variant: "14RegularL20"
  })};
`;
