import React, { FC, memo, PropsWithChildren } from "react";
import * as s from "./styles";

const Component: FC<PropsWithChildren> = ({ children }) => (
  <s.Container>
    <s.Content>{children}</s.Content>
  </s.Container>
);

Component.displayName = "PageWrapper";

export const PageWrapper = memo(Component);
