import React, { FC } from "react";
import * as s from "../styles";

export interface IOnlineProps {
  isVisible: boolean;
}

export const Online: FC<IOnlineProps> = ({ isVisible }: IOnlineProps) =>
  isVisible ? (
    <s.OnlineContainer>
      <s.OnlineIcon />
      <span>Screener is in your application</span>
    </s.OnlineContainer>
  ) : null;
