export enum CardStatuses {
  INCOMPLETE = "Incomplete",
  COMPLETE = "Completed",
  HAS_OPTIONAL = "Has Optional"
}

export interface IProps {
  title: string;
  status: CardStatuses;
}

export interface IStatusCircleProps {
  status: CardStatuses;
}
