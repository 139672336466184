import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren
} from "react";

import { Portal } from "components/Portal";
import { FocusTrap } from "@security-watchdog/sw-ui-kit";
import { ModalHeader, ModalBody, ModalFooter } from "./components";
import * as s from "./styles";

type DesignSystemModalProps = PropsWithChildren<{
  showModal: boolean;
  fullHeightMode?: boolean;
  dataTestId?: string;
}>;

type DesignSystemModalType =
  ForwardRefExoticComponent<DesignSystemModalProps> & {
    Header: typeof ModalHeader;
    Body: typeof ModalBody;
    Footer: typeof ModalFooter;
  };

export const DesignSystemModal = forwardRef<
  HTMLDivElement,
  DesignSystemModalProps
>(
  (
    {
      showModal,
      fullHeightMode = true,
      dataTestId = "design-system-modal",
      children
    },
    ref
  ) => {
    return showModal ? (
      <Portal>
        <s.Container ref={ref} data-testid={dataTestId}>
          <FocusTrap isActive>
            <s.ModalContainer $fullHeightMode={fullHeightMode}>
              {children}
            </s.ModalContainer>
          </FocusTrap>
        </s.Container>
      </Portal>
    ) : null;
  }
) as DesignSystemModalType;

DesignSystemModal.displayName = "DesignSystemModal";
DesignSystemModal.Header = ModalHeader;
DesignSystemModal.Body = ModalBody;
DesignSystemModal.Footer = ModalFooter;
