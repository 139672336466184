import { useQuery } from "@apollo/client";
import { GET_AUTH_FLOW } from "containers/AuthProvider/api";
import { GetAuthFlow, GetAuthFlowVariables } from "src/graphQLTypes";

export const useGetAuthFlow = (caseId: string, skip?: boolean) => {
  const { loading: isLoading, data } = useQuery<
    GetAuthFlow,
    GetAuthFlowVariables
  >(GET_AUTH_FLOW, {
    skip,
    variables: {
      caseId
    }
  });

  return {
    isLoading,
    authFlow: data?.authFlow.authFlow
  };
};
