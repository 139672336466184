import styled, { css } from "styled-components";
import {
  getTypographyStyles,
  ILinkProps,
  Link
} from "@security-watchdog/sw-ui-kit";
import { IStylesProps } from "./types";

const setMarginForText = ({ marginBottom, marginTop }: IStylesProps) => css`
  margin-top: ${marginTop || 0}px;
  margin-bottom: ${marginBottom || 0}px;
`;

export const ContentBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 25px;
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 26px 24px;
`;

export const PrimaryText = styled.h3`
  margin: 0 0 7px 0;
  ${getTypographyStyles({ variant: "20MediumL30" })}
`;

export const SecondaryText = styled.div`
  ${getTypographyStyles({ variant: "16MediumL24" })}
`;

export const Text = styled.div<IStylesProps>`
  ${setMarginForText};
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;

export const CustomLink = styled(Link)<ILinkProps>`
  ${getTypographyStyles({ color: "royalBlue", variant: "16RegularL24" })};
`;
