import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";

export interface IReducer {
  isAdminFlow: boolean;
}

export const initialState: IReducer = {
  isAdminFlow: false
};

export const reducers = reducerWithInitialState(initialState).case(
  actions.updateAdminFlowState,
  (state: IReducer, payload): IReducer => ({
    ...state,
    isAdminFlow: payload
  })
);
