import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const ContainerAddress = styled.div`
  margin: 25px 0 16px;
`;

export const CustomButton = styled(Button)<IButtonProps>`
  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    justify-content: center;
  }
`;

export const ButtonAdd = styled(Button)<IButtonProps>`
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }): number => theme.fontWeights.medium};
`;

export const Text = styled.span`
  margin-left: 16px;
  font-size: 11px;
  line-height: 16px;
  font-weight: ${({ theme }): number => theme.fontWeights.medium};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const PrimaryText = styled.div`
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;

export const SecondaryText = styled(PrimaryText)`
  color: ${({ theme }): string => theme.colors.stormGray};
`;

export const ErrorMessage = styled(PrimaryText)`
  color: ${({ theme }): string => theme.colors.mojo};
  margin-bottom: 15px;
`;
