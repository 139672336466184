import React, { FC } from "react";
import { UsersIcon } from "@security-watchdog/sw-ui-kit";
import { DefaultTheme, useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  takeIsAcceptingRequestInProgress,
  takeIsCancelRequestInProgress,
  takeScreenerAccessId,
  takeScreenerAccessStatus
} from "modules/screenerAccess/selectors";
import { TroubleshootingRequestStatus } from "src/graphQLTypes";
import { Dispatch } from "redux";
import * as screenerAccessActions from "modules/screenerAccess/actions";
import { takeCaseId } from "modules/case/selectors";
import { TROUBLESHOOTING_REQUEST_STATUS_TEXT } from "src/constants";
import { Actions } from "containers/ScreenerAccess/components/actions";
import { takeIsAdminFlow } from "modules/main/selectors";
import { Online } from "containers/ScreenerAccess/components/online";
import * as s from "./styles";

export const ScreenerAccess: FC = () => {
  const theme: DefaultTheme = useTheme();

  const dispatch: Dispatch = useDispatch();

  const status: TroubleshootingRequestStatus | null = useSelector(
    takeScreenerAccessStatus
  );

  const isAcceptingRequestInProgress: boolean = useSelector(
    takeIsAcceptingRequestInProgress
  );

  const isCancelRequestInProgress: boolean = useSelector(
    takeIsCancelRequestInProgress
  );

  const isAdminFlow: boolean = useSelector(takeIsAdminFlow);

  const caseId: string = useSelector(takeCaseId);

  const requestId: string | null = useSelector(takeScreenerAccessId);

  const handleAcceptRequest = (): void => {
    if (caseId && requestId) {
      dispatch(
        screenerAccessActions.doCaseTroubleshootingRequestUpdate.started({
          input: {
            caseId,
            id: requestId,
            status: TroubleshootingRequestStatus.CANDIDATE_ACCEPTED
          }
        })
      );
    }
  };

  const handleCancelRequest = (): void => {
    if (caseId && requestId) {
      dispatch(
        screenerAccessActions.doCaseTroubleshootingRequestUpdate.started({
          input: {
            caseId,
            id: requestId,
            status: TroubleshootingRequestStatus.CANDIDATE_REJECTED
          }
        })
      );
    }
  };

  const shouldBeRendered = Boolean(
    status &&
      [
        TroubleshootingRequestStatus.SCREENER_REQUESTED,
        TroubleshootingRequestStatus.CANDIDATE_ACCEPTED
      ].includes(status) &&
      !isAdminFlow
  );

  const isOnlineStatusVisible =
    status === TroubleshootingRequestStatus.CANDIDATE_ACCEPTED && !isAdminFlow;

  return shouldBeRendered ? (
    <s.Container>
      <s.InfoTextContainer>
        <UsersIcon color={theme.colors.white} />
        <span>{status ? TROUBLESHOOTING_REQUEST_STATUS_TEXT[status] : ""}</span>
      </s.InfoTextContainer>
      <s.ActionsContainer>
        <Online isVisible={isOnlineStatusVisible} />
        <Actions
          isCancelRequestInProgress={isCancelRequestInProgress}
          isAcceptingRequestInProgress={isAcceptingRequestInProgress}
          handleCancelRequest={handleCancelRequest}
          handleAcceptRequest={handleAcceptRequest}
          status={status}
        />
      </s.ActionsContainer>
    </s.Container>
  ) : null;
};
