import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerStatusProof = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
`;

export const MarkerStatus = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.colors.mangoTango};
  width: 8px;
  height: 8px;
`;

export const TextStatus = styled.span`
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 8px;
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
`;
