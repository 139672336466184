import { CompOperator } from "../graphQLTypes";

export const validatesNumbersByCompOperator = (
  comp: CompOperator,
  value: number,
  restrictionValue: number
): boolean => {
  switch (comp) {
    case CompOperator.EQ:
      return value === restrictionValue;

    case CompOperator.LT:
      return value < restrictionValue;

    case CompOperator.LTE:
      return value <= restrictionValue;

    case CompOperator.GT:
      return value > restrictionValue;

    case CompOperator.GTE:
      return value >= restrictionValue;
    default:
      return false;
  }
};
