import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import { Checkbox } from "@security-watchdog/sw-ui-kit";
import { Button } from "@security-watchdog/ui-components";

import { DesignSystemModal } from "components/DesignSystemModal";
import * as s from "./styles";
import { useDeviceType } from "src/hooks/useDeviceType";

type TermsAndConditionsModalProps = PropsWithChildren<{
  headerSubTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}>;

export const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
  isOpen,
  headerSubTitle,
  onClose,
  onConfirm,
  children
}) => {
  const { isMobile } = useDeviceType();
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false);

  const onCloseHandler = useCallback(() => {
    setAreTermsAccepted(false);
    onClose();
  }, [onClose]);

  const onConfirmHandler = useCallback(() => {
    setAreTermsAccepted(false);
    onConfirm();
  }, [onConfirm]);

  return (
    <DesignSystemModal
      showModal={isOpen}
      dataTestId="terms-and-conditions-modal"
    >
      <DesignSystemModal.Header
        title="Terms & Conditions"
        subTitle={headerSubTitle}
        onClose={onCloseHandler}
      />
      <DesignSystemModal.Body>{children}</DesignSystemModal.Body>
      <DesignSystemModal.Footer>
        <s.FooterActions>
          <Checkbox
            value={areTermsAccepted}
            onChange={() => setAreTermsAccepted((prevState) => !prevState)}
            label="I have read and agree to the Terms and Conditions"
          />
          <s.FooterActionButtons $isMobile={isMobile}>
            <Button
              dataTestId="modal-cancel-button"
              variant="secondary"
              onClick={onCloseHandler}
            >
              Cancel
            </Button>
            <Button disabled={!areTermsAccepted} onClick={onConfirmHandler}>
              {`${isMobile ? "Confirm" : "Confirm and Proceed"}`}
            </Button>
          </s.FooterActionButtons>
        </s.FooterActions>
      </DesignSystemModal.Footer>
    </DesignSystemModal>
  );
};
