import { areEqualValues } from "@security-watchdog/sw-ui-kit";
import { CaseTroubleshootingHistoryInput, FieldType } from "../graphQLTypes";
import { IFieldValue, IPartialTroubleShootHistoryRecord } from "../types";
import { setValueByFieldType } from "./buildInputForCaseUpdate";
import { isUndefined } from "./typeGuards";

export interface IBuildDraftTroubleShootingHistoryData {
  caseId: string;
  draftTroubleshootingHistory: IPartialTroubleShootHistoryRecord[];
}

const getHistoryValue = (
  type: FieldType,
  value: IFieldValue,
  isMulti: boolean
): IFieldValue => {
  switch (type) {
    case FieldType.ADDRESS: {
      return value
        ? {
            stringValue: JSON.stringify(value)
          }
        : {};
    }
    case FieldType.UPLOAD: {
      return value ? { stringValue: value } : {};
    }
    case FieldType.MULTIPLE_UPLOAD: {
      return value ? { stringValues: value } : {};
    }

    case FieldType.QUALIFICATIONS_AND_GRADES: {
      return value ? setValueByFieldType(type, value, isMulti) : {};
    }

    default: {
      return setValueByFieldType(type, value, isMulti);
    }
  }
};

export const buildDraftTroubleShootingHistoryData = ({
  caseId,
  draftTroubleshootingHistory
}: IBuildDraftTroubleShootingHistoryData):
  | Array<CaseTroubleshootingHistoryInput | null>
  | null
  | undefined =>
  draftTroubleshootingHistory
    .filter(
      (record: IPartialTroubleShootHistoryRecord) =>
        record.to !== undefined || record.isDeleted
    )
    .reduce(
      (
        acc: CaseTroubleshootingHistoryInput[],
        record: IPartialTroubleShootHistoryRecord
      ): CaseTroubleshootingHistoryInput[] => {
        if (!areEqualValues(record.from, record.to)) {
          const from = getHistoryValue(
            record.fieldType,
            record.from,
            record.isMulti
          );

          const to = getHistoryValue(
            record.fieldType,
            record.to,
            record.isMulti
          );

          return acc.concat({
            fieldId: record.fieldId,
            from: !isUndefined(record.from)
              ? { ...from, fieldId: record.fieldId }
              : undefined,
            type: record.type,
            index: record.index,
            to: !isUndefined(record.to)
              ? { ...to, fieldId: record.fieldId }
              : undefined,
            caseId
          });
        }

        return acc;
      },
      []
    );
