import React, { FC, memo, PropsWithChildren } from "react";
import { IAzureAdProviderProps } from "containers/AzureAdProvier/types";
import { MsalProvider } from "@azure/msal-react";
import { getCandidateAzureConfig, getMsalInstance } from "src/config";
import { azureAppConfigVar } from "src/cache";
import { AzureProviderManager } from "containers/AzureProviderManager";

const AzureProviderComponent: FC<PropsWithChildren<IAzureAdProviderProps>> = ({
  children,
  authFlow
}) => {
  const {
    AZURE_CLIENT_ID,
    AZURE_SIGN_UP_NAME,
    AZURE_SIGN_IN_NAME,
    AZURE_RESET_NAME,
    AZURE_SIGN_UP_OR_SIGN_IN_NAME,
    AZURE_AUTHORITY_DOMAIN
  } = azureAppConfigVar();

  const pca = getMsalInstance(
    getCandidateAzureConfig(
      {
        AZURE_CLIENT_ID,
        AZURE_SIGN_UP_NAME,
        AZURE_SIGN_IN_NAME,
        AZURE_RESET_NAME,
        AZURE_SIGN_UP_OR_SIGN_IN_NAME,
        AZURE_AUTHORITY_DOMAIN
      },
      authFlow
    )
  );

  return (
    <MsalProvider instance={pca}>
      <AzureProviderManager>{children}</AzureProviderManager>
    </MsalProvider>
  );
};

export const AzureProvider = memo<PropsWithChildren<IAzureAdProviderProps>>(
  AzureProviderComponent
);
