import { MAX_SIGNATURE_FILE_SIZE_IN_BYTES } from "../constants";
import { appConfig } from "src/config";

const FILE_MAX_SIZE_IN_BYTES =
  appConfig.inputValidation?.FILE_MAX_SIZE_IN_BYTES;

const getUploadSizeMessage = (maxSize: number): string =>
  `${maxSize / 1024 / 1024}MB`;

export const getUploadSizeString = (): string =>
  getUploadSizeMessage(FILE_MAX_SIZE_IN_BYTES);

export const getUploadSignatureFileSizeString = (): string =>
  getUploadSizeMessage(MAX_SIGNATURE_FILE_SIZE_IN_BYTES);
