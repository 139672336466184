import {
  takeCaseSectionsByAffectingField,
  takeIsOcrTriggered,
  takePersonalInformationQuestions,
  takeProofOfIdentityField,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import React, { NamedExoticComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { ROUTES } from "src/constants";
import { IProofOfIdentityField } from "src/types";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageData } from "modules/case/types";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { takeIsAdminFlow } from "modules/main/selectors";
import * as s from "./styles";
import { SectionDetails } from "../common/SectionDetails";

const PersonalDetailsComponent: React.FC = () => {
  const data: IPageData = useSelector(takePersonalInformationQuestions);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const sectionsByAffectingField = useSelector(
    takeCaseSectionsByAffectingField
  );

  const proofOfIdentity: IProofOfIdentityField | undefined = useSelector(
    takeProofOfIdentityField
  );

  const isProofOfIdentityEmptyField: boolean = useMemo(
    () => Boolean(proofOfIdentity && !proofOfIdentity.isAnswered),
    [proofOfIdentity]
  );

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const isAdminFlow: boolean = useSelector(takeIsAdminFlow);

  const isOcrTriggered: boolean = useSelector(takeIsOcrTriggered);

  const backPath: string | undefined = useMemo(() => {
    if (isAdminFlow) {
      return undefined;
    }

    return isProofOfIdentityEmptyField ? ROUTES.PROOF_OF_IDENTITY : undefined;
  }, [isAdminFlow, isProofOfIdentityEmptyField]);

  return (
    <SectionDetails
      isOcrUploaded={isOcrTriggered}
      isFieldsDisabled={isAllFormsDisabled}
      sectionStatus={data.status}
      saveDraftStatusState={saveDraftStatusState}
      section={Section.PERSONAL_INFORMATION}
      sectionsByAffectingField={sectionsByAffectingField}
      backPath={backPath}
      title="Personal details"
      description="Please confirm that the details scanned match your passport and complete
        any missing information"
      questions={data.questions}
      subTitle={
        isProofOfIdentityEmptyField ? (
          <s.ContainerStatusProof className="subtitle-wrapper">
            <s.MarkerStatus />
            <s.TextStatus>
              Proof of ID required. Add in{" "}
              <s.CustomLink
                to={`${ROUTES.MY_DOCUMENTS}${window.location.search}`}
              >
                My documents
              </s.CustomLink>
            </s.TextStatus>
          </s.ContainerStatusProof>
        ) : undefined
      }
    />
  );
};

export const PersonalDetails: NamedExoticComponent = React.memo(
  PersonalDetailsComponent
);

export default PersonalDetails;
