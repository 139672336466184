import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${getTypographyStyles({ variant: "16RegularL20", color: "lightGray" })};
`;
