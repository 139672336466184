import { useCallback, useState } from "react";
import { GetCaseInformation_screeningCase_products } from "src/graphQLTypes";

export type OpenedProduct = GetCaseInformation_screeningCase_products;

export const useProductActionAnswerDrawerControls = () => {
  const [openedProduct, setOpenedProduct] = useState<OpenedProduct | null>(
    null
  );

  const onOpen = useCallback(
    (product: OpenedProduct) => setOpenedProduct(product),
    []
  );

  const onClose = useCallback(() => setOpenedProduct(null), []);

  return {
    isOpen: Boolean(openedProduct),
    onOpen,
    onClose,
    product: openedProduct
  };
};
