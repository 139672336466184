import React, { FC, memo, PropsWithChildren } from "react";
import { Button, Drawer } from "@security-watchdog/ui-components";

import * as s from "./styles";

type Props = PropsWithChildren<{
  title: string;
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}>;

const Component: FC<Props> = ({
  title,
  isOpen,
  onClose,
  children,
  onSubmit
}) => (
  <>
    <Drawer isOpen={isOpen} placement="right">
      <Drawer.Header title={title} onClose={onClose} />
      <Drawer.Body>{children}</Drawer.Body>
      <Drawer.Footer>
        <s.FooterActionsContainer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Send
          </Button>
        </s.FooterActionsContainer>
      </Drawer.Footer>
    </Drawer>
  </>
);

Component.displayName = "ProductActionAnswerDrawer";

export const ProductActionAnswerDrawer = memo(Component);
