import React, {
  Fragment,
  NamedExoticComponent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBack } from "components/ButtonBack";
import { PageHeader } from "components/PageHeader";
import { Tile } from "components/Tile";
import {
  BUTTON_TYPE,
  FlagIcon,
  formatDate,
  OptionProps,
  PlusSmallIcon,
  theme
} from "@security-watchdog/sw-ui-kit";
import { TimelineCard } from "components/TimelineCard";
import { AddTimelineEntrySidebar } from "components/AddTimelineEntrySidebar";
import {
  takeCaseId,
  takeCountryOptions,
  takeIsCaseUpdating,
  takeCaseProductsConditionalValidation,
  takeHasAddressOverlaps,
  takeRoleLocationScotland
} from "modules/case/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import * as caseActions from "modules/case/actions";
import { v4 as uuidv4 } from "uuid";
import { ConfirmModal } from "components/ConfirmModal";
import { SectionStatus } from "components/common/SectionStatus";
import { IDraftStatuses } from "modules/case/types";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { useMount } from "src/hooks/useMount";
import { usePrevious } from "src/hooks/usePrevious";
import {
  CaseTroubleshoootingHistoryType,
  caseUpdateVariables,
  Countries_countries
} from "src/graphQLTypes";
import {
  IAnswer,
  IFieldAnswer,
  IFieldsValidationMap,
  IPartialTroubleShootHistoryRecord,
  IQuestion,
  ITimeLineValidationReturnValue,
  IValidationCondition
} from "src/types";
import {
  ERROR_TEXT_OF_ADDRESS_HISTORY_OVERLAP_IN_SCOTLAND_LOCATION,
  ROUTES
} from "src/constants";
import {
  buildInputForCaseUpdate,
  getErrorMessageForTimeLineValidation,
  getFieldsValidationMap,
  sortTimeLineAnswers
} from "../../../utils";
import { IAnswerToDelete, IProps } from "./types";
import * as s from "./styles";

const TimelineSectionDetailsComponent: React.FC<IProps> = ({
  title,
  description,
  entityName,
  question,
  timeLineRestriction,
  section,
  saveDraftStatusState,
  sectionStatus
}: IProps) => {
  const navigateTo = useNavigate();

  const dispatch: Dispatch = useDispatch();

  useScrollToTop();

  const isScotlandLocation = useSelector(takeRoleLocationScotland);
  const hasOverlaps = useSelector(takeHasAddressOverlaps);

  const caseId: string = useSelector(takeCaseId);

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const isCaseUpdating: boolean = useSelector(takeIsCaseUpdating);

  const countryOptions: Array<OptionProps<Countries_countries>> =
    useSelector(takeCountryOptions);

  const conditionalValidations: IValidationCondition[] = useSelector(
    takeCaseProductsConditionalValidation
  );

  const prevIsCaseUpdating: boolean | undefined =
    usePrevious<boolean>(isCaseUpdating);

  const prevIsClearDataInProgress: boolean | undefined = usePrevious(
    saveDraftStatusState.clearDataInProgress
  );

  const prevISSaveDraftInProgress: boolean | undefined = usePrevious<boolean>(
    saveDraftStatusState.inProgress
  );

  const [isOpenSideBar, setOpenSideBar]: [
    boolean,
    React.Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [isSubmitted, setSubmitted]: [
    boolean,
    React.Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [answers, setAnswers]: [
    IAnswer[],
    React.Dispatch<SetStateAction<IAnswer[]>>
  ] = useState<IAnswer[]>(question?.answers || []);

  const [editingAnswer, setEditingAnswer]: [
    IAnswer | null,
    React.Dispatch<SetStateAction<IAnswer | null>>
  ] = useState<IAnswer | null>(null);

  const [answerToDelete, setAnswerToDelete]: [
    IAnswerToDelete | null,
    React.Dispatch<SetStateAction<IAnswerToDelete | null>>
  ] = useState<IAnswerToDelete | null>(null);

  useEffect((): void => {
    if (prevIsCaseUpdating && !isCaseUpdating) {
      dispatch(
        caseActions.clearSectionDraftValues.started({
          section,
          caseId,
          history: []
        })
      );
    }
  }, [isCaseUpdating, prevIsCaseUpdating, dispatch, section, caseId]);

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  useEffect(() => {
    if (
      prevIsClearDataInProgress &&
      !saveDraftStatusState.clearDataInProgress
    ) {
      goToBack();
    }
  }, [
    goToBack,
    prevIsClearDataInProgress,
    saveDraftStatusState.clearDataInProgress
  ]);

  useEffect(() => {
    dispatch(caseActions.getCountries.started());

    return (): void => {
      dispatch(caseActions.clearCountries());
    };
  }, [dispatch]);

  const closeSideBar = useCallback((): void => {
    setOpenSideBar(false);
    if (editingAnswer) {
      setEditingAnswer(null);
    }
  }, [editingAnswer]);

  const handleCloseConfirmModal = useCallback(
    () => setAnswerToDelete(null),
    []
  );

  useEffect((): void => {
    if (!saveDraftStatusState.inProgress && prevISSaveDraftInProgress) {
      closeSideBar();
      handleCloseConfirmModal();
    }
  }, [
    closeSideBar,
    handleCloseConfirmModal,
    prevISSaveDraftInProgress,
    saveDraftStatusState.inProgress
  ]);

  const openSideBar = useCallback(
    () => !isAllFormsDisabled && setOpenSideBar(true),
    [isAllFormsDisabled]
  );

  const sortedAnswers: IAnswer[] = useMemo(
    () => sortTimeLineAnswers(answers),
    [answers]
  );

  useMount((): void => {
    dispatch(caseActions.updateSaveDraftStatus(IDraftStatuses.NO_CHANGES));
  });

  const answersValidation: ITimeLineValidationReturnValue = useMemo(() => {
    const filteredAnswers: IAnswer[] = sortedAnswers.filter(
      (answer: IAnswer) => !answer.isDeleted
    );

    return getErrorMessageForTimeLineValidation(
      filteredAnswers,
      timeLineRestriction,
      entityName,
      question,
      conditionalValidations,
      Boolean(isScotlandLocation)
    );
  }, [
    sortedAnswers,
    timeLineRestriction,
    entityName,
    question,
    conditionalValidations,
    isScotlandLocation
  ]);

  const handleSaveDraftValue = useCallback(
    (
      answers: IAnswer[],
      troubleShootingHistory: IPartialTroubleShootHistoryRecord[]
    ): void => {
      if (
        question &&
        !saveDraftStatusState.inProgress &&
        !saveDraftStatusState.clearDataInProgress &&
        !isCaseUpdating
      ) {
        const questions: IQuestion[] = [{ ...question, answers }];
        dispatch(
          caseActions.doAutoSave.started({
            caseId,
            section,
            isTimelineSection: true,
            data: {},
            questions,
            draftTroubleshootingHistory: troubleShootingHistory
          })
        );
      }
    },
    [
      caseId,
      dispatch,
      isCaseUpdating,
      question,
      saveDraftStatusState.clearDataInProgress,
      saveDraftStatusState.inProgress,
      section
    ]
  );

  const handleAddRemovedAnswersToHistory = (
    answers: IAnswer[],
    answerId: string
  ): IPartialTroubleShootHistoryRecord[] =>
    answers.reduce(
      (
        acc: IPartialTroubleShootHistoryRecord[],
        answer: IAnswer
      ): IPartialTroubleShootHistoryRecord[] => {
        if (answer.answerId === answerId) {
          return acc.concat(
            answer.fields.map((field: IFieldAnswer) => ({
              type: CaseTroubleshoootingHistoryType.FIELD_VALUE_UPDATED,
              answerId: answer.answerId,
              fieldId: field.fieldId,
              isDeleted: true,
              index: answer.index,
              to: undefined,
              from: field.value,
              isMulti: field.isMulti,
              fieldType: field.type
            }))
          );
        }

        return acc;
      },
      []
    );

  const handleRemoveAnswer = useCallback(
    ({ answerId, isAnswered }: IAnswerToDelete): void => {
      if (!isAnswered) {
        const nextAnswers: IAnswer[] = answers.filter(
          (answer: IAnswer) => answer.answerId !== answerId
        );
        setAnswers(nextAnswers);
        handleSaveDraftValue(
          nextAnswers,
          handleAddRemovedAnswersToHistory(answers, answerId)
        );
      } else {
        const nextAnswers: IAnswer[] = answers.map((answer: IAnswer) =>
          answer.answerId === answerId ? { ...answer, isDeleted: true } : answer
        );
        setAnswers(nextAnswers);
        handleSaveDraftValue(
          nextAnswers,
          handleAddRemovedAnswersToHistory(answers, answerId)
        );
      }
    },
    [answers, handleSaveDraftValue]
  );

  const handleConfirmDeleteAnswer = useCallback((): void => {
    if (answerToDelete) {
      handleRemoveAnswer(answerToDelete);
    }
  }, [answerToDelete, handleRemoveAnswer]);

  const handleRemoveAnswerClick = useCallback(
    (answerId: string, isAnswered: boolean) => {
      setAnswerToDelete({ answerId, isAnswered });
    },
    []
  );

  const handleAddAnswer = useCallback(
    (
      fields: IFieldAnswer[],
      troubleShootingHistory: IPartialTroubleShootHistoryRecord[]
    ): void => {
      if (editingAnswer) {
        const nextAnswers: IAnswer[] = answers.map((i: IAnswer) =>
          i.answerId === editingAnswer.answerId
            ? { ...editingAnswer, fields }
            : i
        );
        setAnswers(nextAnswers);
        setEditingAnswer(null);
        handleSaveDraftValue(nextAnswers, troubleShootingHistory);
      } else {
        const nextAnswers: IAnswer[] = [
          ...answers,
          {
            index: answers.length,
            answerId: uuidv4(),
            isAnswered: false,
            fields
          }
        ];
        setAnswers(nextAnswers);
        handleSaveDraftValue(nextAnswers, troubleShootingHistory);
      }
    },
    [editingAnswer, answers, handleSaveDraftValue]
  );

  const handleEditAnswer = useCallback(
    (answer: IAnswer): void => {
      setEditingAnswer(answer);
      openSideBar();
    },
    [openSideBar]
  );

  const handleComplete = useCallback((): void => {
    if (!isSubmitted) {
      setSubmitted(true);
    }

    if (question && sortedAnswers.length && !answersValidation.errorMessage) {
      const questions: IQuestion[] = [{ ...question, answers: sortedAnswers }];
      const caseInput: caseUpdateVariables = buildInputForCaseUpdate(
        {},
        questions,
        caseId,
        true
      );
      dispatch(caseActions.caseUpdate.started({ caseInput }));
    }
  }, [
    isSubmitted,
    question,
    sortedAnswers,
    answersValidation.errorMessage,
    caseId,
    dispatch
  ]);

  const answerList = useMemo<JSX.Element[]>(
    () =>
      sortedAnswers
        .filter((answer: IAnswer) => Boolean(!answer.isDeleted))
        .map((answer: IAnswer, index: number) => (
          <Fragment key={answer.answerId}>
            {answersValidation.errorMessage &&
              isSubmitted &&
              answersValidation.index === index && (
                <s.ErrorMessage>
                  {answersValidation.errorMessage}
                </s.ErrorMessage>
              )}
            <TimelineCard
              key={answer.answerId}
              isDisabled={isAllFormsDisabled}
              answer={answer}
              hasAlert={Boolean(
                answersValidation.answerIdsMap &&
                  answersValidation.answerIdsMap[answer.answerId]
              )}
              entityName={entityName}
              onEdit={handleEditAnswer}
              onDelete={handleRemoveAnswerClick}
            />
          </Fragment>
        )),
    [
      sortedAnswers,
      answersValidation.errorMessage,
      answersValidation.index,
      answersValidation.answerIdsMap,
      isSubmitted,
      isAllFormsDisabled,
      entityName,
      handleEditAnswer,
      handleRemoveAnswerClick
    ]
  );

  const isCompleteDisabled: boolean = useMemo(
    () => Boolean(!answers.length),
    [answers]
  );

  const validationMap: IFieldsValidationMap = useMemo(
    () =>
      getFieldsValidationMap(question ? [question] : [], timeLineRestriction),
    [question, timeLineRestriction]
  );

  const isAllAnswersAreDeleted = answers.every(
    (answer: IAnswer) => answer.isDeleted === true
  );

  const isCompleteButtonDisabled =
    isCompleteDisabled ||
    isAllFormsDisabled ||
    saveDraftStatusState.inProgress ||
    isCaseUpdating ||
    saveDraftStatusState.clearDataInProgress ||
    isAllAnswersAreDeleted;

  const renderAddEntityButton: ReactNode = useMemo<ReactNode>((): ReactNode => {
    if (question?.isMultipleAnswers) {
      return (
        <Tile onClick={openSideBar}>
          <s.ButtonAdd
            isDisabled={isAllFormsDisabled}
            tabIndex={-1}
            buttonType={BUTTON_TYPE.Link}
            icon={
              <PlusSmallIcon
                color={
                  isAllFormsDisabled
                    ? theme.colors.stormGray
                    : theme.colors.royalBlue
                }
                size={16}
              />
            }
          >
            {`Add ${entityName}`}
          </s.ButtonAdd>
        </Tile>
      );
    }

    if (
      !question?.isMultipleAnswers &&
      (!answers.length ||
        answers.map((answer: IAnswer) => answer.isDeleted).every(Boolean))
    ) {
      return (
        <Tile onClick={openSideBar}>
          <s.ButtonAdd
            tabIndex={-1}
            buttonType={BUTTON_TYPE.Link}
            icon={<PlusSmallIcon color={theme.colors.royalBlue} size={16} />}
          >
            {`Add ${entityName}`}
          </s.ButtonAdd>
        </Tile>
      );
    }

    return null;
  }, [answers, entityName, isAllFormsDisabled, openSideBar, question]);

  return (
    <>
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
        <PageHeader title={title} description={description} />
        <SectionStatus
          sectionStatus={sectionStatus}
          saveDraftStatus={saveDraftStatusState.status}
        />
        <s.ContainerAddress>
          {question?.isMultipleAnswers &&
            timeLineRestriction &&
            timeLineRestriction.startDate && (
              <Tile>
                <FlagIcon />
                <s.Text>{formatDate(timeLineRestriction.startDate)}</s.Text>
              </Tile>
            )}
          {renderAddEntityButton}
          {answerList}
          {question?.isMultipleAnswers &&
            timeLineRestriction &&
            timeLineRestriction.endDate && (
              <Tile>
                <FlagIcon />
                <s.Text>{formatDate(timeLineRestriction.endDate)}</s.Text>
              </Tile>
            )}
        </s.ContainerAddress>
        {answersValidation.errorMessage &&
          isSubmitted &&
          answersValidation.index === null && (
            <s.ErrorMessage>{answersValidation.errorMessage}</s.ErrorMessage>
          )}
        {isScotlandLocation && hasOverlaps && (
          <s.ErrorMessage>
            {ERROR_TEXT_OF_ADDRESS_HISTORY_OVERLAP_IN_SCOTLAND_LOCATION}
          </s.ErrorMessage>
        )}
        <s.CustomButton
          isLoading={isCaseUpdating || saveDraftStatusState.clearDataInProgress}
          isDisabled={isCompleteButtonDisabled}
          buttonType={BUTTON_TYPE.Primary}
          onClick={handleComplete}
        >
          Complete
          {question?.isMultipleAnswers}
        </s.CustomButton>
        <AddTimelineEntrySidebar
          answerIndex={editingAnswer ? editingAnswer.index : answers.length}
          isAllFormsDisabled={isAllFormsDisabled}
          saveDraftInProgress={saveDraftStatusState.inProgress}
          isMultipleAnswers={question?.isMultipleAnswers}
          questionId={question?.questionId || ""}
          answerId="answerId"
          saveButtonName={`Save ${entityName}`}
          title={editingAnswer ? `Edit ${entityName}` : `Add ${entityName}`}
          isOpened={isOpenSideBar}
          countries={countryOptions}
          onClose={closeSideBar}
          onSave={handleAddAnswer}
          fields={editingAnswer ? editingAnswer.fields : question?.fields || []}
          validationMap={validationMap}
        />
        <ConfirmModal
          buttonsState={{
            cancelDisabled: saveDraftStatusState.inProgress,
            confirmLoading: saveDraftStatusState.inProgress
          }}
          isShowModal={Boolean(answerToDelete)}
          primaryBtnText="Remove answer"
          confirmAction={handleConfirmDeleteAnswer}
          onModalClose={handleCloseConfirmModal}
        >
          Are you sure you want to remove this answer?
        </ConfirmModal>
      </ContentLayout>
    </>
  );
};

export const TimelineSectionDetails: NamedExoticComponent<IProps> =
  React.memo<IProps>(TimelineSectionDetailsComponent);
