import React, { FC, memo, PropsWithChildren, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CandidatePortalAuthFlow } from "src/graphQLTypes";
import { Spinner } from "components/common/Spinner";
import { ROUTES } from "src/constants";
import { isPublicRoute } from "src/utils";
import { useGetAuthFlow } from "containers/AuthProvider/hooks";
import { AzureProvider } from "containers/AzureAdProvier";
import { AppWrapper } from "containers/AppWrapper";
import { ScreenerAzureProvider } from "containers/ScreenerAzureProvider";
import { useDispatch } from "react-redux";
import { updateAdminFlowState } from "modules/main/actions";
import { useUrlSearchParams } from "src/hooks/useUrlSearchParams";

const AuthProviderComponent: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const { isAdminFlow, caseId } = useUrlSearchParams();

  useEffect(() => {
    dispatch(updateAdminFlowState(isAdminFlow));
  }, [dispatch, isAdminFlow]);

  const { authFlow, isLoading } = useGetAuthFlow(
    caseId,
    isAdminFlow || !caseId
  );

  const render = useMemo(() => {
    if (isPublicRoute(pathname as ROUTES)) {
      return <>{children}</>;
    }

    if (isAdminFlow) {
      return (
        <ScreenerAzureProvider>
          <AppWrapper>{children}</AppWrapper>
        </ScreenerAzureProvider>
      );
    }

    switch (authFlow) {
      case CandidatePortalAuthFlow.AZURE_AD_SIGN_IN:
      case CandidatePortalAuthFlow.AZURE_AD_SIGN_IN_OR_SIGN_UP:
      case CandidatePortalAuthFlow.AZURE_AD_SIGN_UP:
        return (
          <AzureProvider authFlow={authFlow}>
            <AppWrapper>{children}</AppWrapper>
          </AzureProvider>
        );

      default:
        return null;
    }
  }, [authFlow, children, isAdminFlow, pathname]);

  return isLoading ? <Spinner /> : render;
};

export const AuthProvider = memo<PropsWithChildren>(AuthProviderComponent);
