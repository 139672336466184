import styled from "styled-components";
import { IContainerMenuProps } from "./types";

export const Container = styled.div`
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 15px;
`;

export const ContainerMenu = styled.div<IContainerMenuProps>`
  border-top: 1px solid #dddeed;
  position: absolute;
  width: 100%;
  height: 128px;
  transition: 0.8s all;
  background-color: ${({ theme }): string => theme.colors.white};
  transform: ${({ $isShowMenu }: IContainerMenuProps): string =>
    $isShowMenu ? "translateY(72%)" : "translateY(-100%)"};
  left: 0;
  padding: 25px;
  box-sizing: border-box;
  box-shadow: 0 4px 2px -2px rgb(221 222 237 / 50%);
`;
