import React, { ReactElement } from "react";
import { StatusCard } from "components/StatusCard";
import * as s from "./styles";
import { IProps } from "./types";

export const SectionTitle: React.FC<IProps> = ({
  status,
  title
}: IProps): ReactElement => (
  <>
    <s.TitleContainer>
      <s.Title>Missing documents</s.Title>
      <s.SubTitle>Please provide missing documents</s.SubTitle>
    </s.TitleContainer>
    <StatusCard status={status} title={title} />
  </>
);
