import { ITimeLineRestriction } from "src/types";
import { getStartDateByUnit } from "./getStartDateByUnit";
import { sortBy } from "lodash";

type Parenthesis = {
  value: number;
  type: "from" | "to";
};

export interface IDateRange {
  from: Date;
  to: Date | undefined | null;
}

/**
 * This function implements an algorithm to find gaps between date ranges.
 *
 * Here's a simplified explanation of the algorithm:
 *
 * For example, consider the date ranges `{from:1, to:5}`, `{from:2, to:7}`, and `{from:9, to:10}`.
 * There is a gap between the second and third date ranges.
 * The date ranges are transformed into pairs `[1, "from"]`, `[5, "to"]`, `[2, "from"]`, `[7, "to"]`, `[9, "from"]`, and `[10, "to"]`.
 * The pairs are sorted by value, resulting in `[1, "from"]`, `[2, "from"]`, `[5, "to"]`, `[7, "to"]`, `[9, "from"]`, and `[10, "to"]`.
 * The sorted pairs can be mapped to a set of parentheses, such as `(())()`, where "(" represents "from" and ")" represents "to".
 * The algorithm goes through this array of parentheses, incrementing a counter by 1 for each "(" and decrementing it by 1 for each ")".
 * If the counter becomes zero and it is not the last element, the value of the current element is checked against the value of the next element.
 * If the value of the next element is greater, a gap has been found.
 *
 * The index of the gap-range pair is returned as the result of the function.
 *
 * @param dateRanges - An array of date ranges.
 * @param timeLineRestriction - Restrictions on the timeline.
 * @returns The index of the element that is part of the gap-range pair.
 */
function findIndexOfGap(
  dateRanges: IDateRange[],
  timeLineRestriction: ITimeLineRestriction
): number {
  const { endDate, gap, gapUnit, startDate }: ITimeLineRestriction =
    timeLineRestriction;
  const parenthesesArray = dateRanges.reduce<Parenthesis[]>((acc, a) => {
    const toValue = a.to?.valueOf() ?? Infinity;
    const open: Parenthesis = {
      value: getStartDateByUnit(gapUnit, gap, a.from).valueOf(),
      type: "from"
    };
    const close: Parenthesis = { value: toValue, type: "to" };
    return [...acc, open, close];
  }, []);

  const sortedParenthesesArray = sortBy(parenthesesArray, (o) => o.value);

  if (sortedParenthesesArray[0].value > startDate.valueOf()) {
    return 0;
  }

  let counter = 0;
  for (let index = 0; index < sortedParenthesesArray.length; index++) {
    const { value, type } = sortedParenthesesArray[index];
    if (type === "from") {
      counter = counter + 1;
    }
    if (type === "to") {
      counter = counter - 1;
    }
    if (counter === 0 && index < sortedParenthesesArray.length - 1) {
      if (sortedParenthesesArray[index + 1].value > value) {
        return dateRanges.findIndex(
          (dr) =>
            dr.to?.valueOf() === value ||
            getStartDateByUnit(gapUnit, gap, dr.from).valueOf() === value
        );
      }
    }
  }

  if (
    getStartDateByUnit(gapUnit, gap, endDate).valueOf() >
    sortedParenthesesArray[sortedParenthesesArray.length - 1].value
  ) {
    return sortedParenthesesArray.length - 1;
  }
  return -1;
}

export default findIndexOfGap;
