import { IS_KONFIR_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY } from "src/constants/system";
import { getCase_screeningCase_sections_answers } from "src/graphQLTypes";
import { getKonfirFirstDifficultiesBooleanAnswerValue } from "containers/KonfirPage/utils";
import { KonfirButtonState } from "containers/KonfirPage/types";

type UseDifficultiesBtnState = {
  buttonState: KonfirButtonState;
  setDifficultiesButtonVisibilityToStorage: () => void;
};

type UseDifficultiesBtnStateArgs = {
  sectionAnswers: getCase_screeningCase_sections_answers[];
  isCompleted: boolean;
};

export const useDifficultiesBtnState = ({
  sectionAnswers,
  isCompleted
}: UseDifficultiesBtnStateArgs): UseDifficultiesBtnState => {
  const isDifficultiesButtonVisible = sessionStorage.getItem(
    IS_KONFIR_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY
  );

  const setDifficultiesButtonVisibilityToStorage = () =>
    sessionStorage.setItem(
      IS_KONFIR_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY,
      "true"
    );

  const difficultiesQuestionAnswerValue =
    getKonfirFirstDifficultiesBooleanAnswerValue(sectionAnswers);

  const getDifficultiesButtonState = (): KonfirButtonState => {
    if (difficultiesQuestionAnswerValue === null) {
      return {
        isDisabled: false,
        isVisible: !!isDifficultiesButtonVisible
      };
    }

    if (difficultiesQuestionAnswerValue || isCompleted) {
      return {
        isDisabled: true,
        isVisible: true
      };
    }

    return {
      isDisabled: false,
      isVisible: true
    };
  };

  return {
    buttonState: getDifficultiesButtonState(),
    setDifficultiesButtonVisibilityToStorage
  };
};
