import React, { FC, useState } from "react";
import * as s from "./styles";
import { ChevronIcon, DIRECTION } from "@security-watchdog/sw-ui-kit";
import {
  attachmentFragment,
  getCase_screeningCase_additionalCandidateAttachments
} from "src/graphQLTypes";
import { ExpandedContent } from "containers/AdditionalDocuments/components/ExpandedContent";

interface IProps {
  attachments: getCase_screeningCase_additionalCandidateAttachments[];
  onDownload: (attachment: attachmentFragment) => void;
}

export const ListOfUploadedFiles: FC<IProps> = ({
  attachments,
  onDownload
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const chevronDirection: DIRECTION = isExpanded
    ? DIRECTION.UP
    : DIRECTION.DOWN;

  const toggleExpand = () => setExpanded((prev: boolean) => !prev);

  return (
    <s.Container>
      <s.HeaderContainer onClick={toggleExpand}>
        <s.TitleContainer>
          <s.Title>All previously uploaded documents</s.Title>
          <s.SubTitle>{attachments.length} documents</s.SubTitle>
        </s.TitleContainer>
        <ChevronIcon size={24} direction={chevronDirection} />
      </s.HeaderContainer>
      {isExpanded &&
        attachments.map((attachment) => (
          <ExpandedContent
            onDownload={onDownload}
            attachment={attachment}
            key={attachment.whenUploaded}
          />
        ))}
    </s.Container>
  );
};
