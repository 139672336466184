import styled from "styled-components";
import { DEVICE } from "src/constants";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  width: 795px;
  padding: 0 0 30px;
  box-sizing: border-box;

  @media (max-width: ${DEVICE.LAPTOP}) {
    padding: 25px 16px 30px;
    max-width: 100%;
  }
`;
