import React, { FC, PropsWithChildren, ReactNode, useState } from "react";
import { Tooltip } from "@security-watchdog/ui-components";

import { useDeviceType } from "src/hooks/useDeviceType";
import { TooltipModal } from "components/TooltipModal";

type DeviceDependedTooltipProps = PropsWithChildren<{
  title: string;
  renderTooltipContent: () => ReactNode;
}>;

export const DeviceDependedTooltip: FC<DeviceDependedTooltipProps> = ({
  title,
  renderTooltipContent,
  children
}) => {
  const { isMobile } = useDeviceType();
  const [isTooltipModalVisible, setIsTooltipModalVisible] =
    useState<boolean>(false);

  if (isMobile) {
    return (
      <>
        <TooltipModal
          title={title}
          isOpen={isTooltipModalVisible}
          onClose={() => setIsTooltipModalVisible(false)}
        >
          {renderTooltipContent()}
        </TooltipModal>
        <div onClick={() => setIsTooltipModalVisible(true)}>{children}</div>
      </>
    );
  }

  return (
    <Tooltip
      renderContent={() => (
        <span className="tips">{renderTooltipContent()}</span>
      )}
      placement="top"
      className="background-color-surface-highlight-default"
    >
      {children}
    </Tooltip>
  );
};
