import {
  IAnswer,
  IFieldAnswer,
  IAnswersToValidateMap,
  IValidationId,
  IValidationCondition
} from "src/types";

export const getAnswersForConditionalValidaton = (
  answers: IAnswer[],
  validations: IValidationCondition[]
): Map<string, IAnswersToValidateMap> =>
  answers
    .reduce(
      (answers: IFieldAnswer[], answer: IAnswer): IFieldAnswer[] => [
        ...answers,
        ...answer.fields
      ],
      []
    )
    .reduce(
      (
        answersToValidateMap: Map<string, IAnswersToValidateMap>,
        field: IFieldAnswer
      ): Map<string, IAnswersToValidateMap> => {
        const matchedItem = validations.find(
          (validation: IValidationCondition) => {
            try {
              const { id: fieldId }: IValidationId = validation.id
                ? JSON.parse(field.fieldId)
                : "";

              if (validation.id && fieldId) {
                return (
                  validation.id === fieldId &&
                  validation.availableAnswers.find((el) => el === field.value)
                );
              }
            } catch {
              return null;
            }
            return undefined;
          }
        );

        if (matchedItem) {
          try {
            const { id: fieldId }: IValidationId = field.fieldId
              ? JSON.parse(field.fieldId)
              : "";
            const currentMapItem = answersToValidateMap.get(fieldId);

            answersToValidateMap.set(fieldId, {
              ...currentMapItem,
              [field.value]:
                currentMapItem && currentMapItem[field.value]
                  ? currentMapItem[field.value] + 1
                  : 1
            });
          } catch {
            return answersToValidateMap;
          }

          return answersToValidateMap;
        }

        return answersToValidateMap;
      },
      new Map()
    );
