import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const ContainerFooter = styled.div`
  flex-shrink: 0;
  min-height: 160px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }): string => theme.colors.tangaroa};
  margin-top: auto;
`;

export const ContainerNavPanelFooter = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${DEVICE.TABLET}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
export const NavItem = styled.div`
  margin: 0 20px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${DEVICE.TABLET}) {
    margin: 0 0 15px 0;
    box-sizing: border-box;
    width: 50%;
  }
`;

export const NavItemLink = styled.a`
  ${getTypographyStyles({ color: "whiteLilac", variant: "14RegularL24" })};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.snuff};

  &:hover {
    color: ${({ theme }): string => theme.colors.whiteLilac};
  }
`;

export const CopyrightBlock = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Logo = styled.div`
  @media (max-width: ${DEVICE.TABLET}) {
    order: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
`;
export const CopyrightText = styled.span`
  padding-left: 32px;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.snuff};

  @media (max-width: ${DEVICE.TABLET}) {
    padding-left: 0;
  }
`;

export const LayoutFooterContent = styled.div`
  width: 795px;
  padding: 34px 0;

  @media (max-width: ${DEVICE.TABLET}) {
    padding: 34px 14px 14px;
  }
`;

export const MatrixLink = styled.a`
  color: ${({ theme }): string => theme.colors.snuff};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.snuff};
`;
