import caseSubmitMutation from "api/graphql/mutations/CandidateCaseSubmitPayload/caseSubmit.gql";
import caseUpdateMutation from "api/graphql/mutations/CandidateCaseUpdatePayload/caseUpdate.gql";
import doSaveDraftMutation from "api/graphql/mutations/CandidateCaseSaveDraftPayload/doSaveDraft.gql";
import saveUploadHistoryMutation from "api/graphql/mutations/CandidateCaseSaveDraftPayload/saveUploadHistory.gql";
import additionalDocumentsUploadMutation from "api/graphql/mutations/CandidateUploadAdditionalDocumentPayload/additionalDocumentsUpload.gql";
import getCaseQuery from "api/graphql/queries/CandidateCase/getCase.gql";
import getCountriesQuery from "api/graphql/queries/Country/getCountries.gql";
import { ApolloQueryResult } from "@apollo/client";
import { SagaIterator } from "redux-saga";
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select
} from "redux-saga/effects";
import { Action } from "typescript-fsa";
import {
  IAutoSaveVariables,
  IClearSectionDraftValuesVariables,
  ISaveUploadHistoryVariables
} from "modules/case/types";
import { takeDraftData } from "modules/case/selectors";
import { takeIsAdminFlow } from "modules/main/selectors";
import {
  caseSubmit,
  caseSubmitVariables,
  caseUpdate,
  Countries,
  getCase,
  getCaseVariables,
  saveDraft,
  additionalDocumentsUploadVariables,
  additionalDocumentsUpload,
  CandidateCaseSaveDraftInput,
  saveUploadHistory
} from "src/graphQLTypes";
import { CaseErrorTypes, ICaseUpdateInput } from "src/types";
import {
  buildInputForSaveDraftValues,
  DraftValues
} from "src/utils/buildInputForSaveDraftValues";
import { buildDraftTroubleShootingHistoryData } from "src/utils/buildDraftTroubleShootingHistoryData";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";
import client from "../../apolloClient";
import * as actions from "./actions";

export function* caseSubmitSaga({
  payload: params
}: Action<caseSubmitVariables>): SagaIterator {
  try {
    const { logEvent }: IUseGA = googleAnalytics();
    const { data }: ApolloQueryResult<caseSubmit> = yield call(client.mutate, {
      variables: params,
      mutation: caseSubmitMutation
    });
    yield put(
      actions.caseSubmitRequest.done({ params, result: data.caseSubmit.case })
    );
    logEvent({ category: "Screening", action: "SUBMITTED" });
  } catch (e) {
    if (e instanceof Error) {
      yield put(
        actions.caseSubmitRequest.failed({
          params,
          error: e.message
        })
      );
    }
  }
}

export function* getCaseSaga({
  payload: params
}: Action<getCaseVariables>): SagaIterator {
  try {
    const { data }: ApolloQueryResult<getCase> = yield call(client.query, {
      variables: params,
      query: getCaseQuery
    });
    yield put(actions.getCase.done({ params, result: data.screeningCase }));
  } catch (e) {
    if (e instanceof Error) {
      yield put(
        actions.getCase.failed({
          params,
          error: { message: e.message, type: CaseErrorTypes.FAILED_FETCH }
        })
      );
    }
  }
}

export function* caseUpdateSaga({
  payload: params
}: Action<ICaseUpdateInput>): SagaIterator {
  try {
    const { data }: ApolloQueryResult<caseUpdate> = yield call(client.mutate, {
      variables: params.caseInput,
      fetchPolicy: "no-cache",
      mutation: caseUpdateMutation
    });

    yield put(
      actions.caseUpdate.done({ params, result: data.caseUpdate.case })
    );
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.caseUpdate.failed({ params, error: e.message }));
    }
  }
}

export function* getCountriesSaga(action: Action<void>): SagaIterator {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data }: ApolloQueryResult<Countries> = yield call(client.query, {
      variables: action.payload,
      query: getCountriesQuery
    });
    yield put(
      actions.getCountries.done({
        params: action.payload,
        result: data.countries
      })
    );
  } catch (e) {
    if (e instanceof Error) {
      yield put(
        actions.getCountries.failed({
          params: action.payload,
          error: e.message
        })
      );
    }
  }
}

export function* doAutoSaveSaga({
  payload: params
}: Action<IAutoSaveVariables>): SagaIterator<void> {
  try {
    const draftData: DraftValues = yield select(takeDraftData);

    const isAdminFlow: boolean = yield select(takeIsAdminFlow);

    const saveDraftAnswers: DraftValues = buildInputForSaveDraftValues({
      isTimelineSection: params.isTimelineSection,
      questions: params.questions,
      section: params.section,
      data: params.data
    });

    const stringifiedData: string = JSON.stringify({
      ...draftData,
      ...saveDraftAnswers
    });

    const draftTroubleshootingHistory: Pick<
      CandidateCaseSaveDraftInput,
      "draftTroubleshootingHistory"
    > = {
      draftTroubleshootingHistory: isAdminFlow
        ? buildDraftTroubleShootingHistoryData({
            caseId: params.caseId,
            draftTroubleshootingHistory: params.draftTroubleshootingHistory
          })
        : undefined
    };

    const { data }: ApolloQueryResult<saveDraft> = yield call(client.mutate, {
      mutation: doSaveDraftMutation,
      variables: {
        input: {
          caseId: params.caseId,
          draft: stringifiedData,
          ...draftTroubleshootingHistory
        }
      }
    });

    yield put(actions.doAutoSave.done({ params, result: data.saveDraft.case }));
  } catch (error) {
    yield put(actions.doAutoSave.failed({ params, error }));
  }
}

export function* clearSectionDraftValuesSaga({
  payload: params
}: Action<IClearSectionDraftValuesVariables>): SagaIterator<void> {
  try {
    const draftData: DraftValues = yield select(takeDraftData);

    const isAdminFlow: boolean = yield select(takeIsAdminFlow);

    const draftTroubleshootingHistory: Pick<
      CandidateCaseSaveDraftInput,
      "draftTroubleshootingHistory"
    > = {
      draftTroubleshootingHistory: isAdminFlow
        ? buildDraftTroubleShootingHistoryData({
            caseId: params.caseId,
            draftTroubleshootingHistory: params.history
          })
        : undefined
    };

    if (params.section) {
      delete draftData[params.section];
    }

    const stringifiedData: string = JSON.stringify(draftData);

    const { data }: ApolloQueryResult<saveDraft> = yield call(client.mutate, {
      mutation: doSaveDraftMutation,
      variables: {
        input: {
          draft: stringifiedData,
          caseId: params.caseId,
          ...draftTroubleshootingHistory
        }
      }
    });

    yield put(
      actions.clearSectionDraftValues.done({
        params,
        result: data.saveDraft.case
      })
    );
  } catch (error) {
    yield put(actions.clearSectionDraftValues.failed({ params, error }));
  }
}

export function* additionalDocumentsUploadSaga({
  payload: params
}: Action<additionalDocumentsUploadVariables>): SagaIterator<void> {
  try {
    const { data }: ApolloQueryResult<additionalDocumentsUpload> = yield call(
      client.mutate,
      {
        mutation: additionalDocumentsUploadMutation,
        variables: params
      }
    );

    yield put(
      actions.additionalDocumentsUpload.done({
        params,
        result: data.uploadAdditionalDocuments
      })
    );

    yield put(
      actions.getCase.started({
        id: data.uploadAdditionalDocuments.screeningCaseId
      })
    );
  } catch (error) {
    yield put(actions.additionalDocumentsUpload.failed({ params, error }));
  }
}

export function* pullDraftDataSaga({
  payload: params
}: Action<getCaseVariables>): SagaIterator<void> {
  try {
    const { data }: ApolloQueryResult<getCase> = yield call(client.query, {
      variables: params,
      query: getCaseQuery
    });

    yield put(
      actions.pullDraftData.done({ params, result: data.screeningCase })
    );
  } catch (error) {
    yield put(actions.pullDraftData.failed({ params, error }));
  }
}

export function* saveUploadFilesHistorySaga({
  payload: params
}: Action<ISaveUploadHistoryVariables>): SagaIterator {
  try {
    const draftData: DraftValues = yield select(takeDraftData);

    const stringifiedData: string = JSON.stringify(draftData);

    const isAdminFlow: boolean = yield select(takeIsAdminFlow);

    const draftTroubleshootingHistory: Pick<
      CandidateCaseSaveDraftInput,
      "draftTroubleshootingHistory"
    > = {
      draftTroubleshootingHistory: isAdminFlow
        ? buildDraftTroubleShootingHistoryData({
            caseId: params.caseId,
            draftTroubleshootingHistory: params.history
          })
        : undefined
    };

    const { data }: ApolloQueryResult<saveUploadHistory> = yield call(
      client.mutate,
      {
        mutation: saveUploadHistoryMutation,
        variables: {
          input: {
            draft: stringifiedData,
            caseId: params.caseId,
            ...draftTroubleshootingHistory
          }
        }
      }
    );

    yield put(
      actions.saveUploadFilesHistory.done({
        params,
        result: data.saveDraft.case
      })
    );
  } catch (error) {
    yield put(actions.saveUploadFilesHistory.failed({ params, error }));
  }
}

export function* watcherSaga(): SagaIterator {
  yield all([
    takeLatest(actions.getCase.started, getCaseSaga),
    takeLatest(actions.caseSubmitRequest.started, caseSubmitSaga),
    takeLatest(actions.caseUpdate.started, caseUpdateSaga),
    takeLatest(actions.getCountries.started, getCountriesSaga),
    takeLatest(actions.doAutoSave.started, doAutoSaveSaga),
    takeLatest(
      actions.clearSectionDraftValues.started,
      clearSectionDraftValuesSaga
    ),
    takeEvery(
      actions.additionalDocumentsUpload.started,
      additionalDocumentsUploadSaga
    ),
    takeLatest(actions.pullDraftData.started, pullDraftDataSaga),
    takeLatest(
      actions.saveUploadFilesHistory.started,
      saveUploadFilesHistorySaga
    )
  ]);
}
