import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps,
  IPropsField,
  Field as FieldComponent,
  Sidebar as SidebarComponent
} from "@security-watchdog/sw-ui-kit";
import { ExpandedCard as ExpandedCardComponent } from "components/ExpandedCard";
import { IExpandedCardProps } from "components/ExpandedCard/types";
import { IExtendedIButtonProps } from "components/common/QualificationAndGradesField/types";
import { DEVICE } from "src/constants";

export const Field = styled(FieldComponent)<IPropsField>`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Sidebar = styled(SidebarComponent)`
  @media (max-width: ${DEVICE.MOBILE_L}) {
    width: 100%;
  }
`;

export const Heading = styled.h2`
  margin: 0;
  ${getTypographyStyles({ variant: "24BoldL32" })};
`;

export const ErrorText = styled.div`
  ${getTypographyStyles({ variant: "14RegularL20", color: "mojo" })};
  margin-top: 8px;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  color: ${({ theme }): string => theme.colors.tangaroa};
  padding: 40px;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
`;

export const DoubleField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

export const LongField = styled(FieldComponent)<IPropsField>`
  width: 62%;
  margin-bottom: 0;
`;

export const ShortField = styled(FieldComponent)<IPropsField>`
  width: 28%;
  margin-bottom: 0;
`;

export const DeleteButton = styled(Button)<IExtendedIButtonProps>`
  color: ${({ theme }): string => theme.colors.mojo};
  display: flex;
  align-items: flex-start;
  padding-top: ${({ isFirst }): string => (isFirst ? "43px" : "20px")};
`;

export const AddSubEntryButton = styled(Button)<IButtonProps>`
  margin-top: 5px;
`;

export const GradesContainer = styled.div`
  ${getTypographyStyles({ variant: "16RegularL20" })};

  display: flex;
  width: 100%;
  color: #8b8c98;
`;

export const CardActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const CardActionButton = styled(Button)<IButtonProps>`
  color: ${({ theme }): string => theme.colors.stormGray};
`;

export const ExpandedCard = styled(ExpandedCardComponent)<IExpandedCardProps>`
  margin-bottom: 15px;
`;

export const ActionButtonAdd = styled(Button)<IButtonProps>`
  justify-content: center;
  margin-right: 20px;
  width: 240px;
`;

export const ActionCancelButton = styled(Button)<IButtonProps>`
  width: 112px;
  justify-content: center;
`;
