import { Configuration } from "@azure/msal-browser/dist/config/Configuration";
import {
  AuthenticationResult,
  BrowserConfiguration,
  EventMessage,
  EventType,
  PublicClientApplication
} from "@azure/msal-browser";

export type MsalConfig = Configuration | BrowserConfiguration;

const getMsalConfiguration = (config: MsalConfig): PublicClientApplication => {
  const instance = new PublicClientApplication(config);

  //get initialize msalInstance
  void instance.initialize();

  const activeAccount = instance.getActiveAccount();

  if (!activeAccount) {
    // Account selection
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
  }

  //set the account
  instance.addEventCallback((event: EventMessage) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload
    ) {
      const authenticationResult = event.payload as AuthenticationResult;
      const account = authenticationResult.account;
      instance.setActiveAccount(account);
    }
  });

  //enable account storage event
  instance.enableAccountStorageEvents();

  return instance;
};

class MsalInstance {
  private static instance: PublicClientApplication | null = null;

  static getInstance(props?: Partial<MsalConfig>): PublicClientApplication {
    if (!MsalInstance.instance) {
      if (props) {
        MsalInstance.instance = getMsalConfiguration(props as MsalConfig);
      } else {
        throw new Error(
          "MsalInstanceProps are required for the first instance."
        );
      }
    }
    return MsalInstance.instance;
  }
}

export const getMsalInstance = (
  props?: Partial<MsalConfig>
): PublicClientApplication => MsalInstance.getInstance(props);
