import React, { useMemo } from "react";
import { DefaultTheme, useTheme } from "styled-components";

const DEFAULT_SIZE: number = 24; // in pixels

interface IProps {
  color?: string;
  size?: number;
}

const BurgerIconComponent: React.FC<IProps> = (props: IProps) => {
  const theme: DefaultTheme = useTheme();

  const size: number = useMemo(() => props.size || DEFAULT_SIZE, [props.size]);

  const color: string = useMemo(
    () => props.color || theme.colors.stormGray,
    [props.color, theme.colors.stormGray]
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm0 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm1 5a1 1 0 100 2h18a1 1 0 100-2H3z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const BurgerIcon = React.memo(BurgerIconComponent);
