import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actions from "modules/screenerAccess/actions";
import { Action } from "typescript-fsa";
import {
  doCaseTroubleshootingRequestUpdate,
  doCaseTroubleshootingRequestUpdateVariables,
  getActiveTroubleShootingRequest,
  getActiveTroubleShootingRequestVariables
} from "src/graphQLTypes";
import { ApolloQueryResult } from "@apollo/client";
import getActiveTroubleShootingRequestQuery from "api/graphql/queries/CandidateCaseTroubleshootingRequestConnection/getActiveTroubleShootingRequest.gql";
import doCaseTroubleshootingRequestUpdateMutation from "api/graphql/mutations/CandidateCaseTroubleshootingRequestUpdateInput/doCaseTroubleshootingRequestUpdate.gql";
import apolloClient from "../../apolloClient";

export function* getActiveTroubleShootingRequestSaga({
  payload: params
}: Action<getActiveTroubleShootingRequestVariables>): SagaIterator<void> {
  try {
    const { data }: ApolloQueryResult<getActiveTroubleShootingRequest> =
      yield call(apolloClient.query, {
        variables: params,
        query: getActiveTroubleShootingRequestQuery
      });

    yield put(
      actions.getActiveTroubleShootingRequest.done({
        params,
        result: data.activeCaseTroubleshootingRequest.troubleshootingRequest
      })
    );
  } catch (error) {
    yield put(
      actions.getActiveTroubleShootingRequest.failed({ params, error })
    );
  }
}

export function* doCaseTroubleshootingRequestUpdateSaga({
  payload: params
}: Action<doCaseTroubleshootingRequestUpdateVariables>): SagaIterator<void> {
  try {
    const { data }: ApolloQueryResult<doCaseTroubleshootingRequestUpdate> =
      yield call(apolloClient.mutate, {
        variables: params,
        mutation: doCaseTroubleshootingRequestUpdateMutation
      });

    yield put(
      actions.doCaseTroubleshootingRequestUpdate.done({
        params,
        result: data.caseTroubleshootingRequestUpdate.troubleshootingRequest
      })
    );
  } catch (error) {
    yield put(
      actions.doCaseTroubleshootingRequestUpdate.failed({ params, error })
    );
  }
}

export function* watcherSaga(): SagaIterator {
  yield all([
    takeLatest(
      actions.getActiveTroubleShootingRequest.started,
      getActiveTroubleShootingRequestSaga
    ),
    takeLatest(
      actions.doCaseTroubleshootingRequestUpdate.started,
      doCaseTroubleshootingRequestUpdateSaga
    )
  ]);
}
