import React, {
  MutableRefObject,
  NamedExoticComponent,
  PropsWithChildren,
  useRef
} from "react";
import { BUTTON_TYPE, FocusTrap } from "@security-watchdog/sw-ui-kit";
import { Portal } from "components/Portal";
import { useMount } from "src/hooks/useMount";
import { IProps } from "./types";
import * as s from "./styles";

export const ModalComponent: React.FC<PropsWithChildren<IProps>> = ({
  title,
  children,
  onModalClose,
  confirmAction,
  primaryBtnText
}: PropsWithChildren<IProps>) => {
  const cancelBtnRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  useMount(() => {
    if (cancelBtnRef.current) {
      cancelBtnRef.current.focus();
    }
  });

  return (
    <Portal>
      <s.Container>
        <FocusTrap isActive>
          <s.ModalContainer>
            <s.Header>{title}</s.Header>
            <s.BodyModal>{children}</s.BodyModal>
            <s.ButtonsWr>
              <s.SubmitButton
                buttonType={BUTTON_TYPE.Primary}
                onClick={confirmAction}
              >
                {primaryBtnText}
              </s.SubmitButton>
              <s.CancelButton
                buttonRef={cancelBtnRef}
                buttonType={BUTTON_TYPE.Secondary}
                onClick={onModalClose}
              >
                Cancel
              </s.CancelButton>
            </s.ButtonsWr>
          </s.ModalContainer>
        </FocusTrap>
      </s.Container>
    </Portal>
  );
};

export const ModalWindow: NamedExoticComponent<PropsWithChildren<IProps>> =
  React.memo<PropsWithChildren<IProps>>(ModalComponent);
