import styled from "styled-components";
import {
  Button,
  getTypographyStyles,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";
import { CustomLink as CommonCustomLink } from "src/common/styles";

export const Container = styled.div``;

export const CustomButton = styled(Button)<IButtonProps>`
  &:first-child {
    margin-right: 24px;
  }
  @media (max-width: ${DEVICE.TABLET}) {
    padding: 4px 0;
  }
`;

export const CustomCompleteButton = styled(CustomButton)<IButtonProps>`
  margin-top: 16px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin-top: 8px;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
  }
`;

export const ButtonText = styled.span`
  ${getTypographyStyles({ color: "royalBlue", variant: "16RegularL24" })}
`;

export const Content = styled.div`
  margin-top: 24px;
`;

export const CustomLink = CommonCustomLink;
