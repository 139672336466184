import ReactGA from "react-ga4";
import { azureAppConfigVar } from "src/cache";
import { CookiesValue, IExtendedWindow } from "src/types";
import { COOKIES_CONSENT_LC_KEY } from "src/constants/system";

interface ILogEvent {
  category: string;
  action: string;
  label?: string;
}

export interface IUseGA {
  init: () => void;
  logPageView: (pagePath: string) => void;
  logEvent: (event: ILogEvent) => void;
}

const googleAnalytics = (): IUseGA => {
  const isAccepted = (): boolean =>
    Boolean(
      localStorage.getItem(COOKIES_CONSENT_LC_KEY) === CookiesValue.Accept
    );
  const init = (): void => {
    if (
      !(window as unknown as IExtendedWindow).ga &&
      azureAppConfigVar().GOOGLE_ANALYTICS_TRACKING_ID &&
      isAccepted()
    ) {
      ReactGA.initialize(azureAppConfigVar().GOOGLE_ANALYTICS_TRACKING_ID);
    }
  };

  const logPageView = (pagePath: string): void => {
    if (isAccepted()) {
      ReactGA.send(pagePath);
    }
  };

  const logEvent = (event: ILogEvent): void => {
    if (isAccepted()) {
      ReactGA.event(event);
    }
  };

  return { init, logPageView, logEvent };
};

export default googleAnalytics;
