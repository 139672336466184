import { IMessagesNtc } from "src/types";
import { takeMessagesNtc } from "modules/messages/selectors";
import { IRootReducer } from "src/reducers";

export type MapState = {
  notifications: IMessagesNtc[];
};

export const mapState = (state: IRootReducer): MapState => ({
  notifications: takeMessagesNtc(state)
});
