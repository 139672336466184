import React from "react";
import { useSelector } from "react-redux";
import {
  takeCaseSectionsByAffectingField,
  takeDeclarationQuestions,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageData } from "modules/case/types";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { SectionDetails } from "../common/SectionDetails";

export const Declarations: React.FC = () => {
  const data: IPageData = useSelector(takeDeclarationQuestions);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const sectionsByAffectingField = useSelector(
    takeCaseSectionsByAffectingField
  );

  return (
    <SectionDetails
      sectionStatus={data.status}
      saveDraftStatusState={saveDraftStatusState}
      section={Section.DECLARATION}
      sectionsByAffectingField={sectionsByAffectingField}
      title="Declarations"
      description="Please disclose any public records that might be flagged by our checks.
        This includes any convictions, civil proceedings, and/or declarations of bankruptcy"
      questions={data.questions}
      isFieldsDisabled={isAllFormsDisabled}
    />
  );
};

export default Declarations;
