import React, { FC, memo, NamedExoticComponent } from "react";
import { BUTTON_TYPE, PlusSmallIcon } from "@security-watchdog/sw-ui-kit";
import { DefaultTheme, useTheme } from "styled-components";
import { IAddAnswerButtonProps } from "components/common/AddAnswerButton/types";
import * as s from "./styles";

export const AddAnswerButtonComponent: FC<IAddAnswerButtonProps> = ({
  onClick,
  isDisabled
}: IAddAnswerButtonProps) => {
  const theme: DefaultTheme = useTheme();

  return (
    <s.Button
      buttonType={BUTTON_TYPE.Link}
      icon={
        <PlusSmallIcon
          size={16}
          color={isDisabled ? theme.colors.stormGray : theme.colors.royalBlue}
        />
      }
      onClick={onClick}
      isDisabled={isDisabled}
    >
      Add Entry
    </s.Button>
  );
};

export const AddAnswerButton: NamedExoticComponent<IAddAnswerButtonProps> =
  memo<IAddAnswerButtonProps>(AddAnswerButtonComponent);
