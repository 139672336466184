import styled from "styled-components";
import { Button, getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import {
  IDeleteButtonTextProps,
  IStyledCardTagProps
} from "containers/common/SectionDetails/types";
import { DEVICE } from "src/constants";

export const QuestionsContainer = styled.div`
  border-radius: 4px;
  margin-top: 28px;
`;

export const QuestionWrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 22px 24px;
  margin-bottom: 8px;
`;

export const QuestionActionContainer = styled.div`
  padding-top: 22px;
  border-top: ${({ theme }): string => `1px solid ${theme.colors.snuff}`};
  margin-top: 22px;
`;

export const AnswerWrapper = styled.div`
  border-bottom: ${({ theme }): string => `1px solid ${theme.colors.snuff}`};
  margin-bottom: 22px;
  padding-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const AnswerActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomButton = styled(Button)`
  margin-top: 16px;
  justify-content: center;

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
  }
`;

export const DeleteButtonText = styled.span<IDeleteButtonTextProps>`
  color: ${({ theme, $isDisabled }): string =>
    $isDisabled ? theme.colors.stormGray : theme.colors.mojo};
`;

export const CardTag = styled.div<IStyledCardTagProps>`
  display: flex;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 16px;
  box-sizing: border-box;
  ${getTypographyStyles({ variant: "11MediumUppercaseL16" })};
  background: ${({ $backgroundColor }): string => $backgroundColor};

  color: ${({ $color }): string => $color};
`;

export const HeaderWrapper = styled.div`
  padding-top: 5px;
  position: relative;
  top: 0;
`;
