import React from "react";
import { useSelector } from "react-redux";
import {
  takeCaseSectionsByAffectingField,
  takePersonalRefereesQuestions,
  takeSaveDraftStatusState
} from "modules/case/selectors";
import { Section } from "src/graphQLTypes";
import { IDraftStatusState, IPageData } from "modules/case/types";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { SectionDetails } from "../common/SectionDetails";

export const PersonalReferees: React.FC = () => {
  const data: IPageData = useSelector(takePersonalRefereesQuestions);

  const saveDraftStatusState: IDraftStatusState = useSelector(
    takeSaveDraftStatusState
  );

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const sectionsByAffectingField = useSelector(
    takeCaseSectionsByAffectingField
  );

  return (
    <SectionDetails
      sectionStatus={data.status}
      saveDraftStatusState={saveDraftStatusState}
      section={Section.PERSONAL_REFEREES}
      sectionsByAffectingField={sectionsByAffectingField}
      title="Personal referees"
      description="Nominate one or more referees who can give details of your character and work..."
      questions={data.questions}
      isFieldsDisabled={isAllFormsDisabled}
    />
  );
};

export default PersonalReferees;
