import styled from "styled-components";
import { DEVICE } from "src/constants";
import { ISticky } from "./types";

export const MainContainer = styled.div<ISticky>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;

  @media (max-width: ${DEVICE.LAPTOP}) {
    margin-top: ${(props: ISticky): string => (props.$isSticky ? "82px" : "0")};
  }
`;

export const Content = styled.div`
  width: 795px;
  padding: 40px 0 30px;
  box-sizing: border-box;

  @media (max-width: ${DEVICE.LAPTOP}) {
    padding: 25px 16px 30px;
    max-width: 100%;
  }
`;
