import React, { useCallback, useState } from "react";
import { BUTTON_TYPE } from "@security-watchdog/sw-ui-kit";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";
import { CookiesValue } from "../../types";
import * as s from "./styles";
import { COOKIES_CONSENT_LC_KEY } from "src/constants/system";

const DisclaimerCookieComponent: React.FC = () => {
  const { init }: IUseGA = googleAnalytics();
  const setInitialValue = (): boolean =>
    Boolean(localStorage.getItem(COOKIES_CONSENT_LC_KEY));
  const [isShowDisclaimer, setIsShowDisclaimer]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = useState<boolean>(!setInitialValue());

  const setCookies = useCallback(
    (value: CookiesValue) => (): void => {
      localStorage.setItem(COOKIES_CONSENT_LC_KEY, value);
      setIsShowDisclaimer(false);
      if (value === CookiesValue.Accept) {
        init();
      }
    },
    [init]
  );
  return isShowDisclaimer ? (
    <s.ContainerDisclaimer>
      <s.ContainerTextInfo>
        <s.PrimaryText>Use of cookies</s.PrimaryText>
        <s.SecondaryText>
          Security Watchdog uses cookies which are essential for the site to
          work. We’d also like to set optional analytics cookies to help us
          improve our services. Any data collected is anonymised. For more
          information please see our Cookies page
        </s.SecondaryText>
      </s.ContainerTextInfo>
      <s.ContainerButton>
        <s.CustomButton
          buttonType={BUTTON_TYPE.Link}
          onClick={setCookies(CookiesValue.Decline)}
        >
          Decline
        </s.CustomButton>
        <s.CustomButton
          buttonType={BUTTON_TYPE.Primary}
          onClick={setCookies(CookiesValue.Accept)}
        >
          Accept
        </s.CustomButton>
      </s.ContainerButton>
    </s.ContainerDisclaimer>
  ) : null;
};

export const DisclaimerCookie = React.memo(DisclaimerCookieComponent);
