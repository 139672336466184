import styled, { css } from "styled-components";

export const FooterActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;

export const FooterActionButtons = styled.div<{ $isMobile: boolean }>`
  display: flex;
  gap: 16px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100%;
      button {
        justify-content: center;
        width: 50%;
      }
    `}
`;
