import styled, { css } from "styled-components";
import { CandidateCaseStatus } from "src/graphQLTypes";

type BarContainerProps = {
  status: CandidateCaseStatus;
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const BarContainer = styled.div<BarContainerProps>`
  display: flex;
  gap: calc(var(--unit) * 0.5);

  & > span {
    background-color: var(--color-divider-default);
    height: calc(var(--unit) * 2);
    width: calc(var(--unit) * 4);

    &:first-child {
      border-top-left-radius: calc(var(--unit) * 2);
      border-bottom-left-radius: calc(var(--unit) * 2);
    }

    &:last-child {
      border-top-right-radius: calc(var(--unit) * 2);
      border-bottom-right-radius: calc(var(--unit) * 2);
    }
  }

  ${({ status }) => {
    switch (status) {
      case CandidateCaseStatus.SUBMITTED: {
        return css`
          & > span:first-child {
            background-color: var(--color-icon-success);
          }
        `;
      }

      case CandidateCaseStatus.IN_PROGRESS: {
        return css`
          & > span:first-child,
          & > span:nth-child(2) {
            background-color: var(--color-icon-success);
          }
        `;
      }
      case CandidateCaseStatus.CANDIDATE_ACTION_REQUIRED: {
        return css`
          & > span:first-child {
            background-color: var(--color-icon-success);
          }
          & > span:nth-child(2) {
            background-color: var(--color-icon-warning);
          }
        `;
      }
      default:
        return undefined;
    }
  }}
`;
