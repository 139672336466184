import styled from "styled-components";

export const FooterActionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: calc(var(--unit) * 4);

  & button {
    width: 100%;
  }
`;
