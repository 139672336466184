import { gql } from "@apollo/client";

export const RESPOND_TO_DATA_REQUEST_MUTATION = gql`
  mutation respondToDataRequest(
    $files: [Upload!]
    $input: RespondToCandidateDataRequestInput!
  ) {
    respondToDataRequest(files: $files, input: $input) {
      product {
        id
      }
    }
  }
`;
