import styled from "styled-components";
import {
  Button as ButtonComponent,
  IButtonProps
} from "@security-watchdog/sw-ui-kit";

export const Button = styled(ButtonComponent)<IButtonProps>`
  margin-left: 16px;
  margin-bottom: 30px;
`;
