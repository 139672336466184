import { Icon } from "@security-watchdog/ui-components";
import React, { FC } from "react";
import * as s from "./styles";
import { CaseSubmittedAlert } from "containers/SuccessSubmit/components/CandidateChecks/components";

export const EmptyState: FC = () => (
  <>
    <CaseSubmittedAlert />

    <h2 className="h2 margin-t-6">Your checks</h2>
    <s.Container>
      <s.Wrapper>
        <Icon
          name="grades-table"
          size={24}
          scale={2}
          className="color-text-subdued"
        />
        <p className="body-highlight margin-t-4">No assigned checks yet</p>
        <p className="body-wide-line color-text-subdued">
          Please wait while a screener will assign necessary checks to your form
        </p>
      </s.Wrapper>
    </s.Container>
  </>
);
