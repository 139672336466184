import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import {
  IUseLoadScripts,
  useLoadScripts
} from "components/eSignatureModal/useLoadScripts";
import { DEVICE } from "src/constants";
import { useMount } from "src/hooks/useMount";
import { useUnmount } from "src/hooks/useUnmount";
import { addMessageNtc } from "modules/messages/actions";
import { TYPE_NOTIFICATION } from "@security-watchdog/sw-ui-kit";
import * as s from "./styles";

const INIT_DRAWING_CANVAS_ERROR_MESSAGE =
  "The drawing canvas has not been initialized. Please close and open modal or refresh the page.";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
  }
}

export const DrawSignatureControl = forwardRef<HTMLDivElement>((_, ref) => {
  const signatureRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);

  const [windowMatchMedia, setWindowMatchMedia] = useState<boolean>(false);
  const [isPlaceholderShown, setIsPlaceholderShown] = useState<boolean>(true);

  const dispatch: Dispatch = useDispatch();
  const { isScriptsReady }: IUseLoadScripts = useLoadScripts();

  const showError = useCallback(() => {
    dispatch(
      addMessageNtc({
        id: uuidv4(),
        type: TYPE_NOTIFICATION.Error,
        message: INIT_DRAWING_CANVAS_ERROR_MESSAGE
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (signatureRef.current && isScriptsReady) {
      try {
        window
          .$(signatureRef.current)
          .jSignature({ height: "100%", width: "100%" });
      } catch (e) {
        showError();
      }
    }
  }, [isScriptsReady, showError]);

  useEffect(() => {
    if (signatureRef.current && isScriptsReady) {
      try {
        window.$(signatureRef.current).jSignature("reset");
      } catch (e) {
        showError();
      }
    }
  }, [windowMatchMedia, isScriptsReady, showError]);

  const resizeFn = useCallback(() => {
    const matchMedia =
      window.matchMedia(`(max-width: ${DEVICE.TABLET})`).matches ||
      window.matchMedia(`(max-width: ${DEVICE.MOBILE_L})`).matches ||
      window.matchMedia(`(max-width: ${DEVICE.MOBILE_M})`).matches ||
      window.matchMedia(`(max-width: ${DEVICE.MOBILE_S})`).matches;

    const prevMatch = windowMatchMedia === matchMedia;

    setWindowMatchMedia(!prevMatch);
  }, [windowMatchMedia]);

  const onDrawerClick = useCallback(() => {
    setIsPlaceholderShown((prevState) => (prevState ? !prevState : prevState));
  }, []);

  useMount(() => {
    window.addEventListener("resize", resizeFn);
  });

  useUnmount(() => {
    window.removeEventListener("resize", resizeFn);

    if (signatureRef.current) {
      try {
        window.$(signatureRef.current).jSignature("destroy");
      } catch (e) {
        showError();
      }
    }
  });

  return (
    <s.Wrapper ref={ref} onMouseDown={onDrawerClick}>
      <div id="signature" ref={signatureRef} />
      {isPlaceholderShown && (
        <s.Placeholder>Draw your signature here</s.Placeholder>
      )}
    </s.Wrapper>
  );
});

DrawSignatureControl.displayName = "DrawSignatureControl";
