import {
  getCase_screeningCase_sections_answers,
  TrustIDApplicationStatus
} from "src/graphQLTypes";
import { TrustIdButtonState } from "./types";
import {
  getTrustIdFirstDifficultiesBooleanAnswerValue,
  isTrustIdApplicationSubmitted
} from "./utils";
import { IS_TRUST_ID_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY } from "src/constants/system";

type UseDifficultiesBtnState = {
  buttonState: TrustIdButtonState;
  setDifficultiesButtonVisibilityToStorage: () => void;
};

type UseDifficultiesBtnStateArgs = {
  sectionAnswers: getCase_screeningCase_sections_answers[];
  trustIdStatus: TrustIDApplicationStatus | null;
};

export const useDifficultiesBtnState = ({
  sectionAnswers,
  trustIdStatus
}: UseDifficultiesBtnStateArgs): UseDifficultiesBtnState => {
  const isDifficultiesButtonVisible = sessionStorage.getItem(
    IS_TRUST_ID_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY
  );

  const setDifficultiesButtonVisibilityToStorage = () =>
    sessionStorage.setItem(
      IS_TRUST_ID_DIFFICULTIES_BUTTON_VISIBLE_STORAGE_KEY,
      "true"
    );

  const difficultiesQuestionAnswerValue =
    getTrustIdFirstDifficultiesBooleanAnswerValue(sectionAnswers);

  const getDifficultiesButtonState = (): TrustIdButtonState => {
    if (
      isTrustIdApplicationSubmitted(
        trustIdStatus,
        difficultiesQuestionAnswerValue
      )
    ) {
      return {
        isDisabled: true,
        isVisible: true
      };
    }

    if (difficultiesQuestionAnswerValue === null) {
      return {
        isDisabled: false,
        isVisible: !!isDifficultiesButtonVisible
      };
    }

    if (difficultiesQuestionAnswerValue) {
      return {
        isDisabled: true,
        isVisible: true
      };
    }

    return {
      isDisabled: false,
      isVisible: true
    };
  };

  return {
    buttonState: getDifficultiesButtonState(),
    setDifficultiesButtonVisibilityToStorage
  };
};
