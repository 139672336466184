import {
  fieldAnyValueFragment,
  fieldAnyValueFragment_Address,
  fieldAnyValueFragment_Attachment,
  fieldAnyValueFragment_Attachments,
  fieldAnyValueFragment_BooleanValue,
  fieldAnyValueFragment_Company,
  fieldAnyValueFragment_Country,
  fieldAnyValueFragment_DateRange,
  fieldAnyValueFragment_DateValue,
  fieldAnyValueFragment_FloatValue,
  fieldAnyValueFragment_QualificationsAndGrades,
  fieldAnyValueFragment_StringValue,
  fieldAnyValueFragment_StringValues,
  fieldRestrictionFragment,
  fieldRestrictionFragment_DateRestriction,
  fieldRestrictionFragment_DurationRestriction,
  fieldRestrictionFragment_GapRestriction,
  fieldRestrictionFragment_LengthRestriction,
  fieldRestrictionFragment_NumericRestriction,
  fieldRestrictionFragment_SelectRestriction,
  getCase_screeningCase_sections_answers_question_fields_opsFields,
  getCase_screeningCase_sections_answers_question_fields_opsFields_OpsEbulkFieldValue,
  getCase_screeningCase_sections_answers_question_fields_opsFields_OpsOcrFieldValue
} from "../graphQLTypes";
import { FileForValidation } from "src/types/documents";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUndefined = (value: any): value is undefined =>
  typeof value === "undefined";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNull = (value: any): value is null => value === null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUndefinedOrNull = (value: any): value is undefined | null =>
  isUndefined(value) || isNull(value);

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const isStringValue = (
  value: fieldAnyValueFragment | null
): value is fieldAnyValueFragment_StringValue =>
  value?.__typename === "StringValue";

export const isQualificationAndGradesValues = (
  value: fieldAnyValueFragment | null
): value is fieldAnyValueFragment_QualificationsAndGrades =>
  value?.__typename === "QualificationsAndGrades";

export const isStringValues = (
  value: fieldAnyValueFragment | null
): value is fieldAnyValueFragment_StringValues =>
  value?.__typename === "StringValues";

export const isFloatValue = (
  value: fieldAnyValueFragment | null
): value is fieldAnyValueFragment_FloatValue =>
  value?.__typename === "FloatValue";

export const isDateValue = (
  value: fieldAnyValueFragment | null
): value is fieldAnyValueFragment_DateValue =>
  value?.__typename === "DateValue";

export const isCountryValue = (
  value: fieldAnyValueFragment
): value is fieldAnyValueFragment_Country => value.__typename === "Country";

export const isCompanyValue = (
  value: fieldAnyValueFragment
): value is fieldAnyValueFragment_Company => value.__typename === "Company";

export const isBooleanValue = (
  value: fieldAnyValueFragment
): value is fieldAnyValueFragment_BooleanValue =>
  value.__typename === "BooleanValue";

export const isAttachmentValue = (
  value: unknown
): value is fieldAnyValueFragment_Attachment =>
  (value as fieldAnyValueFragment)?.__typename === "Attachment";

export const isArrayOfAttachments = (
  value: unknown
): value is fieldAnyValueFragment_Attachment[] =>
  !!value && Array.isArray(value) && value.every(isAttachmentValue);

export const isAttachmentsValue = (
  value: fieldAnyValueFragment
): value is fieldAnyValueFragment_Attachments =>
  value.__typename === "Attachments";

export const isUploadFile = (value: unknown): value is File =>
  Boolean(value) && (value as File).lastModified !== undefined;

export const isUploadFileForValidation = (
  value: unknown
): value is FileForValidation =>
  Boolean(value) &&
  (value as FileForValidation).name !== undefined &&
  (value as FileForValidation).size !== undefined;

export const isUploadFiles = (value: unknown): value is File[] =>
  Array.isArray(value) && Boolean(value.length) && value.every(isUploadFile);

export const isUploadFilesForValidation = (
  value: unknown
): value is FileForValidation[] =>
  Array.isArray(value) &&
  Boolean(value.length) &&
  value.every(isUploadFileForValidation);

export const isAddressValue = (
  value: fieldAnyValueFragment
): value is fieldAnyValueFragment_Address => value.__typename === "Address";

export const isDateRangeValue = (
  value: fieldAnyValueFragment | null
): value is fieldAnyValueFragment_DateRange =>
  value?.__typename === "DateRange";

/*
 *   Field Restrictions
 * */

export const isSelectRestriction = (
  restriction: fieldRestrictionFragment
): restriction is fieldRestrictionFragment_SelectRestriction =>
  restriction.__typename === "SelectRestriction";

export const isDurationRestriction = (
  restriction: fieldRestrictionFragment
): restriction is fieldRestrictionFragment_DurationRestriction =>
  restriction.__typename === "DurationRestriction";

export const isGapRestriction = (
  restriction: fieldRestrictionFragment
): restriction is fieldRestrictionFragment_GapRestriction =>
  restriction.__typename === "GapRestriction";

export const isLengthRestriction = (
  restriction: fieldRestrictionFragment
): restriction is fieldRestrictionFragment_LengthRestriction =>
  restriction.__typename === "LengthRestriction";

export const isNumericRestriction = (
  restriction: fieldRestrictionFragment
): restriction is fieldRestrictionFragment_NumericRestriction =>
  restriction.__typename === "NumericRestriction";

export const isDateRestriction = (
  restriction: fieldRestrictionFragment
): restriction is fieldRestrictionFragment_DateRestriction =>
  restriction.__typename === "DateRestriction";

export const isOpsEbulkFieldValue = (
  opsFieldValue: getCase_screeningCase_sections_answers_question_fields_opsFields
): opsFieldValue is getCase_screeningCase_sections_answers_question_fields_opsFields_OpsEbulkFieldValue =>
  opsFieldValue.__typename === "OpsEbulkFieldValue";

export const isOcrFieldValue = (
  ocrValue: getCase_screeningCase_sections_answers_question_fields_opsFields
): ocrValue is getCase_screeningCase_sections_answers_question_fields_opsFields_OpsOcrFieldValue =>
  ocrValue.__typename === "OpsOcrFieldValue";
