import React, {
  FC,
  memo,
  PropsWithChildren,
  useContext,
  useEffect
} from "react";
import { TroubleshootingRequestStatus } from "src/graphQLTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  takeIsAcceptingRequestInProgress,
  takeIsCancelRequestInProgress,
  takeScreenerAccessStatus
} from "modules/screenerAccess/selectors";
import * as screenerAccessActions from "modules/screenerAccess/actions";
import * as caseActions from "modules/case/actions";
import { useInterval } from "src/hooks/useInterval";
import {
  INTERVAL_OF_POLLING_SCREENER_ACCESS_DATA,
  INTERVAL_OF_POLLING_SCREENER_DRAFT_DATA
} from "src/constants";
import { Dispatch } from "redux";
import { AuthContext } from "src/context/auth";
import { sessionIsTerminatedError } from "src/cache";
import { useReactiveVar } from "@apollo/client";
import { useUrlSearchParams } from "src/hooks/useUrlSearchParams";

const Component: FC<PropsWithChildren> = ({ children }) => {
  const dispatch: Dispatch = useDispatch();

  const sessionTerminatedError = useReactiveVar(sessionIsTerminatedError);

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    if (sessionTerminatedError) {
      logout();
    }
  }, [logout, sessionTerminatedError]);

  const { caseId } = useUrlSearchParams();

  const screenerAccessStatus: TroubleshootingRequestStatus | null = useSelector(
    takeScreenerAccessStatus
  );

  const isAcceptingRequestInProgress: boolean = useSelector(
    takeIsAcceptingRequestInProgress
  );

  const isCancelRequestInProgress: boolean = useSelector(
    takeIsCancelRequestInProgress
  );

  const handleGetActiveTroubleShootingRequest = (): void => {
    if (caseId && !isAcceptingRequestInProgress && !isCancelRequestInProgress) {
      dispatch(
        screenerAccessActions.getActiveTroubleShootingRequest.started({
          caseId
        })
      );
    }
  };

  const handlePullDraftData = (): void => {
    if (
      screenerAccessStatus ===
        TroubleshootingRequestStatus.CANDIDATE_ACCEPTED &&
      caseId
    ) {
      dispatch(caseActions.pullDraftData.started({ id: caseId }));
    }
  };

  useInterval(handlePullDraftData, INTERVAL_OF_POLLING_SCREENER_DRAFT_DATA);

  useInterval(
    handleGetActiveTroubleShootingRequest,
    INTERVAL_OF_POLLING_SCREENER_ACCESS_DATA
  );

  useEffect((): void => {
    if (caseId) {
      dispatch(
        screenerAccessActions.getActiveTroubleShootingRequest.started({
          caseId
        })
      );

      dispatch(caseActions.getCase.started({ id: caseId }));
    }
  }, [caseId, dispatch]);

  return <>{children}</>;
};

Component.displayName = "AppWrapper";

export const AppWrapper = memo(Component);
