import React, { FC } from "react";
import { SuccessSubmitNew } from "containers/SuccessSubmit/SuccessSubmitNew";
import { SuccessSubmitOld } from "containers/SuccessSubmit/SuccessSubmitOld";
import { azureAppConfigVar } from "src/cache";

export const SuccessSubmit: FC = () =>
  azureAppConfigVar().featureToggle.ENABLE_CANDIDATE_SELF_SERVICE ? (
    <SuccessSubmitNew />
  ) : (
    <SuccessSubmitOld />
  );
