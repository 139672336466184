import { subDays, subMonths, subYears } from "date-fns";
import { DateUnit } from "../graphQLTypes";

export const getStartDateByUnit = (
  unit: DateUnit,
  duration: number,
  endDateTimestamp: number | Date
): Date => {
  switch (unit) {
    case DateUnit.YEAR:
      return subYears(new Date(endDateTimestamp), duration);

    case DateUnit.MONTH:
      return subMonths(new Date(endDateTimestamp), duration);

    case DateUnit.DAY:
      return subDays(new Date(endDateTimestamp), duration);
    default:
      return new Date();
  }
};
