import { getMsalInstance } from "src/config";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const acquireNewToken = async () => {
  const instance = getMsalInstance();

  await instance.initialize();

  const account = instance.getActiveAccount();

  try {
    const { idToken } = await instance.acquireTokenSilent({
      account: account || undefined,
      scopes: []
    });

    return idToken || "";
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect({
        account: account || undefined,
        scopes: []
      });
    } else {
      throw error;
    }
  }
};

const getHeaderAuthTokenValue = (token: string): string =>
  token ? `Bearer ${token}` : "";

const isTokenExpired = () => {
  const acc = getMsalInstance().getActiveAccount();
  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  const bufferTime = 60; // 1 minute buffer

  if (acc?.idTokenClaims?.exp) {
    return acc.idTokenClaims.exp < currentTime + bufferTime;
  }

  return true;
};

export const getSessionAuthToken = async (): Promise<string> => {
  if (isTokenExpired()) {
    const token = await acquireNewToken();

    return getHeaderAuthTokenValue(token || "");
  }

  return getHeaderAuthTokenValue(
    getMsalInstance().getActiveAccount()?.idToken || ""
  );
};

export async function getToken() {
  const token = await acquireNewToken();

  return getHeaderAuthTokenValue(token || "");
}
