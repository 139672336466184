import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";
import { DEVICE } from "src/constants";

export const extraContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerModal = styled.div`
  width: 768px;
  background-color: ${({ theme }): string => theme.colors.white};
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }): number => theme.layers.modal};

  @media (max-width: ${DEVICE.TABLET}) {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
`;

export const HeaderModal = styled.div`
  min-height: 72px;
  background-color: ${({ theme }): string => theme.colors.whiteLilac};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(221, 222, 237, 0.5);
`;

export const HeaderTitle = styled.span`
  ${getTypographyStyles({ color: "tangaroa", variant: "24BoldL32" })}
`;

export const FooterModal = styled.div`
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px rgba(221, 222, 237, 0.5);
`;

export const BodyModal = styled.div`
  padding: 26px 40px;
  box-sizing: border-box;
  max-height: 480px;
  overflow-y: auto;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-grow: 1;
    max-height: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderDescription = styled.span`
  ${getTypographyStyles({ variant: "16MediumL24", color: "stormGray" })}
`;
