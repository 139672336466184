import * as cases from "modules/case/reducers";
import * as main from "modules/main/reducers";
import * as messagesNtc from "modules/messages/reducers";
import * as screenerAccess from "modules/screenerAccess/reducers";
import { AnyAction, CombinedState, combineReducers, Reducer } from "redux";

export interface IRootReducer {
  case: cases.IReducer;
  main: main.IReducer;
  messages: messagesNtc.IReducer;
  screenerAccess: screenerAccess.IReducer;
}

const rootReducer: Reducer<
  CombinedState<IRootReducer>,
  AnyAction
> = combineReducers<IRootReducer>({
  case: cases.reducers,
  main: main.reducers,
  messages: messagesNtc.reducers,
  screenerAccess: screenerAccess.reducers
});

export default rootReducer;
