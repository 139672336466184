import React, { FC } from "react";
import * as s from "./styles";
import {
  CheckList,
  EmptyState
} from "containers/SuccessSubmit/components/CandidateChecks/components";
import {
  CandidateCaseStatus,
  GetCaseInformation_screeningCase_products
} from "src/graphQLTypes";
import { OpenedProduct } from "containers/SuccessSubmit/hooks";

type Props = {
  products: GetCaseInformation_screeningCase_products[];
  caseStatus?: CandidateCaseStatus | null;
  onActionClick: (product: OpenedProduct) => void;
};

export const CandidateChecks: FC<Props> = ({
  products,
  caseStatus,
  onActionClick
}) => (
  <s.Container>
    {products.length && caseStatus !== CandidateCaseStatus.SUBMITTED ? (
      <CheckList
        products={products}
        caseStatus={caseStatus}
        onActionClick={onActionClick}
      />
    ) : (
      <EmptyState />
    )}
  </s.Container>
);
