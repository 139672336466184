import styled from "styled-components";

import { DEVICE } from "src/constants";

export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 2);
  margin-top: calc(var(--unit) * 4);
`;

export const CheckListItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--unit) * 2);
  border-radius: var(--unit);
  border: 1px solid var(--color-stroke-default);
  background: var(--color-surface-default);
  padding: calc(var(--unit) * 4);

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column-reverse;
    align-items: normal;
    gap: calc(var(--unit) * 1);
  }
`;

export const CheckListItemNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: ${DEVICE.TABLET}) {
    flex-direction: column;
    align-items: normal;
  }
`;
