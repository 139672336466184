import { debounce } from "lodash";
import { useEffect, useState } from "react";

type DeviceType = "desktop" | "tablet" | "mobile" | undefined;

enum Devices {
  DESKTOP = "desktop",
  TABLET = "tablet",
  MOBILE = "mobile"
}

const getDeviceTypeFromMediaQuery = (): DeviceType => {
  if (!window) return;

  if (window.matchMedia("(min-width: 1200px)").matches) {
    return Devices.DESKTOP;
  } else if (window.matchMedia("(min-width: 768px)").matches) {
    return Devices.TABLET;
  } else {
    return Devices.MOBILE;
  }
};

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<DeviceType>(
    getDeviceTypeFromMediaQuery()
  );

  useEffect(() => {
    const handleResize = () => setDeviceType(getDeviceTypeFromMediaQuery());
    const debouncedResize = debounce(handleResize, 200);

    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  return {
    isDesktop: deviceType === Devices.DESKTOP,
    isTablet: deviceType === Devices.TABLET,
    isMobile: deviceType === Devices.MOBILE
  };
};
