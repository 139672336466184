import React, { FC, memo, useContext } from "react";
import { MsalAuthenticationResult } from "@azure/msal-react/dist/hooks/useMsalAuthentication";
import { MultiFactorErrorScreen } from "components/MultiFactorErrorScreen";
import { Footer } from "components/Footer";
import { AuthContext } from "src/context/auth";

const Component: FC<MsalAuthenticationResult> = ({ error }) => {
  const { logout } = useContext(AuthContext);

  if (error?.errorMessage.includes("AADB2C90151")) {
    return (
      <>
        <MultiFactorErrorScreen onRetryClick={logout} />
        <Footer />
      </>
    );
  }

  return null;
};

Component.displayName = "AzureErrors";

export const AzureErrors = memo(Component);
