import React, { FC, useEffect, useMemo } from "react";
import { LoaderContainer } from "@security-watchdog/sw-ui-kit/dist/Table/styles";
import { Loader } from "@security-watchdog/ui-components";
import { Header as CommonHeader } from "components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useKonfirVerificationSubmitted } from "containers/KonfirPage/hooks";
import { useMount } from "src/hooks/useMount";
import {
  KONFIR_REDIRECT_CASE_ID,
  KONFIR_REDIRECT_JOURNEY_KEY,
  KONFIR_REDIrECT_JOURNEY_SUBMITTED_KEY
} from "src/constants/system";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import * as caseActions from "modules/case/actions";
import { ROUTES } from "src/constants";
import { createQueryParams } from "src/utils/createQueryParams";

export const KonfirRedirectPage: FC = () => {
  const dispatch: Dispatch = useDispatch();

  const location = useLocation();

  const navigateTo = useNavigate();

  const { onKonfirVerificationSubmitted, isLoading } =
    useKonfirVerificationSubmitted();

  const urlParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return {
      journey: searchParams.get(KONFIR_REDIRECT_JOURNEY_KEY),
      caseId: searchParams.get(KONFIR_REDIRECT_CASE_ID)
    };
  }, [location.search]);

  useMount(async () => {
    if (
      urlParams.caseId &&
      urlParams.journey === KONFIR_REDIrECT_JOURNEY_SUBMITTED_KEY
    ) {
      await onKonfirVerificationSubmitted(urlParams.caseId);
    }
  });

  useEffect(() => {
    if (!isLoading && urlParams.caseId) {
      dispatch(caseActions.getCase.started({ id: urlParams.caseId }));

      navigateTo({
        pathname: ROUTES.ROOT,
        search: createQueryParams(encodeURI(urlParams.caseId))
      });
    }
  }, [dispatch, isLoading, navigateTo, urlParams.caseId]);

  return (
    <>
      <CommonHeader />
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </>
  );
};
