import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const TextAreaHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const TextAreaTitle = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;

export const Container = styled.div`
  margin-bottom: 24px;
`;
