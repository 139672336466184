import React, { FC, useCallback } from "react";
import * as s from "./styles";
import { ProductStatusBadge } from "containers/SuccessSubmit/components/CandidateChecks/components";
import { Button, Icon } from "@security-watchdog/ui-components";
import { OpenedProduct } from "containers/SuccessSubmit/hooks";

type Props = {
  product: OpenedProduct;
  onActionClick: (product: OpenedProduct) => void;
};

export const CheckListItem: FC<Props> = ({ onActionClick, product }) => {
  const handleActionClick = useCallback(
    () => onActionClick(product),
    [product, onActionClick]
  );

  return (
    <s.CheckListItemContainer>
      <s.CheckListItemNameContainer>
        <span className="body-highlight">{product.name}</span>
        {product.candidateDataRequest && (
          <div>
            <Button variant="ghost" onClick={handleActionClick}>
              <Icon name="eye-opened" size={16} className="margin-r-1" />
              <span>View requested information</span>
            </Button>
          </div>
        )}
      </s.CheckListItemNameContainer>
      <ProductStatusBadge status={product.status} />
    </s.CheckListItemContainer>
  );
};
