import styled from "styled-components";
import { getTypographyStyles } from "@security-watchdog/sw-ui-kit";

export const Content = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }): string => theme.colors.snuff};
  margin: 24px 0;
  padding: 15px 24px;
  box-sizing: border-box;
`;

export const Title = styled.h4`
  ${getTypographyStyles({ variant: "16MediumL24" })};
  margin-top: 0;
  margin-bottom: 8px;
`;

export const ContainerInfo = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

export const CircleNumber = styled.span`
  position: relative;
  top: -1px;
`;

export const Text = styled.span`
  ${getTypographyStyles({ variant: "16RegularL24" })};
  padding-left: 16px;
`;
