import { takeMessagesNtc } from "modules/messages/selectors";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Notification } from "@security-watchdog/sw-ui-kit";
import * as actionsNtc from "../../modules/messages/actions";
import { IMessagesNtc } from "../../types";

const MessagesNtcComponent: React.FC = () => {
  const messages: IMessagesNtc[] = useSelector(takeMessagesNtc);
  const dispatch: Dispatch = useDispatch();

  const clearNtc = useCallback(
    (id: string) => (): void => {
      const updatedNtc = messages.filter((ntc: IMessagesNtc) => ntc.id !== id);
      dispatch(actionsNtc.updateMessageNtc(updatedNtc));
    },
    [dispatch, messages]
  );

  return (
    <>
      {messages.map((ntc: IMessagesNtc) => (
        <Notification
          key={ntc.id}
          type={ntc.type}
          message={ntc.message}
          lifetime={15000}
          onClose={clearNtc(ntc.id)}
        />
      ))}
    </>
  );
};

export const MessagesNtc = React.memo(MessagesNtcComponent);
