import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { TYPE_NOTIFICATION } from "@security-watchdog/sw-ui-kit";
import { v4 as uuidv4 } from "uuid";

import CREATE_TRUST_ID_GUEST_LINK_QUERY from "api/graphql/mutations/CandidateSubmitTrustId/createTrustIdGuestLink.gql";
import {
  createTrustIdGuestLinkVariables,
  createTrustIdGuestLink,
  getCase_screeningCase_sections_answers,
  caseUpdateVariables
} from "src/graphQLTypes";
import { buildAnswersInput } from "src/utils/buildInputForCaseUpdate";
import { buildFieldKey } from "src/utils";
import * as actionsMsgNtc from "modules/messages/actions";
import * as caseActions from "modules/case/actions";
import { getTrustIdFirstDifficultiesBooleanAnswerValue } from "./utils";
import { CREATE_TRUST_ID_GUEST_LINK_ERROR_MESSAGE } from "./constants";

type UseCreateTrustIdGuestLink = {
  createTrustIdGuestLink: () => Promise<string>;
  submitTrustIdSection: (candidateAnswer: boolean) => void;
};

type UseCreateTrustIdGuestLinkArgs = {
  caseId: string;
  sectionAnswers: getCase_screeningCase_sections_answers[];
};

export const useCreateTrustIdGuestLink = ({
  caseId,
  sectionAnswers
}: UseCreateTrustIdGuestLinkArgs): UseCreateTrustIdGuestLink => {
  const [createLinkRequest] = useMutation<
    createTrustIdGuestLink,
    createTrustIdGuestLinkVariables
  >(CREATE_TRUST_ID_GUEST_LINK_QUERY, {
    variables: {
      input: {
        caseId
      }
    }
  });
  const dispatch: Dispatch = useDispatch();

  const difficultiesQuestionAnswerValue =
    getTrustIdFirstDifficultiesBooleanAnswerValue(sectionAnswers);

  const showErrorMessage = useCallback(() => {
    dispatch(
      actionsMsgNtc.addMessageNtc({
        id: uuidv4(),
        type: TYPE_NOTIFICATION.Error,
        message: CREATE_TRUST_ID_GUEST_LINK_ERROR_MESSAGE
      })
    );
  }, [dispatch]);

  const getTrustIdCandidateAnswerForCaseUpdate = useCallback(
    (candidateAnswer: boolean): caseUpdateVariables => {
      const questionId = sectionAnswers?.[0].question.id;
      const answerId = sectionAnswers?.[0].answers?.[0]?.id;
      const field = sectionAnswers?.[0].question.fields?.[0];
      const formFieldKey: string = buildFieldKey({
        questionId,
        answerId,
        fieldId: field?.id,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: false
      });
      const formData = { [formFieldKey]: candidateAnswer };
      const updatedField = {
        fieldId: field?.id,
        title: field?.title.current,
        type: field?.type,
        fieldType: field?.fieldType,
        description: field?.description.current,
        value: candidateAnswer,
        isConditional: field?.conditional,
        isVisible: true,
        hasDependentFields: false,
        conditions: [],
        validation: {
          isMulti: false,
          isRequired: false,
          dependentField: undefined
        },
        isMulti: false,
        isRoleLocationScotland: false,
        answerIndex: 0,
        isHighlightedWhenOcrUploaded: false
      };
      const updatedAnswer = {
        answerId,
        isAnswered: true,
        fields: [updatedField],
        index: 0
      };

      return {
        files: [],
        input: {
          caseId,
          consentGiven: false,
          answers: [
            {
              questionId: questionId,
              answers: buildAnswersInput({
                answers: [updatedAnswer],
                data: formData,
                questionId: questionId
              })
            }
          ]
        }
      };
    },
    [sectionAnswers, caseId]
  );

  const submitTrustIdSection = useCallback(
    (candidateAnswer: boolean): void => {
      const isAlreadyAnswered =
        difficultiesQuestionAnswerValue === candidateAnswer;

      if (!isAlreadyAnswered) {
        dispatch(
          caseActions.caseUpdate.started({
            caseInput: getTrustIdCandidateAnswerForCaseUpdate(candidateAnswer)
          })
        );
      }
    },
    [
      dispatch,
      getTrustIdCandidateAnswerForCaseUpdate,
      difficultiesQuestionAnswerValue
    ]
  );

  const createTrustIdGuestLink = useCallback(async (): Promise<string> => {
    try {
      const response = await createLinkRequest();
      const link = response.data?.trustIdGuestLinkCreate.guestLink;

      if (link) {
        return Promise.resolve(link);
      }

      showErrorMessage();
      return Promise.resolve("");
    } catch (e) {
      showErrorMessage();
      return Promise.resolve("");
    }
  }, [showErrorMessage, createLinkRequest]);

  return {
    createTrustIdGuestLink,
    submitTrustIdSection
  };
};
