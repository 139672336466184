import actionCreatorFactory, {
  ActionCreatorFactory,
  AsyncActionCreators
} from "typescript-fsa";
import {
  doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest,
  doCaseTroubleshootingRequestUpdateVariables,
  getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest,
  getActiveTroubleShootingRequestVariables
} from "../../graphQLTypes";
import { IErrorType } from "src/types";

const actionCreator: ActionCreatorFactory =
  actionCreatorFactory("SCREENER_ACCESS");

export const getActiveTroubleShootingRequest: AsyncActionCreators<
  getActiveTroubleShootingRequestVariables,
  getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest | null,
  IErrorType
> = actionCreator.async<
  getActiveTroubleShootingRequestVariables,
  getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest | null,
  IErrorType
>("GET_ACTIVE_TROUBLE_SHOOTING_REQUEST");

export const doCaseTroubleshootingRequestUpdate: AsyncActionCreators<
  doCaseTroubleshootingRequestUpdateVariables,
  doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest,
  IErrorType
> = actionCreator.async<
  doCaseTroubleshootingRequestUpdateVariables,
  doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest,
  IErrorType
>("DO_CASE_TROUBLE_SHOOTING_REQUEST_UPDATE");
