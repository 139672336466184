type IUnit =
  | "year"
  | "month"
  | "day"
  | "hour"
  | "minute"
  | "second"
  | "millisecond";

export const getStartOf = (dateTime: DateTime, unit: IUnit): DateTime => {
  const date: Date = new Date(dateTime);

  /* eslint-disable no-fallthrough */
  switch (unit) {
    case "year":
      date.setMonth(0);
    case "month":
      date.setDate(1);
    case "day":
      date.setHours(0);
    case "hour":
      date.setMinutes(0);
    case "minute":
      date.setSeconds(0);
    case "second":
      date.setMilliseconds(0);
    default:
      break;
  }
  /* eslint-enable no-fallthrough */

  return date.valueOf();
};
