import { DateUnit, FieldType } from "../graphQLTypes";
import { IFieldAnswer, IQuestion, ITimeLineRestriction } from "../types";
import { getStartDateByUnit } from "./getStartDateByUnit";
import { getStartOf } from "./getStartOf";

export const getTimeLineRestriction = (
  question: IQuestion,
  whenCreated: number | undefined
): ITimeLineRestriction | undefined => {
  const dateRangeField = question.fields.find(
    (field: IFieldAnswer) => field.type === FieldType.DATE_RANGE
  );
  if (
    whenCreated &&
    dateRangeField &&
    dateRangeField.validation.durationRestriction
  ) {
    const duration: number =
      dateRangeField.validation.durationRestriction.durationValue;
    const durationUnit: DateUnit =
      dateRangeField.validation.durationRestriction.unit;
    const gap: number = dateRangeField.validation.gapRestriction?.gapValue || 0;
    const gapUnit: DateUnit =
      dateRangeField.validation.gapRestriction?.unit || DateUnit.DAY;
    const endDateTimestamp: number = getStartOf(whenCreated, "day");

    return {
      startDate: getStartDateByUnit(durationUnit, duration, endDateTimestamp),
      endDate: new Date(endDateTimestamp),
      duration,
      gap,
      durationUnit,
      gapUnit
    };
  }
  return undefined;
};
