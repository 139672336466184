import React, { useState } from "react";
import { useMount } from "../../hooks/useMount";
import { loadExternalScript, removeScriptsFromDOM } from "../../utils";
import { useUnmount } from "../../hooks/useUnmount";

export interface IUseLoadScripts {
  isScriptsReady: boolean;
}

export const useLoadScripts = (): IUseLoadScripts => {
  const [isJQueryScriptReady, setIsJqueryScriptReady]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [isJSignatureScriptReady, setIsJSignatureScriptReady]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = useState<boolean>(false);

  useMount(() => {
    loadExternalScript(
      "https://code.jquery.com/jquery-3.6.0.min.js",
      "jquery",
      () => {
        setIsJqueryScriptReady(true);
        loadExternalScript(
          "https://cdnjs.cloudflare.com/ajax/libs/jSignature/2.1.3/jSignature.min.js",
          "jSignature",
          () => setIsJSignatureScriptReady(true)
        );
      }
    );
  });

  useUnmount(() => removeScriptsFromDOM(["jquery", "jSignature"]));

  return {
    isScriptsReady: isJQueryScriptReady && isJSignatureScriptReady
  };
};
