import { IAnswer, IFieldAnswer, IQuestion, IFormFieldErrorMap } from "../types";
import { buildFieldKey } from "./buildFieldKey";
import { FieldType } from "../graphQLTypes";
import { formatId } from "./formatId";

export const getFirstFieldIdAByFields = (
  fields: IFieldAnswer[],
  errors: IFormFieldErrorMap,
  questionId: string,
  answerId: string
): string => {
  let elementId: string = "";
  fields.some((field: IFieldAnswer) => {
    const fieldKey = buildFieldKey({
      questionId,
      answerId,
      fieldId: field.fieldId,
      type: field.type,
      fieldType: field.fieldType,
      isMulti: field.isMulti
    });
    const error = errors[fieldKey];
    if (error) {
      const id: string = `${answerId}${field.fieldId}`;
      if (
        field.type === FieldType.COMPANY ||
        field.type === FieldType.ADDRESS
      ) {
        const fieldName = error.message.split("|")[0];
        elementId = `${id}${fieldName}-label`;
      } else {
        elementId = `${id}-label`;
      }
      return true;
    }

    return undefined;
  });
  return formatId(elementId);
};

export const getFirstFieldIdByQuestions = (
  allQuestions: IQuestion[],
  errors: IFormFieldErrorMap
): string => {
  let elementId: string = "";
  allQuestions.some((question: IQuestion) =>
    question.answers.some((answer: IAnswer) => {
      elementId = getFirstFieldIdAByFields(
        answer.fields,
        errors,
        question.questionId,
        answer.answerId
      );
      return Boolean(elementId);
    })
  );
  return elementId;
};
