import React, {
  FC,
  memo,
  NamedExoticComponent,
  useCallback,
  useMemo,
  useState
} from "react";
import { ChevronIcon, DIRECTION } from "@security-watchdog/sw-ui-kit";
import { IExpandedCardProps } from "./types";
import * as s from "./styles";

export const ExpandedCardComponent: FC<IExpandedCardProps> = ({
  primaryText,
  expandedText,
  isExpandDisabled,
  className
}: IExpandedCardProps) => {
  const [isExpanded, setIsExpanded]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const handleContainerClick = useCallback(
    () => setIsExpanded((wasExpanded: boolean) => !wasExpanded),
    []
  );

  const handleContainerKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        setIsExpanded((wasExpanded: boolean) => !wasExpanded);
      }
    },
    []
  );

  const expandIconDirection: DIRECTION = useMemo(
    (): DIRECTION => (isExpanded ? DIRECTION.UP : DIRECTION.DOWN),
    [isExpanded]
  );

  return (
    <s.Container
      onKeyPress={handleContainerKeyPress}
      className={className}
      tabIndex={0}
    >
      <s.MainContainer>
        <s.ContentContainer>
          <s.ContentPrimaryText>{primaryText}</s.ContentPrimaryText>
        </s.ContentContainer>
        {!isExpandDisabled && (
          <div onClick={handleContainerClick}>
            <ChevronIcon direction={expandIconDirection} />
          </div>
        )}
      </s.MainContainer>
      {isExpanded && !isExpandDisabled && (
        <s.ExpandedContainer>
          {expandedText && <s.ExpandedText>{expandedText}</s.ExpandedText>}
        </s.ExpandedContainer>
      )}
    </s.Container>
  );
};

export const ExpandedCard: NamedExoticComponent<IExpandedCardProps> =
  memo<IExpandedCardProps>(ExpandedCardComponent);
