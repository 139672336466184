import { CardStatuses } from "components/StatusCard/types";
import { DraftSectionValues } from "./buildInputForSaveDraftValues";

export interface IGetSectionStatusProps {
  completed?: boolean;
  draftValues?: DraftSectionValues;
}

export const getSectionStatus = ({
  draftValues,
  completed
}: IGetSectionStatusProps): CardStatuses => {
  if (Object.keys(draftValues || {}).length) {
    return CardStatuses.INCOMPLETE;
  }

  return completed ? CardStatuses.COMPLETE : CardStatuses.INCOMPLETE;
};
