import React, { PropsWithChildren } from "react";
import * as s from "./styles";
import { IProps } from "./types";

const PageHeaderComponent: React.FC<IProps> = ({
  title,
  description
}: PropsWithChildren<IProps>) => (
  <>
    <s.PageTitle>{title}</s.PageTitle>
    <s.PageDescription>{description}</s.PageDescription>
  </>
);

export const PageHeader = React.memo<IProps>(PageHeaderComponent);
