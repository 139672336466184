import {
  IBuildFieldKeyProps,
  IFieldValue,
  IPartialTroubleShootHistoryRecord
} from "../types";
import { parseFieldKey } from "./buildFieldKey";

export interface IUpdateTroubleShootPartialHistoryProps {
  fieldKey: string;
  value: IFieldValue;
  historyRecords: IPartialTroubleShootHistoryRecord[];
  answerIndex: number;
}

export const updateTroubleShootPartialHistory = ({
  historyRecords,
  fieldKey,
  value,
  answerIndex
}: IUpdateTroubleShootPartialHistoryProps): IPartialTroubleShootHistoryRecord[] => {
  const { fieldId }: IBuildFieldKeyProps = parseFieldKey(fieldKey);

  return historyRecords.map((record: IPartialTroubleShootHistoryRecord) =>
    record.fieldId === fieldId && record.index === answerIndex
      ? { ...record, to: value }
      : record
  );
};
