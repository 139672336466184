import { createGlobalStyle } from "styled-components";
import "../../../assets/styles.css";
import { hexToRgb } from "@security-watchdog/sw-ui-kit";

export const GlobalStyle = createGlobalStyle`
  body, button, input, textarea {
    margin: 0;
  }

  body {
    font-family: ${({ theme }): string => theme.fontFamily};
    background: ${({ theme }): string => theme.colors.whisper};
    color: ${({ theme }): string => theme.colors.tangaroa};

    #signature {  
      height: 100%;
      width: 100%;
      background: ${({ theme }): string => theme.colors.whisper};
    }
  }

  input[type=text]::-ms-clear {
    display: none;
  }
  
  button {
    padding: 0;
  }
  
  html, body, #root {
    height: 100vh;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  
  #modal-root {
    z-index: 99999;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: ${({ theme }): string =>
      `rgba(${hexToRgb(theme.colors.mineShaft)}, 0.3)`}
  }
  
`;
